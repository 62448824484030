import { LoadingButton } from '@mui/lab';
import { useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { BASE_URL, ERR_SNACKBAR, WARN_SNACKBAR } from '../../../../helpers/constants';
import { setAlert } from '../../../../redux/admin/adminActions';
import { AlertMsg } from '../../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../../redux/rootReducer';
import s from './FeedbackData.module.scss'
import { AbTestContext } from '../../AbTestPage';
import { clearPropertiesAndDatesOurApi, getEventDataOurApi } from '../../../../redux/external-apis/our-api/ourApiActions';
import { DesignGroup } from '../../../../redux/abtest/design-group/interfaces';
import { getDesignGroupScores } from '../../../../redux/abtest/design-group/designGroupActions';
import { OurApiState } from '../../../../redux/external-apis/our-api/interfaces'
import { useSelectDesignGroupDynamicData } from '../../../../redux/external-apis/our-api/ourApiSelectors';
import FeedbackFields from './FeedbackData/FeedbackFields';
import axios, { AxiosResponse } from 'axios';
import to from 'await-to-js';
import { updateAbTestAddFeedback } from '../../../../redux/abtest/abTestActions';
import { AbTestInterface } from '../../../../redux/abtest/interfaces';
export type DesignGroupScoreUpdate = {
    property: string,
    eventName: string,
    otherEventName: string,
    startDate: string,
    endDate: string,
    abTestId: string,
    groupName: string
    trialName: string,
};

type Props = {
    setAlert: (alert: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
    eventDataLoading: OurApiState["eventDataLoading"] // this is for when the abtest page first mounts only, so all GetDesignData.tsx components have synced loading
};


type GetPropertiesResponse = {
    success: boolean
    error?: string
    properties?: string[]
};
type GetValueCountsResponse = {
    success: boolean
    error?: string
    valueCounts: ValueCounts
}
type ValueCounts = { [key: string]: any };

/*
    PURPOSE: updating the design scores according to the 3 fields presented: event, otherEvent, and property
    ACTIONS: See Props that return ReduxAction
    CHILDREN: EventPropertyFields.tsx interacts with this component heavily
    NOTES: To understand how the ratios are calculated, see the /info route in design.ts on the backend
           Currently the maxEndDate field is obsolete, but I left it in here in case we ever do want to have the ability to 
           Get Design Data by a date range. Currently, we getting design data from the earliest possible date to time a user
           clicks the Get Data button. Previously, we got the min max date possible from the db and offer the choice. Wissam 
           wanted to remove this option, since we want the endDate to be now; events are hitting our api every second
*/
const FeedbackData = ({
    alertMsg,
    setAlert,
    eventDataLoading
}: Props) => {
    const dispatch = useDispatch();

    const abTest = useContext(AbTestContext);
    let hasComboFields = false;
    let INIT_EVT_NAME = '', INIT_PARAM_EVT = '';


    // here we handle the case where eventPropertyCombo doesn't exist yet (it should exist, but jic)
    // it also allows us to cache the initial state in case we reset fields
    if (abTest) {
        const { feedbackCombo } = abTest
        if (feedbackCombo) {
            const { event, parameter } = feedbackCombo;
            if (parameter || event) {
                hasComboFields = true;
                INIT_EVT_NAME = event;
                INIT_PARAM_EVT = parameter;
            }
        }
    }
    const [parameters, setParameters] = useState<string[]>([])
    const [valueCounts, setValueCounts] = useState<ValueCounts>({})
    const [eventName, setEventName] = useState<string | null>(hasComboFields ? INIT_EVT_NAME as string : null);
    const [parameterName, setParameterName] = useState<string | null>(hasComboFields ? INIT_PARAM_EVT as string : null);
    const [loading, setLoading] = useState(false); // this is for any api hits launched by this component


    function handleChange(name: string, value: string | null) {
        if (name === "event") {
            setLoading(true);
            setEventName(value);
            if (parameterName) {
                setParameterName(null);
            }
            if (value) {
                getAllPropertiesFrom(value)
                    .then(res => {
                        if (res)
                            setParameters(res);
                    })
                    .catch(console.log)
            }
            setLoading(false);
        } else if (name === "parameter") {
            setParameterName(value);
        }
    }



    /*  this is the final call to "get data". All fields must be present in order to get design scores */
    function handleClick() {
        setLoading(true);
        // if (!eventName || !trialName) {
        //     return setAlert({ msg: "You should add a click or a trial to get data.", config: ERR_SNACKBAR });
        // }
        if (!eventName || !abTest || !parameterName) {
            return setAlert({ msg: "All fields must be filled out.", config: WARN_SNACKBAR });
        }
        getValueCounts().then(res => {
            if (res) {
                setValueCounts(res);
                // TODO save data in db and show it
                if (abTest) {
                    abTest.feedbackCombo = {
                        event: eventName,
                        parameter: parameterName
                    }
                    if (valueCounts)
                        abTest.feedbackData = res;
                        
                    dispatch(updateAbTestAddFeedback(abTest));
                }
            }
        }).catch(console.log)
        setLoading(false);

    }



    function resetFields() {
        setEventName(null);
        setParameterName(null);

    }


    // gets all the properties/parameters from one event (keys of custom_params on the event property data record in db)
    async function getAllPropertiesFrom(event: string) {
        try {
            const url = BASE_URL + '/analytics/techlabfze/event/parameters';
            const [e1, response] = await to<AxiosResponse<GetPropertiesResponse>, any>(axios.get(url, { params: { event } }));
            if (e1) throw new Error(e1.message);
            if (!response) throw new Error('No data returned from get properties query.');
            const { error, properties, success } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error('unsuccessful operation.');
            return properties;
        } catch (e: any) {
            console.log(e);
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
    }

    // performs the operation mentioned in the notes above
    async function getValueCounts() {
        if (!eventName || !parameterName) return
        setLoading(true);
        try {
            const url = BASE_URL + '/analytics/techlabfze/projectdata/valuecounts';
            const [e1, response] = await to<AxiosResponse<GetValueCountsResponse>, any>(
                axios.get(
                    url,
                    {
                        params: {
                            event: eventName,
                            parameter: parameterName
                        }
                    }
                )
            );
            if (e1) throw new Error(e1.message);
            if (!response) throw new Error('No data returned from get parameters query.');
            const { error, valueCounts, success } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error('unsuccessful operation.');
            return valueCounts;
        } catch (e: any) {
            console.log(e);
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
        setLoading(false);
    }


    return (
        <div className={s.sectionWrap}>
            <section>
                <FeedbackFields
                    title={"Feedback"}
                    handleChange={handleChange}
                    event={eventName}
                    parameter={parameterName}
                    parameters={parameters}
                />
            </section>



            <section>
                <div className={s.btnsWrap}>
                    <LoadingButton
                        onClick={handleClick}
                        variant="contained"
                        disabled={eventDataLoading}
                        loading={loading || eventDataLoading}
                        size="small"
                    >
                        Get Data
                    </LoadingButton>
                    <LoadingButton
                        onClick={resetFields}
                        variant="outlined"
                        color="warning"
                        sx={{ marginLeft: "0.5rem" }}
                        size="small"
                    >
                        Reset Fields
                    </LoadingButton>
                </div>
            </section>
        </div>
    )
};


const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    getDesignGroupScores: (params: DesignGroupScoreUpdate) => getDesignGroupScores(params),
    getEventDataOurApi: (eventName: string, otherEventName: string, groupName: string, trialName: string) => getEventDataOurApi(eventName, otherEventName, groupName, trialName),
    clearPropertiesAndDatesOurApi: (groupName: string) => clearPropertiesAndDatesOurApi(groupName),
    updateAbTestAddFeedback: (abTest: AbTestInterface) => updateAbTestAddFeedback(abTest)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    const { eventDataLoading } = rootState.externalApis.ourApi;
    return { alertMsg, eventDataLoading };
}
export default connect(mapStateToProps, mapDispatch)(FeedbackData);

