import { Button, Dialog, DialogTitle, DialogActions, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TABLET_WIDTH } from '../helpers/constants';
import { logout, setAlert } from '../redux/admin/adminActions';
import { selectAlertMsg } from '../redux/admin/adminSelectors';
import { AlertMsg } from '../redux/admin/interfaces';
import { ReduxAction, RootState } from '../redux/rootReducer';
import MenuIcon from '@mui/icons-material/Menu';
import s from './SidePanel.module.scss'
import MainDrawer from './SidePanel/MainDrawer';
import { LoadingButton } from '@mui/lab';

type Props = {
    logout: () => ReduxAction,
    setAlert: (alert: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg
}

const SidePanel = ({ logout, setAlert, alertMsg } : Props) => {
    //const history                     = useHistory();
    const { enqueueSnackbar }         = useSnackbar();
    const [open, setOpen]             = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading]       = useState(false);

    useEffect(() => {
        if (alertMsg.msg) {
            enqueueSnackbar(alertMsg.msg, alertMsg.config);
            setAlert({msg: "", config: {}});
        }
    }, [alertMsg.msg])

    useEffect(() => {
        return () => {
            setLoading(false);
            setDialogOpen(false);
        }
    }, [])
    

    function handleClick() {
        setLoading(true);
        logout();
    }


   
    return (
        <div className={s.wrap}>
            <div className={s.burger}>
            {
                window.innerWidth <= TABLET_WIDTH && (
                    <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon/>
                    </IconButton>
                )
            } 
            </div>
            <MainDrawer
                setDialogOpen={setDialogOpen}
                setOpen={setOpen}
                variant={window.innerWidth > TABLET_WIDTH ? "permanent" : "temporary"}
                open={open}
            />
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    Are you sure you want to log out?
                </DialogTitle>
                
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>No</Button>
                    <LoadingButton onClick={handleClick} loading={loading}>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapDispatch = {
    logout: () => logout(),
    setAlert: (alert: AlertMsg) => setAlert(alert)
}

type SProps = Pick<Props, "alertMsg">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    alertMsg: selectAlertMsg
})
export default connect(mapStateToProps, mapDispatch)(SidePanel);