import { formatProjectGridRows } from "../../helpers/utils"
import { AbTestInterface } from "../abtest/interfaces"
import { ReduxAction } from "../rootReducer"
import { ACTIONS, ProjectInterface, ProjectsState, PROJECTS_INITIAL_STATE } from "./interfaces"

export const projectsReducer = (state: ProjectsState = PROJECTS_INITIAL_STATE, action: ReduxAction): ProjectsState => {

    switch (action.type) {

        // SAGA ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_PROJECTS:
            return {
                ...state,
                projects: action.payload.projects,
                gridRows: action.payload.gridRows
            }
        case ACTIONS.REPLACE_AB_TEST:
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project.name === (action.payload as AbTestInterface).basicFields.project) {
                        return {
                            ...project,
                            abTests: project.abTests.map(abTest => {
                                if (abTest._id === (action.payload as AbTestInterface)._id) {
                                    return action.payload as AbTestInterface
                                } else {
                                    return abTest
                                }
                            })
                        }
                    } else {
                        return project
                    }
                })
            }
        case ACTIONS.REPLACE_PROJECT:
            return {
                ...state,
                projects: state.projects.map(proj => {
                    if (proj.name === (action.payload as ProjectInterface).name) {
                        return action.payload
                    } else {
                        return proj
                    }
                }),
                gridRows: formatProjectGridRows([
                    ...state.projects.filter(proj => proj.name !== (action.payload as ProjectInterface).name),
                    action.payload
                ])
            }
        case ACTIONS.REMOVE_LOCAL_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(proj => proj.name !== action.payload),
                gridRows: formatProjectGridRows(state.projects.filter(proj => proj.name !== action.payload))
            }
        default:
            return {
                ...state
            }
    }
}