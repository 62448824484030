import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ERR_SNACKBAR } from '../../helpers/constants';
import { login, setAlert } from '../../redux/admin/adminActions';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import { useSnackbar } from 'notistack'
import s from './LoginPage.module.scss'
import { LoadingButton } from '@mui/lab';
import { AdminState, AlertMsg } from '../../redux/admin/interfaces';


type Props = {
    login: (u: string, p: string) => ReduxAction,
    setAlert: (alertObj: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
    theme: AdminState["theme"]
}


const LoginPage = ({ login, setAlert, alertMsg, theme }: Props) => {

    const myTheme = createTheme({
        palette: {
          mode: theme,
        }
    });

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loading, setLoading]   = useState<boolean>(false);
    const { enqueueSnackbar }     = useSnackbar();



    useEffect(() => {
        return () => {
            setLoading(false);
        }
    },[])

    useEffect(() => {
        if (alertMsg.msg) {
            enqueueSnackbar(alertMsg.msg, alertMsg.config);
            setLoading(false);
            setAlert({msg: "", config: {}});
        }
    }, [alertMsg.msg])

    

    async function handleSubmit(e: SyntheticEvent) {

        if (!username || !password) {
            return enqueueSnackbar("Missing field", ERR_SNACKBAR);
        }
        setLoading(true);
        login(username, password);
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        let { name, value } = e.target;
        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                break;
        }
    }

    return (
        <ThemeProvider theme={myTheme}>
            <div className={s.outerWrap} style={theme === "dark" ? {backgroundColor: "black"} : {}}>
                <div className={s.wrap} style={theme === "dark" ? {backgroundColor: "#4a4a4a"} : {}}>
                    <h2 style={theme === "dark" ? {color: "white"} : {}}>Login</h2>
                    <TextField
                        required
                        name="username"
                        label="Username"
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        name="password"
                        onChange={handleInputChange}
                        required
                    />
                    <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>Submit</LoadingButton>
                </div>
            </div>
        </ThemeProvider>
        
    );
};

const mapDispatch = {
    login: (u: string, p: string) => login(u, p),
    setAlert: (alertObj: AlertMsg) => setAlert(alertObj)
}

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg, theme } = rootState.admin;
    return { alertMsg, theme };
}
export default connect(mapStateToProps, mapDispatch)(LoginPage);