import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { UnityApiInfo } from '../../../../redux/projects/interfaces';

type Props = {
    handleUnityApiFields: (e: ChangeEvent<HTMLInputElement>) => void,
    setUsingDefault: Dispatch<SetStateAction<boolean>>,
    usingDefault: boolean,
    apiInfo: UnityApiInfo,
    error: boolean,
}

/*
    Unity has environmentsIds which represent an "environment", and each environment contains projects,
    which have a projectId. In Unity, currently there are two environments. Solid Apps INC has its own 
    environment, and Tech Consolidated Inc. has its own environment. Tech Consolidated has the popit project
    and solid apps has the slime project
*/
const UnityProjectFields = ({
    apiInfo,
    usingDefault,
    error,
    handleUnityApiFields,
    setUsingDefault
} : Props) => {
    return (
        <>
            <section>
                <TextField
                    name="projectId"
                    onChange={handleUnityApiFields}
                    label="Unity Project ID"
                    value={apiInfo.unitySettings!.projectId}
                    error={error && !apiInfo.unitySettings!.projectId}
                    fullWidth
                    required
                />
            </section>

            <section>
                <FormControl>
                    <FormLabel>Choose Unity environment</FormLabel>
                    <RadioGroup row value={usingDefault} onChange={(e: ChangeEvent<HTMLInputElement>) => setUsingDefault(e.target.value === 'true')}>
                        <FormControlLabel value="true" control={<Radio/>} label="default"/>
                        <FormControlLabel value="false" control={<Radio/>} label="Use env ID"/>
                    </RadioGroup>
                </FormControl>
            </section>

            <section>
            {
                !usingDefault && (
                    <TextField
                        name="environmentId"
                        onChange={handleUnityApiFields}
                        label="Unity Environment ID"
                        value={apiInfo.unitySettings!.environmentId}
                        error={error && !apiInfo.unitySettings!.environmentId}
                        fullWidth
                        required
                    />
                )
            }
            </section>
        </>
    );
};

export default UnityProjectFields;