import { Autocomplete, CircularProgress, Paper, TextField, AutocompleteRenderInputParams} from '@mui/material';
import { AdminState } from '../../../../../../../redux/admin/interfaces';
import { UnityApiState } from '../../../../../../../redux/external-apis/unity/interfaces';
import s from './UnityConfigKeyDropdown.module.scss'
import { ReactNode } from 'react'

type Props = {
    theme: AdminState["theme"],
    loading: boolean,
    currConfigKeys: UnityApiState["currConfigKeys"],
    configKey: string | null,
    hasCampaignData: boolean
    hasCampaignInApi: boolean
    groupName: string
    handleConfigKeySelection: (name: string, value: string | null) => void
}

const UnityConfigKeyDropdown = ({
    theme,
    loading,
    currConfigKeys,
    configKey,
    hasCampaignData,
    hasCampaignInApi,
    groupName,
    handleConfigKeySelection
}: Props) => {
    const options = currConfigKeys.length > 0 ? [...currConfigKeys] : [configKey]

    function handleChange(e: any, newValue: string | null) {
        console.log(newValue);

        if (!newValue || newValue === '') return
        handleConfigKeySelection(groupName, newValue)
    }

    return (
        <div className={s.wrap}>
            <Paper
                className={s.wrap}
                sx={theme === "dark" ? { backgroundColor: "#262626" } : {}}
            >
                <div className={s.innerWrap}>
                    <Autocomplete
                        renderInput={(params:AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                name={groupName}
                                label={groupName}
                                focused
                                InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: "13px" },
                                    endAdornment: (
                                        <>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={17}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    )
                                }}
                                size="small"
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    style: { fontSize: "13px" }
                                }}
                            />
                        ) as ReactNode}
                        options={options}
                        onChange={handleChange}
                        value={configKey ? configKey : ""}
                        disabled={loading || hasCampaignInApi}
                        fullWidth
                        classes={{ option: s.option }}
                    />
                </div>
            </Paper>

        </div>
    );
};

export default UnityConfigKeyDropdown;