import s from './PageTitle.module.scss';
type Props = {
    text: string
}

const PageTitle = ({ text } : Props) => {
    return (
        <>
            <h2 className={s.title}>{text}</h2>
            <hr style={{width: '100%'}}/>
        </>
    );
};

export default PageTitle;