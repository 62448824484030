import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UnityAbTest } from '../../../../../../redux/abtest/interfaces';
import { selectTheme } from '../../../../../../redux/admin/adminSelectors';
import { AdminState } from '../../../../../../redux/admin/interfaces';
import { UnityApiState } from '../../../../../../redux/external-apis/unity/interfaces';
import { RootState } from '../../../../../../redux/rootReducer';
import { ConfigGroup } from '../UnityConfigArea';
import UnityConfigKeyDropdown from './UnityConfigKeyDropdowns/UnityConfigKeyDropdown';
import s from './UnityConfigKeyDropdowns.module.scss'

type Props = {
    theme: AdminState["theme"] // from redux
    abTest: UnityAbTest
    hasCampaignData: boolean
    groupConfigKeys: ConfigGroup
    hasCampaignInApi: boolean
    currConfigKeys: UnityApiState["currConfigKeys"]
    loading: boolean
    handleConfigKeySelection: (name: string, value: string | null) => void
}

const UnityConfigKeysDropdowns = ({
    abTest,
    hasCampaignData,
    groupConfigKeys,
    hasCampaignInApi,
    currConfigKeys,
    theme,
    loading,
    handleConfigKeySelection
}: Props) => {
    console.log({
        groupConfigKeys,
        currConfigKeys
    });

    return (
        <div className={s.wrap}>
            {
                abTest.designGroups.length > 0 ? (
                    abTest.designGroups.map((group, idx) => (
                        <UnityConfigKeyDropdown
                            key={idx}
                            groupName={group.name}
                            hasCampaignData={hasCampaignData}
                            hasCampaignInApi={hasCampaignInApi}
                            configKey={groupConfigKeys[group.name]}
                            currConfigKeys={currConfigKeys}
                            loading={loading}
                            theme={theme}
                            handleConfigKeySelection={handleConfigKeySelection}
                        />
                    ))
                ) : (
                    <div>Add a design group to view config keys</div>
                )
            }
        </div>
    );
};

const mapStateToProps = createStructuredSelector<RootState, Pick<Props, "theme">>({
    theme: selectTheme
})

export default connect(mapStateToProps)(UnityConfigKeysDropdowns);