import { combineReducers } from "redux";
//import { firebaseApiReducer } from "./firebase/firebaseApiReducer";
import { ourApiReducer } from "./our-api/ourApiReducer";
import { unityApiReducer } from "./unity/unityReducer";


export const externalApisReducer = combineReducers({
    unityApi: unityApiReducer,
    ourApi: ourApiReducer,
    //firebaseApi: firebaseApiReducer
})