import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Box, Paper } from '@mui/material';
import s from './DesignBarChart.module.scss';
import { IPHONE_PLUS_WIDTH } from '../../helpers/constants';
import { DesignGroup } from '../../redux/abtest/design-group/interfaces';


type Props = {
    designGroup: DesignGroup
}



const DesignsBarChart = ({ designGroup }: Props) => {
    let bestScore = -Infinity;
    let trialBestScore = -Infinity;
    const data = designGroup.designs.length > 0 ? designGroup.designs
        .map(d => {

            const { name, score, trialScore } = d;

            if (bestScore < score && score !== 0) {
                bestScore = score;
            }

            if (trialBestScore < trialScore && trialScore !== 0) {
                trialBestScore = trialScore;
            }

            return [
                { name:`${name}_ckd`, score: score, type: 'regular' },
                { name:`${name}_trial`, score: trialScore, type: "trial" }
            ];
        }).flat() : [];
    const { scoreDateRange } = designGroup;

    const startDateFmtted = scoreDateRange ? new Date(designGroup.scoreDateRange!.startDate).toLocaleString('en-GB').slice(0, -3) : "";
    const endDateFmtted = scoreDateRange ? new Date(designGroup.scoreDateRange!.endDate).toLocaleString('en-GB').slice(0, -3) : "";
    let finalStartDate = startDateFmtted === 'Invalid Date' ? "No date set" : startDateFmtted;
    let finalEndDate = endDateFmtted === 'Invalid Date' ? "No date set" : endDateFmtted;
    const [loading, setLoading] = useState(false);
    return (
        <Paper className={s.wrap}>
            <div className={s.titleWrap}>
                <div className={s.title}>
                    <h2>Score Results</h2>
                    <div>
                        {
                            designGroup.eventPropertyCombo && (
                                <p>
                                    <span>Event Combo: </span>
                                    {`(${designGroup.eventPropertyCombo.trialName}|${designGroup.eventPropertyCombo.eventName})/${designGroup.eventPropertyCombo.otherEventName}/${designGroup.eventPropertyCombo.property}`}
                                </p>
                            )
                        }
                        {
                            startDateFmtted && endDateFmtted && (
                                <p>
                                    <span>Dates: </span>
                                    {`${finalStartDate} - ${finalEndDate}`}
                                </p>
                            )
                        }
                    </div>

                </div>

                {
                    window.innerWidth <= IPHONE_PLUS_WIDTH && <div>(Tap bar to view info)</div>
                }
            </div>

            <div>
                <ResponsiveContainer width="100%" height={490}>

                    {
                        data.length > 0 && !loading ? (
                            <BarChart
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                maxBarSize={100}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend wrapperStyle={{ bottom: '15px' }} />
                                <Bar dataKey="score" fill="#8884d8">
                                    {
                                        data.map((d, idx) => {
                                            let bestColor = "#5eba7d";
                                            let otherColor = "#8884d8";
                                            let orangeColor = "#FFA500"
                                            if (d.score === bestScore && d.type === "regular") {
                                                return <Cell key={idx} fill={bestColor} />
                                            } else if (d.score && d.type === "regular") {
                                                return <Cell key={idx} fill={otherColor} />
                                            } else if (d.score === trialBestScore && d.type === "trial") {                                                
                                                return <Cell key={idx} fill={bestColor} />
                                            } else if (d.score && d.type === "trial") {
                                                return <Cell key={idx} fill={orangeColor} />
                                            }
                                        })
                                    }
                                </Bar>
                            </BarChart>
                        ) : (
                            <div className={s.emptyData}>
                                <div>No results</div>
                            </div>
                        )
                    }
                </ResponsiveContainer>
            </div>
        </Paper>

    );
};

export default DesignsBarChart;