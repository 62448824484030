import { Design, InitialDesign } from "./interfaces"
import { DESIGN_ACTIONS } from "./interfaces"

/* Design actions */
export function updateDesignInDb(form: FormData)  {
    return {
        type: DESIGN_ACTIONS.UPDATE_DESIGN_IN_DB,
        payload: form
    }
}
export function addDesignToDb(design: InitialDesign, abTestId: string, groupName: string)  {
    return {
        type: DESIGN_ACTIONS.ADD_DESIGN_TO_DB,
        payload: { design, abTestId, groupName }
    }
}
export function deleteDesignInDb(design: Design, abTestId: string, groupName: string)  {
    return {
        type: DESIGN_ACTIONS.DELETE_DESIGN_IN_DB,
        payload: { design, abTestId, groupName }
    }
}