import { SyntheticEvent, useEffect, useState } from 'react'
import { AbTestInterface } from '../../../redux/abtest/interfaces';
import DesignsBarChart from '../../reusable/DesignsBarChart';
import s from './BarChartArea.module.scss'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { RootState } from '../../../redux/rootReducer';
import { connect } from 'react-redux';
import { AdminState } from '../../../redux/admin/interfaces';
type Props = {
    abTest: AbTestInterface,
    theme: AdminState["theme"]
}

const BarChartArea = ({ abTest, theme } : Props) => {
    const { designGroups } = abTest;
    const NO_GROUPS         = "no groups";
    const [currGroup, setCurrGroup] = useState(designGroups.length > 0 ? designGroups[0].name : NO_GROUPS);

    useEffect(() => {
        const { designGroups } = abTest;
        setCurrGroup(designGroups.length > 0 ? designGroups[0].name : NO_GROUPS)
        return () => {
            setCurrGroup(NO_GROUPS);
        }
    }, [abTest])
    return (
        <div className={s.wrap}>
            <>
                {
                    designGroups.length > 0 ? (
                        <TabContext value={currGroup}>
                            <TabList className={s.tabList} onChange={(e: SyntheticEvent, newValue: string) => setCurrGroup(newValue)} sx={{background: theme === "dark" ? "#343434" : "#f4f4f4"}}>
                            {
                                designGroups.map((group, idx) => <Tab key={idx} value={group.name} label={group.name}/>)                        
                            }
                            </TabList>
                            {
                                designGroups.map((group, idx) => (
                                    <TabPanel classes={{root: s.tabPanel}} key={idx} value={group.name}>
                                        <DesignsBarChart key={idx} designGroup={group}/>
                                    </TabPanel>
                                ))
                            }
                            <TabPanel value={NO_GROUPS}>
                                <h2>No Design Groups</h2>
                            </TabPanel>                        
                        </TabContext>
                    ) : (
                        <TabContext value={NO_GROUPS}>
                            <TabList className={s.tabList}>
                                <Tab value={NO_GROUPS} label="No Groups"/>

                            </TabList>
                            <TabPanel value={NO_GROUPS}>
                                <h2>No Design Groups</h2>
                            </TabPanel>
                        </TabContext>
                    )
                }
            </>
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { theme } = rootState.admin;
    return { theme }
}
export default connect(mapStateToProps)(BarChartArea);