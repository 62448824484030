import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { Paper, Table, TableCell, TableContainer, TableRow, TableHead, TableBody, TextField, Collapse, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useContext, useEffect, useState } from 'react'
import DateAdapter from '@mui/lab/AdapterDateFns';
import { AbTestInterface, UnityAbTest } from '../../../redux/abtest/interfaces';
import s from './AbTestSummary.module.scss'
import EditIcon from '@mui/icons-material/Edit';
import { AdminState, AlertMsg } from '../../../redux/admin/interfaces';
import { setAlert } from '../../../redux/admin/adminActions';
import { connect } from 'react-redux';
import { ReduxAction, RootState } from '../../../redux/rootReducer';
import { ERR_SNACKBAR, IPHONE_PLUS_WIDTH } from '../../../helpers/constants';
import Tooltip from '@mui/material/Tooltip';
import { AbTestContext } from '../AbTestPage';
import { createStructuredSelector } from 'reselect';
import { selectTheme } from '../../../redux/admin/adminSelectors';
import { updateAbTestInDb } from '../../../redux/abtest/abTestActions';

type Props = {
    theme: AdminState["theme"],
    setAlert: (alert: AlertMsg) => ReduxAction,
    updateAbTestInDb: (abTest: AbTestInterface, field: keyof AbTestEditableFields, value: string) => ReduxAction
}

export type AbTestEditableFields = {
    startDate: Date | null,
    endDate: Date | null,
    comments: string,
    name: string
}


interface OpenState {
    startDate: boolean,
    endDate: boolean,
    comments: boolean,
    name: boolean
}
const OPEN_INIT_STATE = {
    startDate: false, 
    endDate: false, 
    comments: false,
    name: false
}

/*
    Width will be set by the comments display field, the sx prop
*/

const AbTestSummary = ({ theme, setAlert, updateAbTestInDb } : Props) => {

    const abTest                 = useContext(AbTestContext) as AbTestInterface;
    const { startDate, endDate } = abTest.basicFields;
    const formattedStart         = startDate ? new Date(abTest.basicFields.startDate).toLocaleString('en-GB') : "";
    const formattedEnd           = endDate ? new Date(abTest.basicFields.endDate).toLocaleString('en-GB'): "";
    const formattedCreatedAt     = new Date(abTest.createdAt).toLocaleString('en-GB');
    const formattedUpdatedAt     = new Date(abTest.updatedAt as string).toLocaleString('en-GB');
    const collapseStyles         = {paddingBottom: '0', paddingTop: '0', borderBottom: 'none'};
    const specialCellStyles      = {fontStyle: 'italic', fontSize: '14px !important', color: theme === "dark" ? "white" : "black" };
    // const phoneOverflowing       = (abTest.basicFields.comments as string).length > 21 && window.innerWidth <= IPHONE_PLUS_WIDTH;
    // const nonPhoneOverflowing    = (abTest.basicFields.comments as string).length > 37 && window.innerWidth > IPHONE_PLUS_WIDTH;

    const [loading, setLoading]     = useState<boolean>(false);
    const [open, setOpen]           = useState<OpenState>(OPEN_INIT_STATE)
    const [editState, setEditState] = useState<AbTestEditableFields>({
        startDate: startDate ? new Date(abTest.basicFields.startDate) : null,
        endDate: endDate ? new Date(abTest.basicFields.endDate) : null,
        comments: abTest.basicFields.comments || "",
        name: abTest.basicFields.name
    })

    useEffect(() => {
        return () => {
            setOpen(OPEN_INIT_STATE);
            setLoading(false);
        }
    }, [abTest])

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [])

    function handleDateChange(d: Date | null, field: string) {
        setEditState((prev) => ({...prev, [field]: d}))
    }
    
    function handleCollapse(field: keyof AbTestEditableFields) {
        if (open[field as keyof OpenState]) {
            // reset basic field to original abTest val if closing the edit area
            let update: Date | string;
            if (field === "comments" || field === "name") {
                update = abTest.basicFields[field] as string;
            } else {
                if (abTest.basicFields[field]) {
                    update = new Date(abTest.basicFields[field])
                } else {
                    update = "";
                }
            }
            if (update) {
                setEditState(prev => (
                    {...prev, [field]: update}
                ))
            }
        }
        setOpen(prev => ({...OPEN_INIT_STATE, [field]: !prev[field]}))
    }


    function dateFieldBtnDisabled(initialDate: string, valueInState: Date | null): boolean {
        if (!initialDate) {
            if (valueInState === null) {
                return true
            } else {
                return false;
            }
        } else {
            // if initialDate, there must be valueInState
            if (initialDate === valueInState?.toISOString()) {
                return true;
            } else {
                return false;
            }
        }
    }




    function hasDateError(): boolean {
        let hasError    = false;

        if (open.startDate || open.endDate) {
            let startDate = editState.startDate;
            let endDate   = editState.endDate;
            
            
            
            if (startDate && endDate) {
                if (startDate.getTime() === endDate.getTime()) {
                    hasError = true;
                } else {
                    const startNum = startDate.getTime();
                    const endNum   = endDate.getTime();
                    if (startNum > endNum) {
                        hasError = true;
                    } else {
                        hasError = false;
                    }
                }
            }
        }
        return hasError
    }

    function handleUpdate(field: keyof AbTestEditableFields) {
        if (hasDateError()) {
            return setAlert({
                msg: "Please make sure start date is before end date.",
                config: ERR_SNACKBAR

            })
        } else {
            let val = editState[field];

            if (val && typeof(val) !== 'string') {
                val = val.toISOString();
            }

            if (!val && (field === "endDate" || field === "startDate")) {
                val = "";
            }
            setLoading(true);
            updateAbTestInDb(abTest, field, (val as string));
        }
    }


    return (
        <div className={s.wrap}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <TableContainer className={s.outerWrap} component={Paper}>
                    {/* ----------- header ----------------*/}
                    <Table className={s.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Summary</TableCell>
                                <TableCell sx={specialCellStyles}>
                                    {
                                        abTest.basicFields.comments && (
                                            "Press and hold or hover over comments to view"
                                        )
                                    }
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    

                        <TableBody>


                            <TableRow key={0}>
                                <TableCell>API</TableCell>
                                <TableCell>{abTest.abTestApiInfo.api}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {
                                abTest.abTestApiInfo.api === "UNITY" && (
                                    <>
                                        <TableRow key={1}>
                                            <TableCell>Project ID</TableCell>
                                            <TableCell>{abTest.abTestApiInfo.unitySettings!.projectId}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        {
                                            (abTest as UnityAbTest).abTestApiInfo.unitySettings.campaignConfig && (
                                                <TableRow key={2}>
                                                    <TableCell>Campaign ID</TableCell>
                                                    <TableCell>{(abTest as UnityAbTest).abTestApiInfo.unitySettings.campaignConfig!.campaignId}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )
                                        }
                                        
                                    </>
                                )
                            }
                            
                            <TableRow key={3}>
                                <TableCell>Creator</TableCell>
                                <TableCell>{abTest.basicFields.creator}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>




                            {/* -------------------------- collapsible row  (NAME) -----------------------------*/}
                            <TableRow key={4}>
                                <TableCell colSpan={3} sx={collapseStyles}>
                                    <Collapse in={open.name} unmountOnExit>
                                        <div className={s.innerCollapse}>
                                            <TextField
                                                key={2344}
                                                name="name"
                                                value={editState.name}
                                                onChange={(e) => setEditState((prev) => ({...prev, name: e.target.value}))}
                                            />
                                            <LoadingButton
                                                loading={loading}
                                                variant="contained"
                                                onClick={() => handleUpdate('name')}
                                                disabled={abTest.basicFields.name === editState.name}
                                            >Update</LoadingButton>
                                        </div>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                            <TableRow key={5}>
                                <TableCell>Name</TableCell>
                                <TableCell>{abTest.basicFields.name}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => handleCollapse("name")}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>



                            {/* -------------------------- collapsible row (startDate) -----------------------------*/}
                            <TableRow key={6}>
                                <TableCell colSpan={3} sx={collapseStyles}>
                                    <Collapse in={open.startDate} unmountOnExit>
                                            <div className={s.innerCollapse}>
                                                <DateTimePicker
                                                    renderInput={(props => (
                                                        <TextField
                                                            {...props}
                                                            key={888}
                                                            id="startDate"
                                                        />
                                                    ))}
                                                    value={editState.startDate}
                                                    onChange={(d) => handleDateChange(d, "startDate")}
                                                    inputFormat="dd/MM/yyyy hh:mm a"
                                                />
                                                <LoadingButton
                                                    loading={loading}
                                                    variant="contained"
                                                    onClick={() => handleUpdate('startDate')}
                                                    disabled={dateFieldBtnDisabled(startDate, editState.startDate)}
                                                >Update</LoadingButton>
                                            </div>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                            <TableRow key={7}>
                                <TableCell>Start Date</TableCell>
                                <TableCell>{formattedStart}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => handleCollapse("startDate")}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                            




                            {/* -------------------------- collapsible row (endDate) -----------------------------*/}
                            <TableRow key={8}>
                                <TableCell colSpan={3} sx={collapseStyles}>
                                    <Collapse in={open.endDate} unmountOnExit>
                                        <div className={s.innerCollapse}>
                                            <DateTimePicker
                                                renderInput={(props => (
                                                    <TextField
                                                        {...props}
                                                        key={424}
                                                        id="endDate"
                                                    />
                                                ))}
                                                value={editState.endDate}
                                                onChange={(d) => handleDateChange(d, "endDate")}
                                                inputFormat="dd/MM/yyyy hh:mm a"
                                            />
                                            <LoadingButton
                                                loading={loading}
                                                variant="contained"
                                                onClick={() => handleUpdate('endDate')}
                                                disabled={dateFieldBtnDisabled(endDate, editState.endDate)}
                                                >Update</LoadingButton>
                                        </div>
                                    </Collapse>
                                </TableCell>
                            </TableRow>

                            <TableRow key={9}>
                                <TableCell>End Date</TableCell>
                                <TableCell>{formattedEnd}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => handleCollapse("endDate")}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>


                            






                            {/* -------------------------- collapsible row (comments) -----------------------------*/}
                            <TableRow key={10}>
                                <TableCell colSpan={3} sx={collapseStyles}>
                                    <Collapse in={open.comments} unmountOnExit>
                                        <div className={s.innerCollapse}>
                                            <TextField
                                                key={2344}
                                                name="comments"
                                                value={editState.comments}
                                                onChange={(e) => setEditState((prev) => ({...prev, comments: e.target.value}))}
                                            />
                                            <LoadingButton 
                                                loading={loading}
                                                variant="contained"
                                                onClick={() => handleUpdate('comments')}
                                                disabled={abTest.basicFields.comments === editState.comments}
                                            >Update</LoadingButton>
                                        </div>
                                    </Collapse>
                                </TableCell>
                            </TableRow>



                            <TableRow key={11}>
                                <TableCell>Comments</TableCell>
                                <Tooltip title={abTest.basicFields.comments || ""}>
                                    <TableCell sx={{
                                        width: '300px',
                                        maxWidth:window.innerWidth <= IPHONE_PLUS_WIDTH ? '175px' : '300px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow:'hidden'
                                    }}>{abTest.basicFields.comments}</TableCell>
                                </Tooltip>

                                <TableCell>
                                    <IconButton size="small" onClick={() => handleCollapse("comments")}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>




                            

                            
                            
                            
                            <TableRow key={13}>
                                <TableCell>Created At</TableCell>
                                <TableCell>{formattedCreatedAt}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow key={14}>
                                <TableCell>Last Updated</TableCell>
                                <TableCell>{formattedUpdatedAt}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </LocalizationProvider>
        </div>
    );
};


const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    updateAbTestInDb: (abTest: AbTestInterface, field: keyof AbTestEditableFields, value: string) => updateAbTestInDb(abTest, field, value)
}

const mapStateToProps = createStructuredSelector<RootState, { theme: AdminState["theme"]}>({
    theme: selectTheme
});
export default connect(mapStateToProps, mapDispatch)(AbTestSummary);