import { ReduxAction } from "../../rootReducer"
import { ACTIONS, UnityApiState, UNITY_API_INIT_STATE } from "./interfaces"



export const unityApiReducer = (state: UnityApiState = UNITY_API_INIT_STATE, action: ReduxAction): UnityApiState => {
    switch(action.type) {
        
        
        // NORMAL ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_HAS_UNITY_CAMPAIGN:
            return {
                ...state,
                hasCampaignInApi: action.payload
            }
        case ACTIONS.SET_CHECK_UNITY_CAMPAIGN_LOADING:
            return {
                ...state,
                checkCampaignLoading: action.payload
            }
        case ACTIONS.SET_UNITY_CONFIG_KEYS:
            return {
                ...state,
                currConfigKeys: action.payload
            }
        default:
            return { ...state }
    }
}