import { useSelector } from "react-redux";
import { ProjectDataDropdownChoice } from "../../components/pages/ProjectDataPage/GetValueCountsArea/SaveValueCountQuery";
import { abTestDateSort } from "../../helpers/utils";
import { RootState } from "../rootReducer";


// export const PROJECTS_INITIAL_STATE: ProjectsState = {
//     projects: [],
//     gridRows: [],
// }



export const selectProjects = (rootState: RootState) => {
    const { projects } = rootState.projects;
    return projects;
}



export const useProjectIdsAndNamesSelector = () => {
    function selectProjectIdsAndNames(state: RootState) {

        const data: ProjectDataDropdownChoice[] = [];
        const { projects } = state.projects;
        for (const project of projects) {
            const { unitySettings } = project.apiInfo;
            if (unitySettings) {
                const { projectId } = unitySettings;
                data.push({
                    projectId,
                    projectName: project.name
                })
            }else{
                data.push({
                    projectId:project.name,
                    projectName: project.name
                })
            }
        }
        return data;
    }
    return useSelector(selectProjectIdsAndNames);
}



export const useAbTestsSelector = (projectName: string) => {
    function selectAbTests(state: RootState) {
        const { projects } = state.projects;
        const foundProj = projects.find(p => p.name === projectName);
        if (!foundProj) return null
        return foundProj.abTests.sort(abTestDateSort).map(test => test.basicFields.name);
    }
    return useSelector(selectAbTests);
}