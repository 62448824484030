import Breadcrumbs from '@mui/material/Breadcrumbs';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BROWSER_HISTORY } from '../..';
import { makeSlug } from '../../helpers/utils';
import { setNavAbTestName, setNavProjectName } from '../../redux/navigation/navActions';
import { ReduxAction } from '../../redux/rootReducer';
import s from './AppNavigation.module.scss'

type Props = {
    projectName?: string
    abTestName?: string
    setNavAbTestName: (s: string) => ReduxAction
    setNavProjectName: (s: string) => ReduxAction
}

/*  
    "Breadcrumbs" component to show the current path in format Projects / projectName / abTestName
    also allows supplies navigation to project page 
*/
const AppNavigation = ({ projectName, abTestName } : Props) => {
    const location      = useLocation();
    const baseUrl       = '/home/projects/';
    const onProjectPage = location.pathname === "/home/projects";


    function handleNavigation() {
        if (abTestName) {
            setNavProjectName(projectName!);
            BROWSER_HISTORY.push(baseUrl + `${makeSlug(projectName!)}`)
        }
    }

    function handleHome() {
        setNavProjectName(""); // resets both ab test and project nav name
        BROWSER_HISTORY.push("/home/projects")
    }
    
    return (
        <div className={s.wrap}>
            <Breadcrumbs>
                {location.pathname.includes("/home/projects") && (
                    <div
                        className={onProjectPage ? s.projectsPage : s.link}
                        onClick={handleHome}
                    >
                        <span>Projects</span>
                        {onProjectPage && (
                            <span className={s.seperator}>/</span>
                        )}
                    </div>
                )}
                {projectName && (
                    <div
                        style={
                            abTestName
                                ? {}
                                : {
                                      fontWeight: "bold",
                                      textDecoration: "none",
                                      cursor: "default",
                                  }
                        }
                        className={s.link}
                        onClick={handleNavigation}
                    >
                        {projectName}
                    </div>
                )}
                {abTestName && (
                    <div style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "default",
                    }}>{abTestName}</div>
                )}
            </Breadcrumbs>
        </div>
    );
};


const mapDispatch = {
    setNavAbTestName: (s: string) => setNavAbTestName(s),
    setNavProjectName: (s: string) => setNavProjectName(s)
}

export default connect(null, mapDispatch)(AppNavigation);