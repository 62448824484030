import { GridColDef, GridFilterItem } from "@mui/x-data-grid";
import axios from "axios";
import { ImageType } from "react-images-uploading";
import { LocalDesign } from "../components/pages/AbTestPage/DesignsArea/DesignGroup/DesignCardFinal/DesignFormFinal";
import { ProjectPageMatch } from "../components/pages/ProjectPage";
import { InitialDesignGroup } from "../redux/abtest/design-group/designGroupSagas";
import { Design } from "../redux/abtest/design-group/designs/interfaces";
import { DesignGroup } from "../redux/abtest/design-group/interfaces";
import { ABTestGridRow, AbTestInterface, UnityAbTest } from "../redux/abtest/interfaces";
import { AdminState } from "../redux/admin/interfaces";
import { NavState } from "../redux/navigation/interfaces";
import { ProjectGridRow, ProjectInterface, ProjectUniqueFields } from "../redux/projects/interfaces";
import { abTestsGridCols, BASE_URL, projectsGridCols } from "./constants";



/* --------------------------------------------------------------- DATA GRID HELPERS ----------------------------------------------------------------------*/

// formats grid rows for the projects page grid, sorts by createdAt field
export function formatProjectGridRows(projects: ProjectInterface[]): ProjectGridRow[] {
    const fmtted = projects.map((project, idx) => {
        const { createdAt, updatedAt, abTests } = project;
        return {
            name: project.name,
            creator: project.creator,
            createdAt,
            updatedAt: new Date(updatedAt).toLocaleDateString('en-GB'),
            id: idx,
            abTestsNum: abTests?.length
        }
    });
    return fmtted.sort(gridRowsDateSort).map(row => ({ ...row, createdAt: new Date(row.createdAt).toLocaleDateString('en-GB') }));
}





// formats grid rows for the abtests page grid
export function formatAbTestGridRows(abTests: AbTestInterface[]): ABTestGridRow[] {
    const fmtted = abTests.map((test, idx) => {
        const { startDate, endDate } = test.basicFields;
        let designsCount = 0;
        if (test.designGroups && test.designGroups.length > 0) {
            test.designGroups.forEach(group => {
                group.designs.forEach(design => {
                    designsCount++;
                })
            })
        }
        return {
            name: test.basicFields.name,
            startDate: startDate ? (
                new Date(test.basicFields.startDate).toLocaleDateString('en-GB')
            ) : (
                ""
            ),
            endDate: endDate ? (
                new Date(test.basicFields.endDate).toLocaleDateString('en-GB')
            ) : (
                ""
            ),
            comments: test.basicFields.comments,
            createdAt: test.createdAt,
            designsNum: designsCount,
            project: test.basicFields.project,
            creator: test.basicFields.creator,
            id: idx
        }
    });
    return fmtted.sort(gridRowsDateSort).map(row => ({ ...row, createdAt: new Date(row.createdAt).toLocaleDateString('en-GB') }));
}


// sorts the ab test grid rows by date
function gridRowsDateSort(a: ABTestGridRow | ProjectGridRow, b: ABTestGridRow | ProjectGridRow) {
    let aTime = new Date(a.createdAt).getTime();
    let bTime = new Date(b.createdAt).getTime();
    if (aTime > bTime) return -1
    if (aTime < bTime) return 1
    return 0;
}
export function abTestDateSort(a: AbTestInterface, b: AbTestInterface) {
    let aTime = new Date(a.createdAt).getTime();
    let bTime = new Date(b.createdAt).getTime();
    if (aTime > bTime) return -1
    if (aTime < bTime) return 1
    return 0;
}



// utility function to get columns formatted according to viewport width, and MUI styling protocol
export function getGridCols(type: "project" | "abTest", viewportWidth: number): GridColDef[] {
    if (type === "abTest") {
        return abTestsGridCols.map(col => {
            let width: number = 175;
            let flex: number = 1;
            if (viewportWidth > 1440) {
                return { ...col, flex }
            } else {
                return { ...col, width }
            }
        });
    } else {
        return projectsGridCols.map(col => {
            let width: number = 175;
            let flex: number = 1;
            if (viewportWidth > 1440) {
                return { ...col, flex }
            } else {
                return { ...col, width }
            }
        });
    }
};
/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------*/











/* 
    used in DesignFormFinal.tsx, to test for any changes on the form
    if empty is true, form will be undefined; if false, form must be defined
*/
type HasChangeReturn = {
    form?: FormData,
    empty: boolean
}
export function getDesignFormData(design: LocalDesign, init: LocalDesign): HasChangeReturn {
    let hasFieldChange = false;
    let hasImageChange = false;
    (Object.keys(design) as Array<keyof LocalDesign>).forEach((key) => {
        if (design[key] !== init[key] && !["createdAt", "updatedAt", "newImage"].includes(key)) {
            hasFieldChange = true;
        }

        if (design.newImage) {
            hasImageChange = true;
        }
    });
    if (!hasFieldChange && !hasImageChange) {
        return { empty: true }
    }


    const form = new FormData();

    if (hasImageChange) {
        const { file } = design.newImage as ImageType;
        form.append("newImage", file as File)
    }
    const { newImage, image, ...updatedDesign } = design;
    appendDesign(form, updatedDesign);
    return { empty: false, form }
}
function appendDesign(form: FormData, updatedDesign: Design) {
    form.append("design", JSON.stringify(updatedDesign));
}






// used for client side routing, preventing invalid chars
export function makeSlug(text: string): string {
    return text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
}









/*
    Checks if there are duplicate projects names with the same slug name
    PURPOSE: recall that we have to force url-friendly characters for react-router
             We route based off of project name and ab test name, and load state based off of the route. If the url-friendly
             characters are the same for different projects, there is a problem: how will
             the page know which project or ab test to render from rdx state?
    USED IN: projectpage.tsx only
    DETAILS: navProjectName will be blank if the user copied and pasted the url, and a string in normal flow
*/
type CheckProjectReturn = {
    count?: number,
    project?: ProjectInterface
}
export function checkProjects(
    navProjectName: string,
    projects: ProjectInterface[],
    match: ProjectPageMatch
): CheckProjectReturn {
    let project: ProjectInterface | undefined;

    // first if condition checks if someone copied and pasted the link into the browser from an existing session
    if (!navProjectName) {
        let count = 0;
        for (let i = 0; i < projects.length; ++i) {
            if (makeSlug(projects[i].name) === match.params.name) {
                count++;
                project = projects[i];
            }
        }

        // if more than one project matches the slug, than just go to projects page
        // maybe two projects could have similar names, and then the slug func returns the same value
        // e.g., projectXYZ%% and projectXYZ!!
        if (count > 1) return { count };
        return { project }
    } else { // normal navigation
        project = projects.find(p => p.name === navProjectName);
        return { project }
    }
}







/*
    copy of above, just only uses project names instead of all projects, for performance improvement
    within StepperArea.tsx (we don't want to always have the entire project array in StepperArea on every render)
*/

type CheckProjectNamesReturn = {
    count?: number,
    uniqueFields?: ProjectUniqueFields,

}
export function checkProjectNames(
    navProjectName: string,
    projectInfos: ProjectUniqueFields[],
    match: ProjectPageMatch
): CheckProjectNamesReturn {
    // first if condition checks if someone copied and pasted the link into the browser from an existing session
    let uniqueFields: Partial<ProjectUniqueFields> = {
        name: ""
    }
    if (!navProjectName) {
        let count = 0;
        for (let i = 0; i < projectInfos.length; ++i) {
            if (makeSlug(projectInfos[i].name) === match.params.name) {
                count++;
                uniqueFields.name = projectInfos[i].name;
                uniqueFields.apiInfo = projectInfos[i].apiInfo;
            }
        }

        // if more than one project matches the slug, than just go to projects page
        // maybe two projects could have similar names, and then the slug func returns the same value
        // e.g., projectXYZ%% and projectXYZ!!
        if (count > 1) return { count };
        if (uniqueFields.name && uniqueFields.apiInfo) {
            return { uniqueFields: uniqueFields as ProjectUniqueFields } // this will be filled out 
        } else {
            return {}
        }
    } else { // normal navigation
        const uniqueFields = projectInfos.find(p => p.name === navProjectName);
        return { uniqueFields: uniqueFields }
    }
}







// checks for the session to see if its still good
export async function apiCheck() {
    const url = BASE_URL + '/user/check'
    return axios.get(url, {}).then((res) => {
        return res;
    })
        .catch((error) => {
            if (error.response) {
                return error.response;
            }
        });
}







// helps with the axios interceptors in App.tsx, checks if new headers are different
export function hasSameHeaders(prevHeaders: AuthHeaders, newHeaders: any): boolean {

    if (
        prevHeaders.username !== newHeaders.username
        ||
        prevHeaders.refreshToken !== newHeaders.refreshtoken
        ||
        prevHeaders.sessionId !== newHeaders.sessionid
    ) {
        return false
    } else {
        return true
    }
}







// in axios interceptors, ensures that all headers have the correct format; meaning a username refreshToken and sessionId all exist before and after
type AuthHeaders = {
    sessionId: AdminState["sessionId"],
    refreshToken: AdminState["refreshToken"],
    username: AdminState["username"]
}
export function hasHeaderFormat(prevHeaders: AuthHeaders, newHeaders: any): boolean {
    if (prevHeaders.username && prevHeaders.refreshToken && prevHeaders.sessionId
        && newHeaders.username && newHeaders.refreshtoken && newHeaders.sessionid) {
        return true;
    } else {
        return false;
    }
}






/* 
   axios interceptors
   checks backend response, to make sure we are always sending headers.
   may be empty string, but never undefined
   it will only be empty string in the logout route
*/
export function newHeadersValid(newHeaders: any) {
    if (newHeaders.username !== undefined
        && newHeaders.refreshtoken !== undefined
        && newHeaders.sessionid !== undefined) {
        return true;
    } else {
        return false;
    }
}







/*
    creates a formdata object to create a new design group on the backend
*/

export function fmtDesignGroupData(group: InitialDesignGroup, abTestId: AbTestInterface["_id"]): FormData {
    const formData = new FormData();
    formData.append("name", group.name);
    formData.append("creator", group.creator);
    formData.append("abTestId", abTestId);
    if (group.designs.length > 0) {
        for (const design of group.designs) {
            const { image, ...otherProps } = design;
            if (image) {
                const { file } = image as ImageType
                formData.append(design.name, file as File);
            }
            // if adding text only designs, add logic here
            formData.append("designs[]", JSON.stringify(otherProps));
        }
    }
    return formData;
}




// if a string appears twice in an array
export function containsDuplicateStr(array: string[]): boolean {
    const vals: { [key: string]: boolean } = {};
    for (const string of array) {
        if (string in vals) {
            return true
        }
        vals[string] = true;
    }
    return false;
}






// makes sure all design groups are the same length (same as the first design group)
export function groupsSameLength(groups: DesignGroup[]) {
    let baseLength = groups[0].designs.length;
    for (let i = 1; i < groups.length; i++) {
        if (groups[i].designs.length !== baseLength) {
            return false;
        }
    }
    return true;
}







export function hasCampaignData(test: UnityAbTest) {
    const { campaignConfig } = test.abTestApiInfo.unitySettings!;
    return campaignConfig && campaignConfig.campaignId ? true : false;
}







export function getFileSizeMB(file: File): number {
    const { size } = file;
    const mbs = size / 1024 / 1024;
    return mbs
}





export function getFileSizeMsg(file: File): string {
    const { size } = file;
    const kbs = size / 1024;
    if (kbs < 1000) {
        return `${kbs.toFixed(2)}kb`
    } else {
        return `${(kbs / 1024).toFixed(2)}mb`
    }
}









export function arraysEqual(a: string[] | string, b: string[] | string) {
    /*
        Array-aware equality checker:
        Returns whether arguments a and b are == to each other;
        however if they are equal-lengthed arrays, returns whether their 
        elements are pairwise == to each other recursively under this
        definition.
    */
    if (a instanceof Array && b instanceof Array) {
        if (a.length !== b.length)  // assert same length
            return false;
        for (var i = 0; i < a.length; i++)  // assert each element equal
            if (!arraysEqual(a[i], b[i]))
                return false;
        return true;
    } else {
        return a === b;  // if not both arrays, should be the same
    }
}




export function getFilterModelsWith(filterValue: NavState["filterValue"], cols: GridColDef[]): GridFilterItem[] {
    const filterItems: GridFilterItem[] = [];
    for (const col of cols) {
        if (col.field === 'name' && col.headerName! === "Name") {
            filterItems.push({
                columnField: col.field,
                //operator: ""
            })
        }
    }
    return filterItems
}