import {
  ApiInfo,
  FirebaseApiInfo,
  FirebaseSettings,
  UnityApiInfo,
  UnitySettings,
} from "../projects/interfaces";
import { DesignGroup } from "./design-group/interfaces";

// ------------------------------------------------------{{  REDUX  }}------------------------------------------------------------
export const ACTIONS = {
  DELETE_AB_TEST_IN_DB: "DELETE_AB_TEST_IN_DB",
  CREATE_AB_TEST_IN_DB: "CREATE_AB_TEST_IN_DB",
  UPDATE_AB_TEST_IN_DB: "UPDATE_AB_TEST_IN_DB",
  LOAD_AB_TEST_PAGE_DATA: "LOAD_AB_TEST_PAGE_DATA",
  GET_FEEDBACKS:"GET_FEEDBACKS",
  UPDATE_FEEDBACK_AB_TEST_IN_DB: "UPDATE_FEEDBACK_AB_TEST_IN_DB",


};

// ------------------------------------------------------{{  AB TEST  }}------------------------------------------------------------

// below three are copies of db interfaces - below 3 must be kept in sync between frontend and backend
export interface AbTestBasicFields {
  name: string;
  startDate: string;
  endDate: string;
  comments: string;
  project: string;
  creator: string;
}

export interface FeedbackCombo {
  event: string;
  parameter: string;
}
export interface FeedbackData { [key: string]: any}

export interface AbTestInterface {
  _id: string;
  basicFields: AbTestBasicFields;
  designGroups: DesignGroup[];
  feedbackCombo?: FeedbackCombo;
  feedbackData?: FeedbackData;
  abTestApiInfo: AbTestApiInfo;
  createdAt: string;
  updatedAt?: string;
}

export interface AbTestApiInfo {
  api: ApiInfo["api"];
  unitySettings?: UnityAbTestSettings;
  firebaseSettings?: FirebaseAbTestSettings;
}

// ------------------------------------------------------------ {{ API SPECIFIC INTERFACES }} ------------------------------------------------------------

/* -----------    unity    -------------*/
// we want to add campaignConfig to the abTestApiInfo.unitySettings field
export interface UnityAbTestSettings extends UnitySettings {
  campaignConfig?: UnityCampaignConfig;
}

export interface UnityCampaignConfig {
  campaignId: string;
  createdAt: string;
}

// overwrite unitySettings to include above interface that now has campaignConfig
export interface UnityAbTestApiInfo
  extends Omit<UnityApiInfo, "unitySettings"> {
  unitySettings: UnityAbTestSettings;
}
export interface UnityAbTest extends Omit<AbTestInterface, "abTestApiInfo"> {
  abTestApiInfo: UnityAbTestApiInfo;
}

/* -----------    firebase    -------------*/
export interface FirebaseAbTestSettings extends FirebaseSettings {}
export interface FirebaseAbTestApiInfo
  extends Omit<FirebaseApiInfo, "firebaseSettings"> {
  firebaseSettings: FirebaseAbTestSettings;
}
export interface FirebaseAbTest extends Omit<AbTestInterface, "abTestApiInfo"> {
  abTestApiInfo: FirebaseAbTestApiInfo;
}

// ------------------------------------------------------------ {{ USE FOR REACT COMPONENTS }} ------------------------------------------------------------
export interface ABTestGridRow extends Omit<AbTestBasicFields, "updatedAt"> {
  designsNum: number;
  id: number;
  createdAt: string; // date as localeDateString in en-GB format
}
