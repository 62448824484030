import { createContext, ReactNode, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../redux/rootReducer'
import { ProjectInterface, ProjectsState } from '../../redux/projects/interfaces'
import s from './ProjectPage.module.scss'
import PageTitle from '../reusable/PageTitle';
import DataTable from '../reusable/DataTable';
import { getGridCols } from '../../helpers/utils';
import { checkProjects, formatAbTestGridRows } from '../../helpers/utils';
import AbTestsCustomFooter from './ProjectPage/DataGrid/AbTestsCustomFooter';
import DeleteDataButton from '../reusable/DeleteDataButton';
import UpdateProjectButton from './ProjectPage/UpdateProjectButton';
import { match, Redirect } from 'react-router-dom'
import AppNavigation from '../reusable/AppNavigation'

export interface ProjectPageMatch extends match {
    params: { name: string } // params is the url params from react router, (e.g., /:something/abtests)
}
type Props = {
    match: ProjectPageMatch,
    navProjectName: string,
    projects: ProjectsState["projects"]
}

export const ProjectContextTwo = createContext<ProjectInterface | null>(null);



const ProjectPage = ({ navProjectName, projects, match } : Props) => {
    const { count, project } = checkProjects(navProjectName, projects, match);
    if (count) {
        return <Redirect to="/home/projects"/>
    }
    const projectNames = projects.map(p => p.name);
    let rows;
    if (project) {
        rows = formatAbTestGridRows(project.abTests)
    }

    return (
        <div className={s.wrap}>
            <AppNavigation projectName={project? project.name : ""}/>
            <PageTitle text={`${project ? project.name : ""}`}/>
            {
                project && (
                    <ProjectContextTwo.Provider value={project}>
                        <h2>A/B Tests</h2>
                        <DataTable 
                            cols={getGridCols("abTest", window.innerWidth)} 
                            rows={rows ? rows : []}
                            components={{
                                Footer: AbTestsCustomFooter
                            }}
                            purpose="abTest"
                        />
                        <div className={s.btns}>
                            <DeleteDataButton
                                projectName={project.name}
                                dataType="project"
                            />
                            <UpdateProjectButton
                                project={project}
                                projectNames={projectNames}
                            />
                        </div>
                    </ProjectContextTwo.Provider>
                )
            }
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { projects }    = rootState.projects;
    const { projectName } = rootState.nav;
    return { 
        projects, 
        navProjectName: projectName 
    }
}
export default connect(mapStateToProps)(ProjectPage);