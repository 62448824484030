import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
} from "@redux-saga/core/effects";
import axios, { AxiosResponse } from "axios";
import { BROWSER_HISTORY } from "../..";
import {
  AXIOS_ERROR,
  BASE_URL,
  ERR_SNACKBAR,
  SUCCESS_SNACKBAR,
  UNSUCCESS,
} from "../../helpers/constants";
import {
  formatProjectGridRows,
  hasCampaignData,
  makeSlug,
} from "../../helpers/utils";
import { setAlert } from "../admin/adminActions";
import {
  getEventDataOurApi,
  setCurrentDynamicGroupsOurApi,
  setEventDataLoading,
} from "../external-apis/our-api/ourApiActions";
import {
  GetEventDataOurApiAction,
  handleGetEventDataOurApi,
} from "../external-apis/our-api/ourApiSagas";
import {
  checkUnityCampaignExists,
  getUnityConfigKeys,
  setUnityCampaignCheckLoading,
} from "../external-apis/unity/unityActions";
import {
  CheckUnityCampaignExistsAction,
  GetUnityConfigKeysAction,
  handleCheckUnityCampaignExists,
  handleGetUnityConfigKeys,
} from "../external-apis/unity/unitySagas";
import { selectHasCampaignInApi } from "../external-apis/unity/unitySelectors";
import { setAbTestDataLoaded } from "../navigation/navActions";
import { ProjectInterface } from "../projects/interfaces";
import {
  replaceAbTest,
  replaceProject,
  setProjects,
} from "../projects/projectsActions";
import { selectProjects } from "../projects/projectsSelectors";
import { setCreateAbTestFormOpen } from "./abtest-init/abtestInitActions";
import {
  createAbTestInDb,
  deleteAbTestInDb,
  loadAbTestPageData,
  updateAbTestAddFeedback,
  updateAbTestInDb,
} from "./abTestActions";
import { designGroupSagas } from "./design-group/designGroupSagas";
import { DesignGroup } from "./design-group/interfaces";
import {
  AbTestBasicFields,
  AbTestInterface,
  ACTIONS,
  UnityAbTest,
} from "./interfaces";

interface InitData {
  success: boolean;
  projects?: ProjectInterface[];
  eventNames?: string[];
  error?: string;
}

// ------------------------------------------------------------------ Create AB Test ----------------------------------------------------------------------------------------//
type CreateAbTestAction = ReturnType<typeof createAbTestInDb>;
type CreateAbTestResponse = {
  success: boolean;
  error?: string;
  updatedProject?: ProjectInterface;
};
function* handleCreateAbTestInDb({ payload }: CreateAbTestAction) {
  try {
    const { name, comments, project } = payload;
    if (!name || !project)
      throw new Error("name and project name are required.");
    const basicFields: AbTestBasicFields = {
      name: name,
      comments: comments,
      project: project, // extremely important to have this here
      startDate: "",
      endDate: "",
      creator: "admin",
    };

    // post data
    const url = BASE_URL + "/projects/abtests";
    const res: AxiosResponse<CreateAbTestResponse> = yield call(
      axios.post,
      url,
      { basicFields }
    );

    if (!res) throw new Error(AXIOS_ERROR);
    const { success, error, updatedProject } = res.data;
    if (error) throw new Error(error);
    if (!success) throw new Error(UNSUCCESS);
    if (!updatedProject)
      throw new Error("No updated project returned. Contact developer.");
    yield put(replaceProject(updatedProject));
    yield put(
      setAlert({
        msg: "Successfully created A/B Test.",
        config: SUCCESS_SNACKBAR,
      })
    );
    yield put(setCreateAbTestFormOpen(false));
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptCreateAbTestInDb() {
  yield takeLatest([ACTIONS.CREATE_AB_TEST_IN_DB], handleCreateAbTestInDb);
}

type UpdateAbTestAddFeedbackInDb = ReturnType<typeof updateAbTestAddFeedback>;
type UpdateAbTestAddFeedback = {
  success: boolean;
  error?: string;
  updatedAbTest?: AbTestInterface;
  projectName?: string;
};
function* handleUpdateAbTestAddFeedback({
  payload,
}: UpdateAbTestAddFeedbackInDb) {
  const id = payload.abTest._id;
  const feedbackCombo = payload.abTest.feedbackCombo;

  try {
    if (!feedbackCombo?.event || !feedbackCombo?.parameter || !id)
      yield put(
        setAlert({
          msg: "missing parameters",
          config: ERR_SNACKBAR,
        })
      );

    const url = BASE_URL + "/projects/abtests/feedback";
    const res: AxiosResponse<UpdateAbTestAddFeedback> = yield call(
      axios.patch,
      url,
      { abTest: payload.abTest }
    );
    if (!res) throw new Error(AXIOS_ERROR);
    const { success, error, updatedAbTest } = res.data;
    if (error) throw new Error(error);
    if (!success) throw new Error(UNSUCCESS);
    if (!updatedAbTest)
      throw new Error("Data wasn't returned properly. Contact developer.");
    yield put(replaceAbTest(updatedAbTest!));
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptUpdateAbTestAddFeedbackInDb() {
  yield takeLatest(
    [ACTIONS.UPDATE_FEEDBACK_AB_TEST_IN_DB],
    handleUpdateAbTestAddFeedback
  );
}

// ------------------------------------------------------------------ Update A/B Test ----------------------------------------------------------------------------------------//
type UpdateAbTestInDbAction = ReturnType<typeof updateAbTestInDb>;
type UpdateAbTestResponse = {
  success: boolean;
  error?: string;
  updatedAbTest?: AbTestInterface;
  projectName?: string;
};
function* handleUpdateAbTestInDb({ payload }: UpdateAbTestInDbAction) {
  try {
    // if updating ab test name, make sure it's not already taken
    if (payload.field === "name") {
      const projects: ProjectInterface[] = yield select(selectProjects);
      const project = projects.find(
        (p) => p.name === payload.abTest.basicFields.project
      );
      if (!project)
        throw new Error(
          `Please contact developer. The project ${payload.abTest.basicFields.name} was not found in the ab test.`
        );
      for (let test of project.abTests as AbTestInterface[]) {
        if (test.basicFields.name === payload.value) {
          throw new Error("A/B Test already exists. Choose another.");
        }
      }
    }
    const url = BASE_URL + "/projects/abtests";
    const res: AxiosResponse<UpdateAbTestResponse> = yield call(
      axios.patch,
      url,
      { abTestUpdate: payload }
    );
    if (!res) throw new Error(AXIOS_ERROR);
    const { success, error, updatedAbTest, projectName } = res.data;
    if (error) throw new Error(error);
    if (!success) throw new Error(UNSUCCESS);
    if (!updatedAbTest || !projectName)
      throw new Error("Data wasn't returned properly. Contact developer.");
    yield put(replaceAbTest(updatedAbTest!));

    // reload page if name is changed, since AbTestPage is based off of match.params
    if (payload.field === "name") {
      BROWSER_HISTORY.goBack();
    }
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptUpdateAbTestInDb() {
  yield takeLatest([ACTIONS.UPDATE_AB_TEST_IN_DB], handleUpdateAbTestInDb);
}

// ------------------------------------------------------------------ {{ Delete AB Test }} ----------------------------------------------------------------------------------------//
type DeleteAbTestInDbAction = ReturnType<typeof deleteAbTestInDb>;
type DeleteAbTestResponse = {
  success: boolean;
  project?: ProjectInterface;
  error?: string;
};
function* handleDeleteAbTestInDb({ payload }: DeleteAbTestInDbAction) {
  try {
    const { projectName, abTestId } = payload;
    const url = BASE_URL + "/projects/abtests";
    const res: AxiosResponse<DeleteAbTestResponse> = yield call(
      axios.delete,
      url,
      { data: { projectName, abTestId } }
    );

    if (!res) throw new Error(AXIOS_ERROR);
    const { error, success, project } = res.data;
    if (error) throw new Error(error);
    if (!success) throw new Error(UNSUCCESS);
    if (!project)
      throw new Error("No project returned. Please contact developer.");
    yield put(replaceProject(project));
    yield put(
      setAlert({
        msg: "Successfully deleted ab test",
        config: SUCCESS_SNACKBAR,
      })
    );

    // change location after deletion
    BROWSER_HISTORY.push(`/home/projects/${makeSlug(projectName)}`);
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptDeleteAbTestInDb() {
  yield takeLatest([ACTIONS.DELETE_AB_TEST_IN_DB], handleDeleteAbTestInDb);
}

// ------------------------------------------------------------------ {{ Load unity ab test page data }} ----------------------------------------------------------------------------------------//
function* loadUnityAbTestData(unityAbTest: UnityAbTest) {
  try {
    const hasCampaignFields = hasCampaignData(unityAbTest);
    const hasCampaignInApi: string = yield select(selectHasCampaignInApi);
    // then load campaign check data || config keys
    // if we have campaign data, we don't need to get config keys since we disable the launch campaign btn if a campaign exists
    if (hasCampaignFields) {
      const secondAction: CheckUnityCampaignExistsAction =
        checkUnityCampaignExists(
          unityAbTest.abTestApiInfo.unitySettings!.campaignConfig!.campaignId,
          unityAbTest.basicFields.project
        );
      yield call(handleCheckUnityCampaignExists, secondAction);
    } else if (!hasCampaignFields || !hasCampaignInApi) {
      const thirdAction: GetUnityConfigKeysAction = getUnityConfigKeys(
        unityAbTest.abTestApiInfo.unitySettings.projectId
      );
      yield call(handleGetUnityConfigKeys, thirdAction);
    }
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* loadDesignGroupData(designGroups: DesignGroup[]) {
  try {
    // first load dynamic event property data for each design group
    yield put(setCurrentDynamicGroupsOurApi(designGroups));
    for (const designGroup of designGroups) {
      const { eventPropertyCombo } = designGroup;
      if (eventPropertyCombo) {
        const { eventName, otherEventName, property, trialName } =
          eventPropertyCombo;
        if (eventName && otherEventName && property) {
          const firstAction: GetEventDataOurApiAction = getEventDataOurApi(
            eventName,
            otherEventName,
            designGroup.name,
            trialName
          );
          yield call(handleGetEventDataOurApi, firstAction);
        }
      }
    }
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

/*   we use "take" here because take will block all other actions until the saga interceptAbTestPageLoad is complete */

/*
    The purpose of this saga is to prevent other actions from firing off simultaneously, 'takeLatest' and 'takeEvery' allow
    concurrent sagas, whilst 'take' blocks all sagas/actions until its finished. 'take' solved the problem i was facing
    that would automatically log users out after the sessionid would expire. AbTestPage.tsx used to call all the sagas at the
    same time; this would mean that if the session was expired on the first request, the next ones would all fail because 
    when a sessionId expires, I delete the current sessionId and refreshToken in the redis cache, and then create a new sessionId and refreshtoken.
    I then send it back to the frontend, and update the sessionId and refreshToken in redux state for use of later requests.

    Essentially, with the setup I have for sessions, the app should not send two requests at the same time. If a request is sent
    with expired tokens, it will return 403 forbidden and automatically log out the user.
*/
type HandleLoadAbTestPageAction = ReturnType<typeof loadAbTestPageData>;
function* interceptAbTestPageLoad() {
  while (true) {
    const { payload }: HandleLoadAbTestPageAction = yield take(
      ACTIONS.LOAD_AB_TEST_PAGE_DATA
    );
    const { abTestApiInfo } = payload;
    const { api } = abTestApiInfo;
    /* ---------- make all loading buttons load on abtest page, at the same time ----------*/
    yield put(setEventDataLoading(true));
    // we wont check campaign if no campaign data
    if (api === "UNITY" && hasCampaignData(payload as UnityAbTest)) {
      yield put(setUnityCampaignCheckLoading(true));
    }

    /* ------- load event/property data from our api, since all ab tests do this ------------*/
    yield loadDesignGroupData(payload.designGroups);

    /* ---------------------------- load unity ab test data next -----------------------------*/
    if (abTestApiInfo.api === "UNITY") {
      yield loadUnityAbTestData(payload as UnityAbTest);
    }

    /* ---------------------------- remove all loading states --------------------------------*/
    yield put(setEventDataLoading(false));
    if (api === "UNITY" && hasCampaignData(payload as UnityAbTest)) {
      // we wont check campaign if no campaign data
      yield put(setUnityCampaignCheckLoading(false));
    }
    yield put(setAbTestDataLoaded(true));
  }
}

export function* abTestSagas() {
  yield all([
    call(interceptCreateAbTestInDb),
    call(interceptDeleteAbTestInDb),
    call(interceptUpdateAbTestInDb),
    call(interceptAbTestPageLoad),
    call(designGroupSagas),
    call(interceptUpdateAbTestAddFeedbackInDb),
  ]);
}
