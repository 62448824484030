export const ACTIONS = {
    SET_ALL_EVENT_NAMES_OUR_API: "SET_ALL_EVENT_NAMES_OUR_API",
    SET_GROUP_PROPERTY_NAMES_OUR_API: "SET_GROUP_PROPERTY_NAMES_OUR_API",
    GET_EVENT_DATA_OUR_API: "GET_EVENT_DATA_OUR_API",
    SET_MIN_MAX_DATES_OUR_API: "SET_MIN_MAX_DATES_OUR_API",
    CLEAR_PROPERTIES_AND_DATES_OUR_API: "CLEAR_PROPERTIES_AND_DATES_OUR_API",
    SET_CURRENT_DYNAMIC_GROUPS_OUR_API: "SET_CURRENT_DYNAMIC_GROUPS_OUR_API",
    SET_EVENT_DATA_LOADING: "SET_EVENT_DATA_LOADING"
}



export interface OurApiState {
    allEventNames: string[],                            // all possible event names in the redis cache
    designGroupDynamicData: DesignGroupDynamicData[],   
    eventDataLoading: boolean
}



/* 
    minEvent and maxEvent were used before primarily to allow the user to choose a date range in event property fields component
    If a user can choose a date range, then we would need to limit the date range they can choose to reflect the possible date ranges
    that exist in the database
*/
export interface DesignGroupDynamicData {
    name: string,             // unique identifer
    properties: string[]      // third dropdown in EventPropertyFields component; see eventproperty.ts for more info
    minEventStartDate: string // first available event time, based off of eventName and otherEventName (first two dropdowns in EventPropertyFields.tsx)
    maxEventEndDate: string   // latest event time based off of eventName and otherEventName (first two dropdowns in EventPropertyFields.tsx)
}



export const OUR_API_INIT_STATE: OurApiState = {
    allEventNames: [],
    designGroupDynamicData: [],
    eventDataLoading: false,    // for syncing ab test page loading spinners in GetDesignData.tsx
}


