import { Button } from '@mui/material';
import { useState } from 'react'
import { ProjectInterface } from '../../../redux/projects/interfaces';
import CustomModal from '../../reusable/CustomModal';
import UpdateProjectForm from './UpdateProjectForm';
import s from './UpdateProjectButton.module.scss'

type Props = {
    project: ProjectInterface,
    projectNames: string[]
}

const UpdateProjectButton = ({ project, projectNames } : Props) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={s.wrap}>
            <Button 
                variant="outlined" 
                color="warning"
                onClick={() => setOpen(true)}
            >UPDATE PROJECT</Button>
            <CustomModal open={open} setOpen={setOpen}>
                <UpdateProjectForm
                    setOpen={setOpen}
                    project={project}
                    projectNames={projectNames}
                />
            </CustomModal>
        </div>
    );
};

export default UpdateProjectButton;