import { DesignGroup } from "../../abtest/design-group/interfaces";
import { ACTIONS } from "./interfaces";



export function setGroupPropertyNames(propertyNames: string[], groupName: string) {
    return {
        type: ACTIONS.SET_GROUP_PROPERTY_NAMES_OUR_API,
        payload: { propertyNames, groupName }
    }
}





export function getEventDataOurApi(eventName: string, otherEventName: string, groupName: string,trialName: string) {
    return {
        type: ACTIONS.GET_EVENT_DATA_OUR_API,
        payload: {eventName, otherEventName, groupName, trialName}
    }
}





export function setMinMaxDateOurApi(dates: { start: string, end: string}, groupName: string) {
    return {
        type: ACTIONS.SET_MIN_MAX_DATES_OUR_API,
        payload: { dates, groupName }
    }
}




export function clearPropertiesAndDatesOurApi(groupName: string) {
    return {
        type: ACTIONS.CLEAR_PROPERTIES_AND_DATES_OUR_API,
        payload: groupName
    }
}





export function setCurrentDynamicGroupsOurApi(designGroups: DesignGroup[]) {
    return {
        type: ACTIONS.SET_CURRENT_DYNAMIC_GROUPS_OUR_API,
        payload: designGroups
    }
}




export function setAllEventNamesOurApi(eventNames: string[]) {
    return {
        type: ACTIONS.SET_ALL_EVENT_NAMES_OUR_API,
        payload: eventNames
    }
}





export function setEventDataLoading(bool: boolean) {
    return {
        type: ACTIONS.SET_EVENT_DATA_LOADING,
        payload: bool
    }
}

