import { useSelector } from "react-redux";
import { LocalMatch } from "../../components/pages/AbTestPage";
import { makeSlug } from "../../helpers/utils";
import { ProjectInterface } from "../projects/interfaces";
import { RootState } from "../rootReducer";
import { AbTestInterface } from "./interfaces";

// gets the ab test from rdx state based on the current client side url
export const useAbTestSelector = (match: LocalMatch) => {
    let projectCount = 0, abTestCount = 0;
    function selectAbTests(state: RootState) {
        //pluck off projects
        const { projects } = state.projects;
        let { projectName } = state.nav

        let project: ProjectInterface | undefined;
        if (projectName) {
            project = projects.find(p => p.name === projectName);
        } else {
            for (let i = 0; i < projects.length; ++i){
                if(makeSlug(projects[i].name) === match.params.name) {
                    projectCount++;
                    project = projects[i];
                    projectName = projects[i].name;
                }
            }
        }
        if (project) {
            let abTest: AbTestInterface | undefined;
            for (let i = 0; i < project.abTests.length; ++i){
                if(makeSlug(project.abTests[i].basicFields.name) === match.params.test) {
                    abTestCount++;
                    abTest = project.abTests[i];
                }
            }
            if (abTest) return { abTest, projectCount, abTestCount, projectName }
        }
        return { abTest: null, projectCount, abTestCount, projectName }
    }
    return useSelector(selectAbTests)
}
