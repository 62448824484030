import { ReduxAction } from "../rootReducer"
import { ACTIONS, AdminState, ADMIN_INIT_STATE } from "./interfaces"



export const adminReducer = (state: AdminState = ADMIN_INIT_STATE, action: ReduxAction): AdminState => {
    switch(action.type) {
        
        
        // NORMAL ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_AUTHED:
            let { value, username, sessionId, refreshToken } = action.payload;
            return {
                ...state,
                authed: value,
                username: value === true ? username : "",
                sessionId,
                refreshToken
            }
        case ACTIONS.SET_ALERT:
            return {
                ...state,
                alertMsg: action.payload
            }
       
        case ACTIONS.TOGGLE_THEME:
            return {
                ...state,
                theme: state.theme === "dark" ? "light" : "dark"
            }
        default:
            return { ...state }
    }
}