import { Paper, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { AdminState, AlertMsg } from '../../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../../redux/rootReducer';
import { setAlert } from '../../../../redux/admin/adminActions';
import { BASE_URL, ERR_SNACKBAR } from '../../../../helpers/constants';
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import { LoadingButton } from '@mui/lab';
import s from './EventParameterDropdown.module.scss'
import { ReducerType, ValueCounts } from '../GetValueCountsArea';






type Props = {
    theme: AdminState["theme"], // rdx
    allEventNames: string[], // rdx
    state: ReducerType // parent
    setAlert: (alert: AlertMsg) => ReduxAction // rdx
    setValueCounts: Dispatch<SetStateAction<ValueCounts>> //parent
    dispatch: Dispatch<any> //parent
};

type GetPropertiesResponse = {
    success: boolean
    error?: string
    properties?: string[]
};
type GetValueCountsResponse = {
    success: boolean
    error?: string
    valueCounts: ValueCounts
}


/*
    IMPORTANT: The EventPropertyDatas in the db determine what a parameter/property and event are
               Example:
               {
                    "_id" : ObjectId("61f3abe3c3cd3484c14a1aa3"),
                    "eventName" : "D1_Properties",
                    "custom_params" : {
                            "MainIAP" : "MainIAPtest",      <---- the key (MainIAP) is a Property/Parameter, the value is the Parameter/Property value
                            "Are_u_Sure" : "r",
                            "PurchaseBtn" : "PurchaseButton"
                    },
                    "createdAt" : ISODate("2022-01-28T08:40:03.088Z"),
                    "__v" : 0
                }

                Value count example: say we have 10 events (1 event is represented by the entire database record above)
                                     first we filter and find only the events in the 10 that have the parameter/property "MainIAP"
                                     lets say we now have 8 records with "MainIAP" after filtering.
                                     We then get all the possible VALUES of the key into an object, and count how many times each different value appears
                                     In the above case, we would simply add the key "MainIAPtest" to the object, and add a count in a for loop
    PURPOSE OF COMPONENT:
                Displays all events in one dropdown
                On selection of an event, it queries the db for all properties that exist for db records that match the event
                performs the query to get all the valueCounts
*/


const EventParameterDropdown = ({
    theme,
    allEventNames,
    state,
    dispatch,
    setValueCounts,
    setAlert
} : Props) => {


    const [loading, setLoading] = useState(false);
    const [countsLoading, setCountsLoading] = useState(false);
    useEffect(() => {
        return () => {
            setLoading(false);
            setCountsLoading(false);
        }
    }, [])

    function handleEventsSelect(e: any, newValue: string | null) {
        dispatch({type: 'SET_EVENT', payload: newValue});
        if (newValue) getAllPropertiesFrom(newValue);
        
    }


    function handleParameterSelect(e: any, newValue: string | null) {
        dispatch({type: 'SET_PARAMETER', payload: newValue});
    }


    // gets all the properties/parameters from one event (keys of custom_params on the event property data record in db)
    async function getAllPropertiesFrom(event: string) {
        setLoading(true);
        try {
            const url = BASE_URL + '/analytics/techlabfze/event/parameters';
            const [e1, response] = await to<AxiosResponse<GetPropertiesResponse>, any>(axios.get(url, { params: { event }}));
            if (e1) throw new Error(e1.message);
            if (!response) throw new Error('No data returned from get properties query.');
            const {error, properties, success} = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error('unsuccessful operation.');
            dispatch({ type: 'SET_PARAMETERS', payload: properties});
        } catch (e: any) {
            console.log(e);
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
        setLoading(false);
    }

    // performs the operation mentioned in the notes above
    async function getValueCounts() {
        if (!state.event || !state.parameter) return
        setCountsLoading(true);
        try {
            const url = BASE_URL + '/analytics/techlabfze/projectdata/valuecounts';
            const [e1, response] = await to<AxiosResponse<GetValueCountsResponse>, any>(
                axios.get(
                    url, 
                    { params: { 
                        event: state.event, 
                        parameter: state.parameter 
                    }}
                )
            );
            if (e1) throw new Error(e1.message);
            if (!response) throw new Error('No data returned from get parameters query.');
            const {error, valueCounts, success} = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error('unsuccessful operation.');
            setValueCounts(valueCounts);
        } catch (e: any) {
            console.log(e);
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
        setCountsLoading(false);
    }


    return (
        <Paper className={s.wrap} sx={theme === "dark" ? { backgroundColor: "#262626" } : {}}>
            <div className={s.innerWrap}>
                <div className={s.title}>{"Choose event and parameter"}</div>

                <Autocomplete
                    renderInput={(params) => (
                        <TextField 
                            name="events" 
                            label="event" 
                            {...params} 
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                style: {fontSize: "13px"}
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: {fontSize: "13px"}
                            }}
                        />
                    )}
                    options={allEventNames.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    onChange={handleEventsSelect}
                    value={state.event}
                    classes={{option: s.option}}
                    fullWidth
                />

                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            name="parameter"
                            label="parameters"
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                style: {fontSize: "13px"},
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={17}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: {fontSize: "13px"}
                            }}
                            size="small"
                        />
                    )}
                    options={state.parameters.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    onChange={handleParameterSelect}
                    disabled={!state.event}
                    value={state.parameter}
                    classes={{option: s.option}}
                    fullWidth
                />

                <LoadingButton loading={countsLoading} variant="outlined" onClick={getValueCounts}>Get Value Counts</LoadingButton>
            </div>
        </Paper>
    );
};

const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert)
}

const mapStateToProps = (state: RootState) => {
    const { theme } = state.admin;
    const { allEventNames } = state.externalApis.ourApi;
    return { theme, allEventNames }
}
export default connect(mapStateToProps, mapDispatch)(EventParameterDropdown);