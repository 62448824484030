import { Design } from "./designs/interfaces";

export const DESIGN_GROUP_ACTIONS = {
  DELETE_DESIGN_GROUP_IN_DB: "DELETE_DESIGN_GROUP_IN_DB",
  EDIT_DESIGN_GROUP_IN_DB: "EDIT_DESIGN_GROUP_IN_DB",
  CREATE_DESIGN_GROUP_IN_DB: "CREATE_DESIGN_GROUP_IN_DB",
  GET_DESIGN_GROUP_SCORES: "GET_DESIGN_GROUP_SCORES",
};

// ------------------------------------------------------{{  DESIGN GROUP  }}------------------------------------------------------------
// same as DB
export interface DesignGroup {
  name: string;
  creator: string;
  createdAt: string;
  updatedAt: string;
  designs: Design[];
  eventPropertyCombo?: EventPropertyCombo;
  
  scoreDateRange?: ScoreDateRange;
  scoreUpdated: boolean;
  configKey?: string;
}

export interface EventPropertyCombo {
  eventName: string;
  otherEventName: string;
  property: string;
  trialName: string;
}


export interface ScoreDateRange {
  endDate: string;
  startDate: string;
}
