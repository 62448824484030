import {  Container, Paper } from '@mui/material';
import { useEffect } from 'react'
import s from './UnityLaunchArea.module.scss'
import { connect } from 'react-redux';
import { ReduxAction } from '../../../../redux/rootReducer';
import { setHasUnityCampaign } from '../../../../redux/external-apis/unity/unityActions';
import UnityConfigArea from './UnityLaunchArea/UnityConfigArea';
import UnityLaunchAreaTitle from './UnityLaunchArea/UnityLaunchAreaTitle';


/*
    PURPOSE: Launches a unity campaign in the unity api

*/


type Props = {
    setHasUnityCampaign: (b: boolean) => ReduxAction,
}

const UnityLaunchArea = ({ setHasUnityCampaign } : Props) => {

    useEffect(() => {
        return () => {
            setHasUnityCampaign(false);
        }
    }, [])

    return (
        <Paper className={s.wrap}>
            <Container className={s.innerWrap}>
                <UnityLaunchAreaTitle/>
                <UnityConfigArea/>
            </Container>
        </Paper>
    );
};

const mapDispatch = {
    setHasUnityCampaign: (b: boolean) => setHasUnityCampaign(b),
}

export default connect(null, mapDispatch)(UnityLaunchArea);