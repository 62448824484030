import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MainSection from './components/MainSection';
import SidePanel from './components/SidePanel';
import { selectTheme } from './redux/admin/adminSelectors';
import { AdminState } from './redux/admin/interfaces';
import { RootState } from './redux/rootReducer';

type Props = {
    theme: AdminState["theme"],
}

/* This component is the part of the app that is accessible only if you are authed */

const AppBody = ({ theme } : Props) => {

    const myTheme = createTheme({
        palette: {
          mode: theme,
        },
    });

    return (
        <ThemeProvider theme={myTheme}>
            <CssBaseline/>
            <SidePanel/>
            <MainSection/>
        </ThemeProvider>
    );
};

const mapStateToProps = createStructuredSelector<RootState, Props>({
    theme: selectTheme
});
export default connect(mapStateToProps)(AppBody);