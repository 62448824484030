import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@mui/material';
import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { IPHONE_PLUS_WIDTH } from '../../helpers/constants';
import { deleteAbTestInDb } from '../../redux/abtest/abTestActions';
import { ProjectInterface } from '../../redux/projects/interfaces';
import { deleteProjectInDb } from '../../redux/projects/projectsActions';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import s from './DeleteDataButton.module.scss'

type Props = {
    dataType: "abTest" | "project",
    projectName: string,
    abTestId?: string,
    projects: ProjectInterface[],
    alertText: string,
    deleteProjectInDb: (projectName: string) => ReduxAction,
    deleteAbTestInDb: (projectName: string, abTestId: string) => ReduxAction
}


/* used for deleting an ab test or project */

const DeleteDataButton = ({
    dataType,
    projectName,
    abTestId,
    alertText,
    projects,
    deleteProjectInDb,
    deleteAbTestInDb
} : Props) => {



    const [loading, setLoading]       = useState<boolean>(false);
    const [open, setOpen]             = useState<boolean>(false);
    const [validation, setValidation] = useState<string>("");
    const typeMsg                     = dataType === "abTest" ? "A/B Test" : "project"



    useEffect(() => {
        return () => {
            // cleanup; we go to new url in saga
            setOpen(false);
            setLoading(false);
        }
    }, [projects])

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [alertText])

    

    function handleClick() {
        setLoading(true);
        if (dataType === "abTest") {
            deleteAbTestInDb(projectName, abTestId as string);
        } else {
            deleteProjectInDb(projectName);
        }
    }


    return (
        <div className={s.wrap}>
            <Button onClick={() => setOpen(true)} color="error" variant="outlined">Delete { typeMsg }</Button>
            <Dialog
                    sx={window.innerWidth <= IPHONE_PLUS_WIDTH ? { textAlign: 'center'} : {}}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                <DialogTitle>
                    Are you sure you want to delete this entire {typeMsg}?
                </DialogTitle>

                <DialogContent>
                    <div>
                        {
                            dataType === "abTest" ? (
                                "The A/B test and all its designs will also be deleted forever. This action cannot be undone."
                            ) : (
                                "The project, ab tests, and all designs associated with every A/B test will be deleted forever. This action cannot be undone."
                            )
                        }
                    </div>
                    <div style={{fontStyle: "italic", marginTop: '2rem', marginBottom: '1rem'}}>Type "permanently delete" to delete.</div>
                    <TextField value={validation} onChange={(e) => setValidation(e.target.value)}/>
                </DialogContent>
                
                <DialogActions sx={window.innerWidth <= IPHONE_PLUS_WIDTH ? { justifyContent: 'center'} : {}}>
                    <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    <LoadingButton variant="contained" disabled={validation !== "permanently delete"} loading={loading} onClick={handleClick}>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { projects } = rootState.projects;
    const { alertMsg } = rootState.admin;
    return { projects, alertText: alertMsg.msg };
}

const mapDispatch = {
    deleteProjectInDb: (projectName: string) => deleteProjectInDb(projectName),
    deleteAbTestInDb: (projectName: string, abTestId: string) => deleteAbTestInDb(projectName, abTestId)
}
export default connect(mapStateToProps, mapDispatch)(DeleteDataButton);