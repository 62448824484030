import { ACTIONS, AlertMsg } from "./interfaces";

export function setAuthed(value: boolean, username: string, sessionId: string, refreshToken: string) {
    return {
        type: ACTIONS.SET_AUTHED,
        payload: { value, username, sessionId, refreshToken }
    }
}


export function setAlert(alert: AlertMsg) {
    return {
        type: ACTIONS.SET_ALERT,
        payload: alert
    }
}


export function toggleTheme() {
    return {
        type: ACTIONS.TOGGLE_THEME,
    }
}


export function login(username: string, password: string) {
    return {
        type: ACTIONS.LOGIN,
        payload: { username, password }
    }
}

export function logout() {
    return {
        type: ACTIONS.LOGOUT,
    }
}



export function initAppState() {
    return {
        type: ACTIONS.INIT_APP_STATE,
    }
}






