import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
type Props = {
    handleClick: () => void
}

const TextFieldAdornment = ({ handleClick } : Props) => {
    return (
        <InputAdornment position="end">
            <Tooltip title="reset">
                <IconButton onClick={handleClick}>
                    <RestoreIcon/>
                </IconButton>
            </Tooltip>
        </InputAdornment>
    );
};

export default TextFieldAdornment;