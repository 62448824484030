import { Card } from '@mui/material';
import { Dispatch, SetStateAction } from 'react'
import s from './AddCard.module.scss'
import AddIcon from '@mui/icons-material/Add';
import { AdminState } from '../../redux/admin/interfaces';
import { createStructuredSelector } from 'reselect';
import { RootState } from '../../redux/rootReducer';
import { selectTheme } from '../../redux/admin/adminSelectors';
import { connect } from 'react-redux';

type Props = {
    setOpen: Dispatch<SetStateAction<boolean>>,
    theme: AdminState["theme"]
}

/*
    PURPOSE: the display-only component to add a design to a design group
    Used in DesignGroup.tsx and also in AddDesignGroupForm
*/
const AddCard = ({ theme, setOpen } : Props) => {
    return (
        <Card onClick={() => setOpen(true)} elevation={3} className={theme === "light" ? s.addCardLight : s.addCardDark}>
            <AddIcon sx={{ fontSize: "150px" }} />
            <h3>ADD DESIGN</h3>
        </Card>
    );
};

type SProps = Pick<Props, "theme">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    theme: selectTheme
});
export default connect(mapStateToProps)(AddCard);