import axios, { AxiosResponse } from "axios";
import { all, call, takeLatest, takeEvery, put } from "redux-saga/effects";
import { BASE_URL, ERR_SNACKBAR, SUCCESS_SNACKBAR, AXIOS_ERROR, UNSUCCESS } from "../../helpers/constants";
import { setAlert } from "../admin/adminActions";
import { ACTIONS, ProjectInterface } from "./interfaces";
import { removeLocalProject, setProjects, createUnityProjectInDb, deleteProjectInDb, updateProjectFieldsInDb, createFirebaseProjectInDb, createNonUnityProjectInDb } from "./projectsActions";
import { apiCheck, formatProjectGridRows } from '../../helpers/utils'
import { BROWSER_HISTORY } from "../..";






type CreateProjectResponse = {
    success: boolean
    error?: string
    projects?: ProjectInterface[]
}

// ------------------------------------------------------------------ Create Project ----------------------------------------------------------------------------------------//
type CreateUnityProjectInDbAction = ReturnType<typeof createUnityProjectInDb>;
function* handleCreateUnityProjectInDb(action: CreateUnityProjectInDbAction) {
    try {
        const project                     = action.payload;
        const url                         = BASE_URL + '/projects/unity';
        const res: AxiosResponse<CreateProjectResponse> = yield call(axios.post, url, { project });
        if (!res) throw new Error(AXIOS_ERROR);
        const { success, error, projects } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!projects) throw new Error("No projects return from response. Please contact developer");
        const gridRows = formatProjectGridRows(projects);
        yield put(setProjects(projects, gridRows));
        yield put(setAlert({
            msg: "Successfully added new project",
            config: SUCCESS_SNACKBAR
        }));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}
function* interceptCreateUnityProjectInDb() {
    yield takeLatest(
        [ACTIONS.CREATE_UNITY_PROJECT_IN_DB],
        handleCreateUnityProjectInDb
    )
}






type CreateFirebaseProjectInDbAction = ReturnType<typeof createFirebaseProjectInDb>;
function* handleCreateFirebaseProjectInDb(action: CreateFirebaseProjectInDbAction) {
    try {
        const project                     = action.payload;
        const url                         = BASE_URL + '/projects/firebase';
        const res: AxiosResponse<CreateProjectResponse> = yield call(axios.post, url, { project });
        if (!res) throw new Error(AXIOS_ERROR);
        const { success, error, projects } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!projects) throw new Error("No projects return from response. Please contact developer");
        const gridRows = formatProjectGridRows(projects);
        yield put(setProjects(projects, gridRows));
        yield put(setAlert({
            msg: "Successfully added new project",
            config: SUCCESS_SNACKBAR
        }));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}

function* interceptCreateFirebaseProjectInDb() {
    yield takeLatest(
        [ACTIONS.CREATE_FIREBASE_PROJECT_IN_DB],
        handleCreateFirebaseProjectInDb
    )
}









type CreateNonUnityProjectInDbAction = ReturnType<typeof createNonUnityProjectInDb>;
function* handleCreateNonUnityProjectInDb(action: CreateNonUnityProjectInDbAction) {
    try {
        const project                     = action.payload;
        const url                         = BASE_URL + '/projects/other';
        const res: AxiosResponse<CreateProjectResponse> = yield call(axios.post, url, { project });
        if (!res) throw new Error(AXIOS_ERROR);
        const { success, error, projects } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!projects) throw new Error("No projects return from response. Please contact developer");
        const gridRows = formatProjectGridRows(projects);
        yield put(setProjects(projects, gridRows));
        yield put(setAlert({
            msg: "Successfully added new project",
            config: SUCCESS_SNACKBAR
        }));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}

function* interceptCreateNonUnityProjectInDb() {
    yield takeLatest(
        [ACTIONS.CREATE_NON_UNITY_PROJECT_IN_DB],
        handleCreateNonUnityProjectInDb
    )
}







// ------------------------------------------------------------------ Delete Project in DB ----------------------------------------------------------------------------------------//
type DeleteProjectResponse = {
    success: boolean,
    error?: string
}
type DeleteProjectInDbAction = ReturnType<typeof deleteProjectInDb>;
function* handleDeleteProjectInDb(action: DeleteProjectInDbAction) {
    try {
        const { projectName } = action.payload;
        const url             = BASE_URL + '/projects';

        const res: AxiosResponse<DeleteProjectResponse> = yield call(
            axios.delete, 
            url,
            { data: { projectName }}
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { error, success } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        // delete project
        yield put(removeLocalProject(projectName));
        yield put(setAlert({
            msg: "Successfully deleted project",
            config: SUCCESS_SNACKBAR
        }));
        // change location after deletion
        BROWSER_HISTORY.push(`/home/projects`);
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}


function* interceptDeleteProjectInDb() {
    yield takeEvery(
        [ACTIONS.DELETE_PROJECT_IN_DB],
        handleDeleteProjectInDb
    )
}













// ------------------------------------------------------------------ Update Project Fields ----------------------------------------------------------------------------------------//
type ProjectUpdateResponse = {
    success: boolean,
    projects?: ProjectInterface[],
    error?: string
}
type UpdateProjectFieldsAction = ReturnType<typeof updateProjectFieldsInDb>;
function* handleUpdateProjectFieldsInDb(action: UpdateProjectFieldsAction) {
    try {
        
        const url          = BASE_URL + '/projects'
        const res: AxiosResponse<ProjectUpdateResponse> = yield call(
            axios.patch, 
            url,
            action.payload
        );

        if (!res) throw new Error(AXIOS_ERROR);
        const { error, success, projects } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!projects) throw new Error("No projects were returned. Please contact developer.");
        const gridRows = formatProjectGridRows(projects);
        yield put(setProjects(projects, gridRows));
        yield put(setAlert({
            msg: "Successfully updated project.",
            config: SUCCESS_SNACKBAR
        }));
        BROWSER_HISTORY.push('/home/projects');
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}


function* interceptUpdateProjectFieldsInDb() {
    yield takeEvery(
        [ACTIONS.UPDATE_PROJECT_FIELDS_IN_DB],
        handleUpdateProjectFieldsInDb
    )
}




// used as a test hit to make sure the user is authenticated or not
export function* handleCheckAuth() {
    // check auth on backend
    const res: AxiosResponse<any> = yield call(apiCheck);
    return
}












export function* projectsSagas() {
    yield all([
        call(interceptCreateUnityProjectInDb),
        call(interceptDeleteProjectInDb),
        call(interceptUpdateProjectFieldsInDb),
        //call(interceptCreateFirebaseProjectInDb),
        call(interceptCreateNonUnityProjectInDb)
    ])
}