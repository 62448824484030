import { Container } from '@mui/material';
import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { UnityAbTest } from '../../../../../redux/abtest/interfaces';
import { UnityApiState } from '../../../../../redux/external-apis/unity/interfaces';
import { launchUnityCampaign, setUnityConfigKeys } from '../../../../../redux/external-apis/unity/unityActions';
import { ReduxAction, RootState } from '../../../../../redux/rootReducer';
import s from './UnityConfigArea.module.scss'
import { LoadingButton } from '@mui/lab';
import { AlertMsg } from '../../../../../redux/admin/interfaces';
import { setAlert } from '../../../../../redux/admin/adminActions';
import { ERR_SNACKBAR, WARN_SNACKBAR } from '../../../../../helpers/constants';
import { containsDuplicateStr, groupsSameLength } from '../../../../../helpers/utils';
import { AbTestContext } from '../../../AbTestPage';
import UnityConfigKeysDropdowns from './UnityConfigArea/UnityConfigKeyDropdowns';

type Props = {
    setUnityConfigKeys: (keys: string[]) => ReduxAction,
    launchUnityCampaign: (abTest: UnityAbTest, configGroup: ConfigGroup) => ReduxAction
    setAlert: (alert: AlertMsg) => ReduxAction
    hasCampaignInApi: boolean,
    checkCampaignLoading: UnityApiState["checkCampaignLoading"] // used only on page load
    currConfigKeys: UnityApiState["currConfigKeys"]
    alertMsg: AlertMsg
}

export type ConfigGroup = {
    [key: string]: string
}
const UnityConfigArea = ({
    checkCampaignLoading,
    hasCampaignInApi,
    currConfigKeys,
    alertMsg,
    setUnityConfigKeys,
    launchUnityCampaign,
    setAlert
}: Props) => {

    const [loading, setLoading] = useState(false);
    const [launchCampaignLoading, setLaunchCampaignLoading] = useState(false);
    const abTest = useContext(AbTestContext) as UnityAbTest;
    const { campaignConfig } = abTest.abTestApiInfo.unitySettings;
    const hasCampaignData = campaignConfig && campaignConfig.campaignId ? true : false;
    const INIT_GROUP_CONFIG_KEYS: ConfigGroup = {};
    for (const { name, configKey } of abTest.designGroups) {
        INIT_GROUP_CONFIG_KEYS[name] = configKey ? configKey : ""
    }
    const [groupConfigKeys, setGroupConfigKeys] = useState<ConfigGroup>(INIT_GROUP_CONFIG_KEYS)
    const [configKeysFull, setConfigKeysFull] = useState(true);


    useEffect(() => {
        setLoading(true);
        return () => {
            setUnityConfigKeys([]);
            setLaunchCampaignLoading(false);
        }
    }, [])


    // when we receive currconfigkeys, reset loading
    useEffect(() => {
        setLoading(false);
    }, [currConfigKeys]);


    // when we get a failed or success notification, reset notification
    useEffect(() => {
        setLaunchCampaignLoading(false);
    }, [alertMsg.msg]);


    // since connect() makes our component pure, we must update manually nested props that change
    useEffect(() => {
        const newInitState: ConfigGroup = {};
        for (const { name } of abTest.designGroups) {
            if (name in groupConfigKeys) {
                newInitState[name] = groupConfigKeys[name];
            } else {
                newInitState[name] = "";
            }
        }
        setGroupConfigKeys(newInitState)
    }, [abTest.designGroups.length])


    useEffect(() => {
        let isFull = true;
        for (const key of Object.keys(groupConfigKeys)) {
            if (groupConfigKeys[key] === "") {
                isFull = false;
            }
        }
        setConfigKeysFull(isFull);
    }, [groupConfigKeys])


    function handleConfigKeySelection(name: string, value: string | null) {
        setGroupConfigKeys(prev => ({
            ...prev,
            [name]: value === null ? "" : value
        }))
    }

    function handleLaunch() {
        const configKeys = Object.values(groupConfigKeys);
        if (configKeys.includes("")) {
            return setAlert({ msg: "All config keys must be filled out.", config: WARN_SNACKBAR })
        } else if (containsDuplicateStr(configKeys)) {
            return setAlert({ msg: "No duplicate keys allowed.", config: ERR_SNACKBAR })
        } else if (!groupsSameLength(abTest.designGroups)) {
            return setAlert({ msg: "All design groups must be the same length.", config: ERR_SNACKBAR });
        }
        setLaunchCampaignLoading(true);
        launchUnityCampaign(abTest, groupConfigKeys);
    }

    return (
        <Container className={s.mainArea}>
            <div className={s.sectionWrap}>
                <UnityConfigKeysDropdowns
                    abTest={abTest}
                    hasCampaignData={hasCampaignData}
                    groupConfigKeys={groupConfigKeys}
                    hasCampaignInApi={hasCampaignInApi}
                    currConfigKeys={currConfigKeys}
                    loading={loading}
                    handleConfigKeySelection={handleConfigKeySelection}
                />

                <section>
                    <div className={s.btnsWrap}>
                        <LoadingButton
                            variant="contained"
                            onClick={handleLaunch}
                            disabled={abTest.designGroups.length === 0 || !configKeysFull || hasCampaignInApi}
                            loading={launchCampaignLoading || checkCampaignLoading}
                        >
                            Create Test
                        </LoadingButton>
                    </div>
                </section>
            </div>
        </Container>
    );
};


const mapDispatch = {
    setUnityConfigKeys: (keys: string[]) => setUnityConfigKeys(keys),
    launchUnityCampaign: (abTest: UnityAbTest, configGroup: ConfigGroup) => launchUnityCampaign(abTest, configGroup),
    setAlert: (alert: AlertMsg) => setAlert(alert)
}

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    const { currConfigKeys, checkCampaignLoading, hasCampaignInApi } = rootState.externalApis.unityApi;
    return { currConfigKeys, checkCampaignLoading, hasCampaignInApi, alertMsg }
}
export default connect(mapStateToProps, mapDispatch)(UnityConfigArea);