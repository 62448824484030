import { AxiosResponse } from "axios";
import { all, call, put, takeLatest, select} from "redux-saga/effects";
import { AXIOS_ERROR, BASE_URL, ERR_SNACKBAR, UNSUCCESS } from "../../helpers/constants";
import { ACTIONS } from './interfaces';
import axios from 'axios';
import { login, setAlert, setAuthed } from "./adminActions";
import { BROWSER_HISTORY } from "../..";
import { selectAuthHeaders, selectUsername } from './adminSelectors'
import { ProjectInterface } from "../projects/interfaces";
import { handleCheckAuth } from "../projects/projectsSagas";
import { formatProjectGridRows } from "../../helpers/utils";
import { setProjects } from "../projects/projectsActions";
import { setAllEventNamesOurApi } from "../external-apis/our-api/ourApiActions";




interface InitData { 
    success: boolean, 
    projects?: ProjectInterface[], 
    eventNames?: string[],
    error?: string
}

// ProjectInterface[] is equal to PopulatedProject[] in DB, since it requires population and stripping of mongo fields
// ------------------------------------------------------------------ Init State ----------------------------------------------------------------------------------------//
function* handleInitAppState() {
    try {
        yield call(handleCheckAuth);
        const url = BASE_URL + '/admin/init'
        const { sessionId, refreshToken, username } = yield select(selectAuthHeaders);
    
        // this handles the case when a user tries to visit a route without a refreshtoken
        if (!sessionId || !refreshToken || !username) {
            if (!BROWSER_HISTORY.location.pathname.includes('login')) {
                yield console.log("redirecting to login from init state")
            }
        }
        const res: AxiosResponse<InitData> = yield call(axios.get, url);
        if (!res) throw new Error(AXIOS_ERROR);
        const { success, error, projects, eventNames } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        
        // only throw errors if they are undefined, we may want an empty array
        if (projects === undefined) throw new Error("Projects was undefined. Please contact developer.");
        if (eventNames === undefined) throw new Error("event names were undefined. Please contact developer.");
        
        const gridRows   = formatProjectGridRows(projects);
        yield put(setProjects(projects, gridRows));
        yield put(setAllEventNamesOurApi(eventNames));
    } catch (e: any) {
        console.log(e);
        if (!e.response) {
            yield put(setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            }));
        } else {
            yield put(setAlert({
                msg: JSON.stringify(e.response.data),
                config: ERR_SNACKBAR
            }))
        }
    }
}
function* interceptInitAppState() {
    yield takeLatest(
        [ACTIONS.INIT_APP_STATE],
        handleInitAppState
    )
}










interface LoginRouteResponse {
    msg?: string,
    success: boolean,
    error?: string
    user?: string
}
type LoginAction = ReturnType<typeof login>
function* handleLogin(action: LoginAction) {
    try {
        const route = BASE_URL + '/user/login';
        const res: AxiosResponse<LoginRouteResponse> = yield call(
            axios.post,
            route,
            action.payload
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { msg, error, success, user } = res.data;
        if (error) throw new Error(error)
        if (!success) throw new Error(UNSUCCESS);
        if (!msg || !user) throw new Error("no message or no user returned. Contact developer.");
        yield BROWSER_HISTORY.push('/home/projects');
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }))
    }
}
function* interceptLogin() {
    yield takeLatest(
        [ACTIONS.LOGIN],
        handleLogin
    )
}








interface LogoutResponse {
    success: boolean,
    error?: string
}

function* handleLogout() {
    try {
        const route = BASE_URL + '/user/logout';
        const username: string = yield select(selectUsername);
        const res: AxiosResponse<LogoutResponse> = yield call(
            axios.post,
            route,
            { username }
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { error, success } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        yield put(setAuthed(false, "", "", ""));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }))
    }
}
function* interceptLogout() {
    yield takeLatest(
        [ACTIONS.LOGOUT],
        handleLogout
    )
}










export function* adminSagas() {
    yield all([
        call(interceptLogin),
        call(interceptLogout),
        call(interceptInitAppState)
    ])
}