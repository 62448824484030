import { GridColDef, GridFilterOperator } from "@mui/x-data-grid";
import { OptionsObject } from "notistack";

export const projectsGridCols: GridColDef[] = [
    {
        field: 'name',
        headerName: "Project Name",
    },
    // {
    //     field: 'creator',
    //     headerName: "Creator",
    // },
    {
        field: "createdAt",
        headerName: "Creation Time",
    },
    {
        field: "abTestsNum",
        headerName: "# of AB Tests",
    }
];
//const GridFilter: GridFilterOperator

export const abTestsGridCols: GridColDef[] = [
    {
        field: 'name',
        headerName: "Name",
    },
    // {
    //     field: 'creator',
    //     headerName: "Creator",
    // },
    
    {
        field: 'createdAt',
        headerName: "Creation Time",
    },
    {
        field: 'designsNum',
        headerName: "# of Designs",
    },
    // {
    //     field: 'project',
    //     headerName: "project",
    // },
    {
        field: 'startDate',
        headerName: "Start Date",
    },
    {
        field: 'endDate',
        headerName: "End Date",
    },
    {
        field: 'comments',
        headerName: "Comments",
    },
];


export const SUCCESS_SNACKBAR: OptionsObject = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'success',
    autoHideDuration: 5000
}

export const ERR_SNACKBAR: OptionsObject = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'error',
    autoHideDuration: null
}


export const WARN_SNACKBAR: OptionsObject = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'warning',
    autoHideDuration: null
}

export const BASE_URL            = '/api/v1';
export const BIG_LAPTOP_WIDTH    = 1680;
export const NORMAL_LAPTOP_WIDTH = 1280;
export const SMALL_LAPTOP_WIDTH  = 1024;
export const TABLET_WIDTH        = 768;
export const IPHONE_PLUS_WIDTH   = 414;
export const IPHONE_X_WIDTH      = 375;
export const IPHONE_PRO_MAX      = 428;
export const NGINX_TIMEOUT       = 300000; // i think its set in /etc/nginx/sites-available/default file?
export const PROJECT_APIS        = ["UNITY", "FIREBASE", "OTHER"] as const;


export const darkModeCardBg      = {background: "#484848 !important"};
export const darkModeCardContent = {background: "#0000009e !important"};
export const AXIOS_ERROR = "No response returned from Axios";
export const UNSUCCESS   = "Unsuccessful operation. Please contact developer."