import { ProjectUpdateFields } from "../../components/pages/ProjectPage/UpdateProjectForm";
import { AbTestInterface, UnityAbTest } from "../abtest/interfaces";
import { ProjectInterface, ACTIONS, ProjectGridRow, UnityProject, FirebaseProject } from "./interfaces";


/* Project CRUD actions */
export function createUnityProjectInDb(p: UnityProject) {
    return {
        type: ACTIONS.CREATE_UNITY_PROJECT_IN_DB,
        payload: p
    }
}
export function createFirebaseProjectInDb(p: FirebaseProject) {
    return {
        type: ACTIONS.CREATE_FIREBASE_PROJECT_IN_DB,
        payload: p
    }
}
export function createNonUnityProjectInDb(p: ProjectInterface) {
    return {
        type: ACTIONS.CREATE_NON_UNITY_PROJECT_IN_DB,
        payload: p
    }
}
export function deleteProjectInDb(projectName: string) {
    return {
        type: ACTIONS.DELETE_PROJECT_IN_DB,
        payload: { projectName }
    }
}
export function updateProjectFieldsInDb(fields: Partial<ProjectUpdateFields>, projectName: string) {
    return {
        type: ACTIONS.UPDATE_PROJECT_FIELDS_IN_DB,
        payload: { fields, projectName }
    }
}








// primarily used in sagas
export function setProjects(projects: ProjectInterface[], gridRows: ProjectGridRow[]) {
    return {
        type: ACTIONS.SET_PROJECTS,
        payload: { projects, gridRows }
    }
}
export function replaceAbTest(abTest: AbTestInterface | UnityAbTest) {
    return {
        type: ACTIONS.REPLACE_AB_TEST,
        payload: abTest
    }
}
export function replaceProject(project: ProjectInterface) {
    return {
        type: ACTIONS.REPLACE_PROJECT,
        payload: project
    }
}

export function removeLocalProject(projectName: string) {
    return {
        type: ACTIONS.REMOVE_LOCAL_PROJECT,
        payload: projectName
    }
}