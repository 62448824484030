import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ERR_SNACKBAR, WARN_SNACKBAR } from '../../../helpers/constants';
import { setAlert } from '../../../redux/admin/adminActions';
import { AlertMsg } from '../../../redux/admin/interfaces';
import { ProjectInterface } from '../../../redux/projects/interfaces';
import { updateProjectFieldsInDb } from '../../../redux/projects/projectsActions';
import { ReduxAction } from '../../../redux/rootReducer';
import TextFieldAdornment from '../AbTestPage/DesignsArea/DesignGroup/DesignCardFinal/DesignFormFinal/TextFieldAdornment';
import s from './UpdateProjectForm.module.scss'

type Props = {
    project: ProjectInterface,
    projectNames: string[],
    setAlert: (alert: AlertMsg) => ReduxAction,
    updateProjectFieldsInDb: (fields: Partial<ProjectUpdateFields>, projectName: string) => ReduxAction
    setOpen: Dispatch<SetStateAction<boolean>>,
}

export type ProjectUpdateFields = {
    name: string,
    creator: string
};


const UpdateProjectForm = ({ 
    project, 
    projectNames,
    setAlert, 
    updateProjectFieldsInDb,
    setOpen,
} : Props) => {

    const INIT_STATE = {
        name: project.name,
        creator: project.creator
    }

    const [fields, setFields] = useState<ProjectUpdateFields>(INIT_STATE);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        return () => {
            setOpen(false);
            setFields(INIT_STATE);
            setLoading(false);
        }
    }, [project])



    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setFields((oldVal) => {
            return {
                ...oldVal,
                [e.target.name]: e.target.value
            }
        })
    }

    function resetField(field: keyof ProjectUpdateFields) {
        setFields(prev => ({...prev, [field]: INIT_STATE[field]}))
    }

    function handleClick() {
        let hasChange = false;
        Object.keys(fields).forEach(field => {
            if (fields[field as keyof ProjectUpdateFields] !== INIT_STATE[field as keyof ProjectUpdateFields]) {
                hasChange = true;
            }
        })
        
        if (!hasChange) {
            setAlert({
                msg: "No changes detected.",
                config: WARN_SNACKBAR
            });
        } else {
            const update: Partial<ProjectUpdateFields> = {};
            for (let key of Object.keys(fields)) {
                if (fields[key as keyof ProjectUpdateFields] !== INIT_STATE[key as keyof ProjectUpdateFields]) {

                    if (key === "name") {
                        if (!fields[key]) {
                            return setAlert({
                                msg: "Project name cannot be empty.",
                                config: WARN_SNACKBAR
                            })
                        }
                        if (projectNames.includes(fields[key])) {
                            return setAlert({
                                msg: "That name is already taken, please choose another.",
                                config: ERR_SNACKBAR
                            });
                        }
                    }

                    // if creator is invalid, backend will send an error
                    update[key as keyof ProjectUpdateFields] = fields[key as keyof ProjectUpdateFields];
                }
            }
            setLoading(true)
            updateProjectFieldsInDb(update, project.name);
        }
    }


    return (
        <div className={s.wrap}>
            <h2>Project Fields</h2>
            <section>
                <TextField
                    name="name"
                    onChange={handleInputChange}
                    label="Project Name"
                    value={fields.name}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("name")}/>
                    }}
                    fullWidth
                    required
                />
            </section>
            <section>
                <TextField
                    name="creator"
                    onChange={handleInputChange}
                    label="Project Creator"
                    value={fields.creator}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("creator")}/>
                    }}                    
                    fullWidth
                />
            </section>

            <section>
                <LoadingButton size="large" loading={loading} onClick={handleClick} variant="contained" fullWidth>
                    UPDATE
                </LoadingButton>
            </section>
        </div>
    );
};

const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    updateProjectFieldsInDb: (fields: Partial<ProjectUpdateFields>, projectName: string) => updateProjectFieldsInDb(fields, projectName),
}

export default connect(null, mapDispatch)(UpdateProjectForm);