import { AbTestInterface } from "../abtest/interfaces";


// ------------------------------------------------ {{  REDUX INTERFACES  }} ------------------------------------------------
export const ACTIONS = {
    CREATE_UNITY_PROJECT_IN_DB: "CREATE_UNITY_PROJECT_IN_DB",
    CREATE_FIREBASE_PROJECT_IN_DB: "CREATE_FIREBASE_PROJECT_IN_DB",
    DELETE_PROJECT_IN_DB: "DELETE_PROJECT_IN_DB",
    UPDATE_PROJECT_FIELDS_IN_DB: "UPDATE_PROJECT_FIELDS_IN_DB",
    SET_PROJECTS: "SET_PROJECTS",
    REPLACE_AB_TEST: "REPLACE_AB_TEST",
    REPLACE_PROJECT: "REPLACE_PROJECT",
    REMOVE_LOCAL_PROJECT: "REMOVE_LOCAL_PROJECT",
    CREATE_NON_UNITY_PROJECT_IN_DB: "CREATE_NON_UNITY_PROJECT_IN_DB"
}



export const PROJECTS_INITIAL_STATE: ProjectsState = {
    projects: [],
    gridRows: [],
}




export interface ProjectsState {
    projects: ProjectInterface[],
    gridRows: ProjectGridRow[],
}







// ------------------------------------------------ {{ MAIN INTERFACES  }} ------------------------------------------------
export interface ProjectInterface {
    name: string,
    abTests: AbTestInterface[],
    creator: string,
    apiInfo: ApiInfo,
    createdAt: string,
    updatedAt: string
}

export interface ApiInfo {
    api: "UNITY" | "FIREBASE" | "OTHER",
    unitySettings?: UnitySettings
    firebaseSettings?: FirebaseSettings
};








// ------------------------------------------------ {{ API SPECIFIC INTERFACES }} ------------------------------------------------


/* -----------    unity    -------------*/
export interface UnityProject extends Omit<ProjectInterface, "apiInfo"> {
    apiInfo: UnityApiInfo
}

export interface UnityApiInfo {
    api: "UNITY",
    unitySettings: UnitySettings
}

export interface UnitySettings {
    projectId: string,
    environmentId: string,
};



/* -----------    firebase    -------------*/

export interface FirebaseProject extends Omit<ProjectInterface, "apiInfo"> {
    apiInfo: FirebaseApiInfo
}

export interface FirebaseApiInfo {
    api: "FIREBASE",
    firebaseSettings: FirebaseSettings
}

export interface FirebaseSettings {
    projectId: string,
};






// ------------------------------------------------ {{ use for components }} ------------------------------------------------
export type ProjectUniqueFields = Pick<ProjectInterface, "name" | "apiInfo">


export interface ProjectGridRow {
    id: number,
    abTestsNum: number,
    creator: string,
    createdAt: string, // in en-GB localeDateString format
    updatedAt: string,
    name: string
}
