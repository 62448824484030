import { Button, Card, CardActions, CardContent, CardMedia, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { darkModeCardBg, darkModeCardContent } from "../../../../../helpers/constants";
import { deleteInitialDesign } from "../../../../../redux/abtest/abtest-init/abtestInitActions";
import { InitialDesign } from "../../../../../redux/abtest/design-group/designs/interfaces";
import { AbTestInterface } from "../../../../../redux/abtest/interfaces";
import { selectTheme } from "../../../../../redux/admin/adminSelectors";
import { AdminState } from "../../../../../redux/admin/interfaces";
import { ReduxAction, RootState } from "../../../../../redux/rootReducer";
import CustomModal from "../../../../reusable/CustomModal";
import { AbTestContext } from "../../../AbTestPage";
import s from "./DesignCard.module.scss";
import DesignForm from "./DesignForm";

type Props = InitialDesign & {
    idx: number,
    deleteInitialDesign: (idx: number) => ReduxAction,
    disableButtons?: boolean,
    theme: AdminState["theme"]
}
const DesignCard = ({
    deleteInitialDesign,
    image,
    name,
    comments,
    creator,
    theme,
    designText,
    idx,
    type,
    disableButtons
}: Props) => {
    const [open, setOpen] = useState(false);
    const abTest = useContext(AbTestContext) as AbTestInterface;
    const DesignText = () => (
        designText && designText.length > 140 ? (
            <Tooltip title={designText}>
                <div className={s.designTextWrap}>
                    <div>
                        {designText}
                    </div>
                </div>
            </Tooltip>
        ) : (
            <div className={s.designTextWrap}>
                <div>
                    {designText}
                </div>
            </div>
        )
    );

    return (
        <Card className={s.wrap} key={idx} sx={theme === "dark" ? darkModeCardBg : {}}>
            {
                designText ? (
                    <CardMedia component={DesignText} />
                ) : (
                    <CardMedia
                        component="img"
                        alt="design img"
                        image={image?.dataURL}
                        className={s.image}
                    />
                )
            }
            <div className={s.btmWrap}>
                <CardContent className={s.content} sx={theme === "dark" ? darkModeCardContent : {}}>
                    <div className={s.nameWrap}>
                        <Typography gutterBottom variant="body2" component="div">
                            {name}
                        </Typography>
                    </div>
                    <div className={s.commentsWrap}>
                        <Tooltip title={comments}>
                            <Typography variant="body2" color="text.secondary">
                                Comments: {comments}
                            </Typography>
                        </Tooltip>

                    </div>
                    <div className={s.btm}>
                        <Typography variant="body2" color="text.secondary">
                            Created By: {creator}
                        </Typography>
                    </div>
                </CardContent>

                <CardActions className={theme === "dark" ? s.btnsDark : s.btns}>
                    <Button size="small" disabled={disableButtons} onClick={() => setOpen(true)}>Edit</Button>
                    <Button size="small" disabled={disableButtons} onClick={() => deleteInitialDesign(idx)}>Delete</Button>
                </CardActions>
            </div>

            <CustomModal open={open} setOpen={setOpen}>
                <DesignForm abTest={abTest} idx={idx} setOpen={setOpen} type={type} />
            </CustomModal>
        </Card>
    );
};

const mapDispatch = {
    deleteInitialDesign: (idx: number) => deleteInitialDesign(idx)
}

type SProps = Pick<Props, "theme">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    theme: selectTheme
});

export default connect(mapStateToProps, mapDispatch)(DesignCard);
