import { Redirect, Route, Switch } from 'react-router';
import s from './MainSection.module.scss'
import ProjectPage from './pages/ProjectPage';
import ProjectsPage from './pages/ProjectsPage';
import AbTestPage from './pages/AbTestPage';
import ProjectDataPage from './pages/ProjectDataPage';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { CircularProgress } from '@mui/material';



const MainSection = () => {

    const Welcome  = () => <div style={{ fontWeight: 'bold', fontSize: '40px', paddingTop: '1rem'}}>Welcome!</div>;
    const location = useLocation();
    const projectsLength = useSelector((rootState: RootState) => rootState.projects.projects.length);
    return (
        <div className={s.wrap}>
            {
                location.pathname === "/" && <Redirect to="/home/projects"/>
            }
            <Switch>
                <Route exact path="/home" component={Welcome}/>
                
                 {
                    // projectsLength > 0 ? (
                        <> 
                            <Route path="/home/specialdata" component={ProjectDataPage}/>
                            <Route exact path="/home/projects/:name" component={ProjectPage}/>
                            <Route exact path="/home/projects/:name/abtests/:test" component={AbTestPage}/>
                            <Route exact path="/home/projects" component={ProjectsPage}/>
                        </>
                //     ) : (
                //         <CircularProgress style={{marginTop: '2rem'}}/>
                //     )
                 }
            </Switch>
        </div>
    );
};

export default MainSection;