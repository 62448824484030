import { CircularProgress } from '@mui/material';
import to from 'await-to-js';
import { useEffect, useState } from 'react';

type Props = {
    url : string
}


const InlineSvg = ({ url }: Props) => {
    const [svg, setSvg]             = useState("");
    const [isLoaded, setIsLoaded]   = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    useEffect(() => {
        async function loadData() {
            const [e1, res] = await to(fetch(url));
            if (e1) {
                console.log(e1);
                console.error(e1)
                setIsErrored(true);
            } else if (res) {
                const text = await res.text();
                setIsLoaded(true);
                setSvg(text);
            }
        }
        if (url) {
            loadData();
        }
        // fetch(url)
        //     .then(res => res.text())
        //     .then(text => setSvg(text))
        //     .catch(setIsErrored)
        //     .then(() => setIsLoaded(true))
        
    }, [url]);

    return (
        <>
        {
            isErrored ? (<div>Error</div>) : !isLoaded ? (
                <CircularProgress/>
            ) : (
                <div dangerouslySetInnerHTML={{__html: svg}}/>
            )
        }
        </>
    );
}
export default InlineSvg;