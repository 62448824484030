import { connect } from 'react-redux';
import { getGridCols } from '../../helpers/utils';
import { ProjectsState } from '../../redux/projects/interfaces';
import { RootState } from '../../redux/rootReducer';
import AppNavigation from '../reusable/AppNavigation';
import DataTable from '../reusable/DataTable';
import PageTitle from '../reusable/PageTitle';
import s from './ProjectsPage.module.scss'
import CustomGridFooter from './ProjectsPage/ProjectsDataGrid/CustomGridFooter';

type Props = {
    gridRows: ProjectsState["gridRows"]

}

const ProjectsPage = ({ gridRows } : Props) => {
    return (
        <div className={s.wrap}>
            <AppNavigation/>
            <PageTitle text="Projects"/>
            <DataTable 
                rows={gridRows} 
                cols={getGridCols("project", window.innerWidth)} 
                components={{
                    Footer: CustomGridFooter
                }}
                purpose="project"
            />
        </div>
    );
};
const mapStateToProps = (rootState: RootState) => {
    const gridRows = rootState.projects.gridRows;
    return { gridRows }
}

export default connect(mapStateToProps)(ProjectsPage);