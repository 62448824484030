import { DesignGroupScoreUpdate } from "../../../components/pages/AbTestPage/EventPropertyArea/GetDesignData"
import { DesignGroupEdit } from "../../../components/pages/AbTestPage/DesignsArea/DesignGroup/DesignGroupButtons/DesignGroupEditForm"
import { AbTestInterface } from "../interfaces"
import { DESIGN_GROUP_ACTIONS } from "./interfaces"


/* Design group actions */
export function createDesignGroupInDb(name: string, abTestId: AbTestInterface["_id"]) {
    return {
        type: DESIGN_GROUP_ACTIONS.CREATE_DESIGN_GROUP_IN_DB,
        payload: { name, abTestId }
    }
}
export function deleteDesignGroupInDb(abTestId: string, groupName: string) {
    return {
        type: DESIGN_GROUP_ACTIONS.DELETE_DESIGN_GROUP_IN_DB,
        payload: { abTestId, groupName }
    }
}
export function editDesignGroupInDb(abTestId: string, groupName: string, designGroupEdit: DesignGroupEdit) {
    return {
        type: DESIGN_GROUP_ACTIONS.EDIT_DESIGN_GROUP_IN_DB,
        payload: { abTestId, groupName, designGroupEdit }
    }
}
export function getDesignGroupScores(params: DesignGroupScoreUpdate) {
    return {
        type: DESIGN_GROUP_ACTIONS.GET_DESIGN_GROUP_SCORES,
        payload: params
    }
}