import { useSelector } from "react-redux";
import { RootState } from "../../rootReducer";

export function useSelectDesignGroupDynamicData(groupName: string) {

    function selectData(state: RootState) {
        const { designGroupDynamicData } = state.externalApis.ourApi;
        for (const dataPiece of designGroupDynamicData) {
            if (dataPiece.name === groupName) {
                return dataPiece
            }
        }
        return null
    }
    return useSelector(selectData);
} 



