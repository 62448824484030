import { Button, TextField } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { createAbTestInDb } from '../../../redux/abtest/abTestActions';
import { ReduxAction, RootState } from '../../../redux/rootReducer';
import AddIcon from '@mui/icons-material/Add';
import s from './AbTestForm.module.scss'
import BackdropLoader from '../../reusable/BackdropLoader';
import { ProjectContextTwo } from '../ProjectPage';
import { createStructuredSelector } from 'reselect'
import { selectAlertMsgTxt } from '../../../redux/admin/adminSelectors';
type Props = {
    createAbTestInDb: (n: string, c: string, p: string) => ReduxAction,
    alertText: string
}

/* Form to create an A/B Test in the database */
const AbTestForm = ({ createAbTestInDb, alertText }: Props) => {

    const [name, setName] = useState("");
    const [comments, setComments] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const project = useContext(ProjectContextTwo);
    const abTestNames = project!.abTests.map(test => test.basicFields.name);

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        setLoading(false);
    }, [alertText])


    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        if (name === "name") {
            if (abTestNames.map(abTestName=>abTestName.toLowerCase())
                .includes(value.toLowerCase())) {
                setError("Ab test name is already taken")
            } else if (error) {
                setError("");
            }
            setName(value)
        } else {
            setComments(value);
        }
    }

    function handleClick() {
        if (!error && name) {
            setLoading(true);
            createAbTestInDb(name, comments, project!.name)
        } else if (!name) {
            setError("name field is required")
        }
    }

    return (
        <div className={s.wrap}>
            <h2>Project Fields</h2>
            <section>
                <TextField
                    name="name"
                    onChange={handleChange}
                    label="Ab Test Name"
                    value={name}
                    error={error.length > 0}
                    helperText={error}
                    fullWidth
                    required
                />
            </section>
            <section>
                <TextField
                    name="comments"
                    onChange={handleChange}
                    label="Comments"
                    value={comments}
                    fullWidth
                />
            </section>
            <section>
                <Button onClick={handleClick} variant="contained" disabled={!name || error?.length > 0} fullWidth>
                    <AddIcon sx={{ fontSize: '50px' }} />
                </Button>
            </section>
            <BackdropLoader backgroundColor="#cdcdcd8f" circleColor="black" loading={loading} />
        </div>
    );
};
const mapDispatch = {
    createAbTestInDb: (n: string, c: string, p: string) => createAbTestInDb(n, c, p)
}

type SProps = Pick<Props, "alertText">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    alertText: selectAlertMsgTxt
})
export default connect(mapStateToProps, mapDispatch)(AbTestForm);