import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle, Paper } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BASE_URL, ERR_SNACKBAR, IPHONE_PLUS_WIDTH, SUCCESS_SNACKBAR } from '../../../../helpers/constants';
import { setAlert } from '../../../../redux/admin/adminActions';
import { AlertMsg } from '../../../../redux/admin/interfaces';
import { ReduxAction } from '../../../../redux/rootReducer';
import { ParameterValueCountInterface } from '../DisplayValueCountsArea';
import s from './ValueCountsGraph.module.scss';


type Props = {
    valueCounts: ParameterValueCountInterface["parameterValues"]
    setOpen?: Dispatch<SetStateAction<boolean>>
    setAlert: (alertMsg: AlertMsg) => ReduxAction
    displayOnly?: boolean
    updatePvCounts?: (pvCount: ParameterValueCountInterface) => void
    deletePvCount?: (pvCount: ParameterValueCountInterface) => void
    pvCount?: ParameterValueCountInterface
}
type UpdateSavedProjectDataRes = {
    success: boolean
    error?: string
    updatedRecord?: ParameterValueCountInterface
};

/*
    PURPOSE OF COMPONENT
        displays results of a get value counts query
        optionally has a display only property
        if !displayOnly then we can update or delete a saved db record (from the parent component passing in a function)
*/
const ValueCountsGraph = ({
    setOpen,
    setAlert,
    updatePvCounts,
    deletePvCount,
    displayOnly,
    valueCounts,
    pvCount
}: Props) => {

    const parameters = Object.keys(valueCounts);
    let bestCount = -Infinity;
    const data = parameters.length > 0 ? parameters.map(p => {
        let count = valueCounts[p];
        if (count > bestCount) bestCount = count;
        return { name: p, count };
    }) : [];

    const [updateLoading, setUpdateLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const [delDialogOpen, setDelDialogOpen] = useState(false);

    useEffect(() => {
        return () => {
            setDelLoading(false);
            setUpdateLoading(false);
            setDelDialogOpen(false);
        }
    }, [])

    async function update() {
        setUpdateLoading(true);
        try {
            const url = BASE_URL + '/analytics/techlabfze/projectdata/saved';
            const response = await axios.patch<any, AxiosResponse<UpdateSavedProjectDataRes>>(url, { currentRecord: pvCount! });
            if (!response) throw new Error('no response returned from update query');
            const { error, success, updatedRecord } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error("unsuccessful update.");
            if (!updatedRecord) throw new Error("No updated record returned from update query");
            updatePvCounts!(updatedRecord);
            setAlert({
                msg: "successfully updated record.",
                config: SUCCESS_SNACKBAR
            });
        } catch (e: any) {
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
        setUpdateLoading(false);
    }


    async function deleteRecord() {
        setDelLoading(true);
        try {
            const url = BASE_URL + '/analytics/techlabfze/projectdata/saved';
            const response = await axios.delete<any, AxiosResponse<UpdateSavedProjectDataRes>>(url, { data: { currentRecord: pvCount! } });
            if (!response) throw new Error('no response returned from update query');
            const { error, success } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error("unsuccessful delete.");
            deletePvCount!(pvCount!);
            setAlert({
                msg: "successfully deleted record.",
                config: SUCCESS_SNACKBAR
            })
        } catch (e: any) {
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
    }

    return (
        <Paper className={s.wrap}>
            <div className={s.titleWrap}>
                <div className={s.title}>
                    <h2>Score Results</h2>
                    <div>
                        {
                            data.length > 0 && !displayOnly ? (
                                <LoadingButton onClick={() => setOpen!(true)} variant="contained">Save Query</LoadingButton>
                            ) : (null)
                        }
                        {
                            displayOnly && (
                                <div className={s.dbBtnsWrap}>
                                    <LoadingButton loading={updateLoading} disableElevation onClick={update} variant="contained">Update Results</LoadingButton>
                                    <LoadingButton disableElevation onClick={() => setDelDialogOpen(true)} color="error" variant="contained">Delete Query</LoadingButton>
                                </div>
                            )
                        }
                    </div>

                </div>

                {
                    window.innerWidth <= IPHONE_PLUS_WIDTH && <div>(Tap bar to view info)</div>
                }
            </div>

            <div>
                <ResponsiveContainer width="100%" height={490}>

                    {
                        parameters.length > 0 ? (
                            <BarChart
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                // barSize={20}
                                // maxBarSize={100}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis dataKey="count" />
                                <Tooltip
                                    labelStyle={{ color: "#000000" }}
                                    allowEscapeViewBox={{ x: true, y: true }}
                                />
                                <Legend />
                                <Bar dataKey="count" fill="#8884d8">
                                    {
                                        data.map((d, idx) => {
                                            let bestColor = "#5eba7d";
                                            let otherColor = "#8884d8";
                                            if (d.count === bestCount) {
                                                return <Cell key={idx} fill={bestColor} />
                                            } else {
                                                return <Cell key={idx} fill={otherColor} />
                                            }

                                        })
                                    }
                                </Bar>
                            </BarChart>
                        ) : (
                            <div className={s.emptyData}>
                                <div>No results</div>
                            </div>
                        )
                    }
                </ResponsiveContainer>
            </div>

            <Dialog
                open={delDialogOpen}
                onClose={() => setDelDialogOpen(false)}
            >
                <DialogTitle>
                    Are you sure you want to permanently delete this query?
                </DialogTitle>

                <DialogActions>
                    <Button onClick={() => setDelDialogOpen(false)}>No</Button>
                    <LoadingButton onClick={deleteRecord} loading={delLoading}>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

const mapDispatch = {
    setAlert: (alertMsg: AlertMsg) => setAlert(alertMsg)
}
export default connect(null, mapDispatch)(ValueCountsGraph);