import s from './UnityLaunchAreaTitle.module.scss'
import { Container, Typography } from '@mui/material';


const UnityLaunchAreaTitle = () => {
    return (
        <Container className={s.titleWrap}>
            <Typography fontSize={20} fontWeight={600}>
                Config Editor
            </Typography>
        </Container>
    );
};

export default UnityLaunchAreaTitle;