import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ApiInfo, FirebaseApiInfo, FirebaseProject, ProjectInterface, UnityApiInfo, UnityProject, UnitySettings } from '../../../redux/projects/interfaces';
import s from './ProjectForm.module.scss'
import AddIcon from '@mui/icons-material/Add';
import { RootState } from '../../../redux/rootReducer';
import { createFirebaseProjectInDb, createNonUnityProjectInDb, createUnityProjectInDb } from '../../../redux/projects/projectsActions'
import { ReduxAction } from '../../../redux/rootReducer'
import { connect } from 'react-redux';
import BackdropLoader from '../../reusable/BackdropLoader';
import { PROJECT_APIS } from '../../../helpers/constants';
import UnityProjectFields from './ProjectForm/UnityProjectFields';
import { AlertMsg } from '../../../redux/admin/interfaces';


type Props = {
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    createUnityProjectInDb: (p: UnityProject) => ReduxAction
    createFirebaseProjectInDb: (p: FirebaseProject) => ReduxAction
    createNonUnityProjectInDb: (p: ProjectInterface) => ReduxAction
    creator: string,
    alertMsg: AlertMsg
}

/* Creates a project in the db */
const ProjectForm = ({
    setOpen,
    createUnityProjectInDb,
    createFirebaseProjectInDb,
    createNonUnityProjectInDb,
    creator,
    alertMsg
} : Props) => {
    const INIT_STATE: Omit<ProjectInterface, "apiInfo"> = {
        name: "",
        abTests: [],
        creator: creator,
        createdAt: "",
        updatedAt: ""
    }
    const UNITY_INIT_STATE: UnityApiInfo = {
        api: "UNITY",
        unitySettings: { projectId: "", environmentId: ""}
    }
    // const FIREBASE_INIT_STATE: FirebaseApiInfo = {
    //     api: "FIREBASE",
    //     firebaseSettings: { projectId: ""}
    // }
    const [state, setState]                         = useState<Omit<ProjectInterface, "apiInfo">>(INIT_STATE);
    const [error, setError]                         = useState<boolean>(false);
    const [apiInfo, setApiInfo]                     = useState<ApiInfo>(UNITY_INIT_STATE);
    const [hasRequiredFields, setHasRequiredFields] = useState(false);
    const [usingDefault, setUsingDefault]           = useState(true); // for unity only; default project api is UNITY
    const [loading, setLoading]                     = useState(false);

    useEffect(() => {
        return () => {
            setOpen(false)
            setLoading(false);
        }
    }, []);
    

    useEffect(() => {
        setLoading(false);
    }, [alertMsg])


    /* -------    handles state and api changes ----------- */
    useEffect(() => {
        if (apiInfo.api === "UNITY") {
            let { projectId } = apiInfo.unitySettings!;
            let envFieldValid = !usingDefault && !apiInfo.unitySettings!.environmentId ? false : true;
            if (projectId && state.name && state.creator && envFieldValid) {
                setHasRequiredFields(true);
            } else {
                setHasRequiredFields(false);
            }
        } else {
            if (state.name && state.creator) {
                setHasRequiredFields(true);
            }
        }
        // } else if (apiInfo.api === "FIREBASE") {
        //     if (apiInfo.firebaseSettings!.projectId && state.name && state.creator) {
        //         setHasRequiredFields(true);
        //     } else {
        //         setHasRequiredFields(false);
        //     }
        // }
    }, [state, apiInfo])




    /* -------  (( UNITY only EFFECT ))   handles changes to unity default/envID radio switch ----------- */
    useEffect(() => {

        setApiInfo(prev => ({...prev, unitySettings: { ...prev.unitySettings!, environmentId: ""}}))
    }, [usingDefault])




    function handleClick() {
        if (hasRequiredFields) {
            setLoading(true);
            if (apiInfo.api === "UNITY") {
                const unityApiInfo: UnityApiInfo = {
                    api: "UNITY",
                    unitySettings: apiInfo.unitySettings as UnitySettings
                }
                // fire off saga
                createUnityProjectInDb({
                    creator: state.creator,
                    abTests: [],
                    name: state.name,
                    apiInfo: unityApiInfo,
                    createdAt: "",
                    updatedAt: ""
                });
            } else {
                createNonUnityProjectInDb({
                    creator: state.creator,
                    abTests: [],
                    name: state.name,
                    apiInfo: { api: apiInfo.api },
                    createdAt: "",
                    updatedAt: ""
                })
            }
            // } else if (apiInfo.api === "FIREBASE") {
            //     const firebaseApiInfo: FirebaseApiInfo = {
            //         api: "FIREBASE",
            //         firebaseSettings: apiInfo.firebaseSettings as FirebaseSettings
            //     }
            //     createFirebaseProjectInDb({
            //         creator: state.creator,
            //         abTests: [],
            //         name: state.name,
            //         apiInfo: firebaseApiInfo,
            //         createdAt: "",
            //         updatedAt: ""
            //     })
            // }
        } else {
            setError(true);
        }
    }

    function handleNonApiFields(e: ChangeEvent<HTMLInputElement>) {
        setError(false);
        setState((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });
    }

    function handleUnityApiFields(e: ChangeEvent<HTMLInputElement>) {
        setError(false);
        let { name, value } = e.target;
        setApiInfo(prev => {
            return {
                ...prev,
                unitySettings: {
                    ...prev.unitySettings!,
                    [name]: value
                }
            }
        });
    }
    
    // function handleFirebaseApiFields(e: ChangeEvent<HTMLInputElement>) {
    //     setError(false);
    //     let { value } = e.target;
    //     setApiInfo(prev => {
    //         return {
    //             ...prev,
    //             firebaseSettings: {
    //                 projectId: value // we only have one field needed so far, so we don't use the [name] pattern
    //             }
    //         }
    //     })
    // }

    function handleRadioChange(e: ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        if (value === "UNITY" && apiInfo.api !== "UNITY") {
            setApiInfo(UNITY_INIT_STATE)
        } else {
            setApiInfo({ api: value as ApiInfo["api"] })
        }
    }
    return (
            <div className={s.wrap}>
                <h2>Project Fields</h2>
                <section>
                    <TextField
                        name="name"
                        onChange={handleNonApiFields}
                        label="Project Name"
                        value={state.name}
                        error={error && !state.name}
                        fullWidth
                        required
                    />
                </section>

                <section>
                    <FormControl className={s.formGroupWrap}>
                        <FormLabel>Select Api</FormLabel>
                        <RadioGroup className={s.radioGroup} value={apiInfo.api} name="api" onChange={handleRadioChange}>
                        {
                            PROJECT_APIS.map((project, idx) => (
                                <FormControlLabel classes={{label: s.label}} key={idx} value={project} control={<Radio />} label={project} />
                            ))
                        }
                        </RadioGroup>
                    </FormControl>
                </section>

                {
                    apiInfo.api === "UNITY" && (
                        <UnityProjectFields
                            apiInfo={apiInfo as UnityApiInfo}
                            usingDefault={usingDefault}
                            error={error}
                            handleUnityApiFields={handleUnityApiFields}
                            setUsingDefault={setUsingDefault}
                        />
                        
                    )
                }

                {
                    // apiInfo.api === "FIREBASE" && (
                    //     <FirebaseProjectFields
                    //         handleFirebaseApiFields={handleFirebaseApiFields}
                    //         apiInfo={apiInfo as FirebaseApiInfo}
                    //         error={error}
                    //     />
                    // )
                }

                <section>
                    <Button onClick={handleClick} variant="contained" disabled={!hasRequiredFields} fullWidth>
                        <AddIcon sx={{fontSize: '50px'}}/>
                    </Button>
                </section>
                <BackdropLoader backgroundColor="#cdcdcd8f" circleColor="black" loading={loading}/>
            </div>
            
        
    );
};

const mapDispatch = {
    createUnityProjectInDb: (p: UnityProject) => createUnityProjectInDb(p),
    createFirebaseProjectInDb: (p: FirebaseProject) => createFirebaseProjectInDb(p),
    createNonUnityProjectInDb: (p: ProjectInterface) => createNonUnityProjectInDb(p)
}

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg, username } = rootState.admin;
    return {
        creator: username,
        alertMsg
    }
}
export default connect(mapStateToProps, mapDispatch)(ProjectForm);