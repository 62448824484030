import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { ReduxAction, RootState } from "./redux/rootReducer";
import { selectAuthed } from "./redux/admin/adminSelectors";
import { connect } from "react-redux";
import LoginPage from "./components/pages/LoginPage";
import PrivateRoute from "./components/reusable/PrivateRoute";
import AppBody from "./AppBody";
import { initAppState, setAlert, setAuthed } from "./redux/admin/adminActions";
import axios, { AxiosError } from "axios";
import { store } from "./redux/store";
import { hasHeaderFormat, hasSameHeaders, newHeadersValid } from "./helpers/utils";
import { ERR_SNACKBAR } from "./helpers/constants";
import { BROWSER_HISTORY } from ".";


type Props = {
    authed: boolean,
    initAppState: () => ReduxAction,
}


/* 
   this code runs immediately before our code in sagas etc receives the response back from the server
   this is the first receiver of the response, then the res: AxiosResponse will get the return value of this 
   interceptor.

   This handles routing when session is expired or we are logging in
*/
axios.interceptors.response.use(res => {
        const { sessionId, refreshToken, username, authed } = store.getState().admin;
        const authHeaders = { sessionId, refreshToken, username };

        // here, new headers won't be valid if incorrect login. We'll let the saga handle that logic
        if (!newHeadersValid(res.headers)) {
            if (res.config.url !== "/api/v1/user/login") {
                // store.dispatch(setAlert({msg: "backend auth headers incorrectly formatted", config: ERR_SNACKBAR}));
            }
            return res;
        }
        
        // at this point, we will have backend auth headers formatted correctly, with 100% certainty
        if (res.config.url?.includes('login')) {
            
            // check if response has same authHeaders as in state
            if (!authed && !hasSameHeaders(authHeaders, res.headers)) {
                store.dispatch(setAuthed(true, res.headers.username, res.headers.sessionid, res.headers.refreshtoken));
                BROWSER_HISTORY.push('/home/projects');
            }
        } else if (res.config.url?.includes('logout')) {
            BROWSER_HISTORY.push('/login');
        } else {
            // check headerFormat is good first
            if (authed && !hasHeaderFormat(authHeaders, res.headers)) {
                store.dispatch(setAuthed(false, "", "", ""));
                store.dispatch(setAlert({msg: "auth headers incorrectly formatted", config: ERR_SNACKBAR}));
                return res;
            }

            // check if response has same authHeaders as in state
            if (authed && !hasSameHeaders(authHeaders, res.headers)) {
                store.dispatch(setAuthed(true, res.headers.username, res.headers.sessionid, res.headers.refreshtoken));
            }
        }
        
        
        return res;
    },
    (error: AxiosError) => {
        if (error.response && error.response.status === 403) {
            store.dispatch(setAuthed(false, "", "", ""));
            store.dispatch(setAlert({msg: "", config: ERR_SNACKBAR}));
        }
        return Promise.reject(error);
    }
);


// every request must have headers attached appropriately, before being sent
// this will run as the last stage before axios finally sends request to the server
axios.interceptors.request.use(config => {
    const { sessionId, refreshToken, username } = store.getState().admin;
    config.headers.sessionid    = sessionId;
    config.headers.refreshtoken = refreshToken;
    config.headers.username     = username;
    return config;
});




function App({ authed, initAppState }: Props) {
    useEffect(() => {
        if (authed) {
            initAppState();
        }
    }, [authed]);

    return (
        <div>
            <Switch>
                <Route path="/login" component={LoginPage}/>
                <Route exact path="/" component={() => <Redirect to="/home/projects"/>}/>
                <PrivateRoute path="/home" authed={authed} component={AppBody}/>
            </Switch>
        </div>
    )
}


const mapDispatch = {
    initAppState: () => initAppState()
}

type SProps = Pick<Props, "authed">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    authed: selectAuthed,
});
export default connect(mapStateToProps, mapDispatch)(App);
