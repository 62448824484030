import axios, { AxiosResponse } from "axios";
import { ImageType } from "react-images-uploading";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AXIOS_ERROR, BASE_URL, ERR_SNACKBAR, SUCCESS_SNACKBAR, UNSUCCESS } from "../../../../helpers/constants";
import { setAlert } from "../../../admin/adminActions";
import { AbTestInterface } from "../../../abtest/interfaces";
import { DESIGN_ACTIONS } from "./interfaces";
import { replaceAbTest } from "../../../projects/projectsActions";
import { deleteDesignInDb, addDesignToDb, updateDesignInDb } from './designActions';



type DesignRouteResponse = {
    success: boolean,
    error?: string,
    updatedAbTest?: AbTestInterface
}



// ------------------------------------------------------------------ Delete Design in DB ----------------------------------------------------------------------------------------//
type DeleteDesignInDbAction = ReturnType<typeof deleteDesignInDb>;
function* handleDeleteDesignInDb({ payload }: DeleteDesignInDbAction) {
    const { abTestId, groupName, design } = payload;
    try {
       
        const url                    = BASE_URL + '/projects/abtests/designgroup/design';
        const res: AxiosResponse<DesignRouteResponse> = yield call(
            axios.delete, 
            url,
            { data: { abTestId, groupName, designToDelete: design }}
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const {success, error, updatedAbTest} = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!updatedAbTest) throw new Error("No updated ab test returned.");
        yield put(replaceAbTest(updatedAbTest));
        yield put(setAlert({
            msg: "Successfully deleted design.",
            config: SUCCESS_SNACKBAR
        }))
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}


function* interceptDeleteDesignInDb() {
    yield takeEvery(
        [DESIGN_ACTIONS.DELETE_DESIGN_IN_DB],
        handleDeleteDesignInDb
    )
}







// ------------------------------------------------------------------ Add Design to DB ----------------------------------------------------------------------------------------//
type AddDesignToDbAction = ReturnType<typeof addDesignToDb>;
function* handleAddDesignToDb({ payload }: AddDesignToDbAction) {
    const { design, abTestId, groupName } = payload;
    try {
        const { image, ...rest }    = design;
        const form                  = new FormData();
        form.append("abTestId", abTestId);
        form.append("groupName", groupName);
        form.append("design", JSON.stringify({...rest, imageUrl: ""})); // important! imageUrl must be added, since there is no default in db (if we are having a text design)

        // the form takes the actual image off the InitialDesign, and the backend multer package handles the file upload
        if (image) {
            const { file } = image as ImageType;
            form.append("image", file as File);
        }
    
        const url = BASE_URL + '/projects/abtests/designgroup/design';
        const res: AxiosResponse<DesignRouteResponse> = yield call(
            axios.post, 
            url,
            form,
            { headers: { 'Content-Type': 'multipart/form-data'}} // must be this type in order to send an image
        );
    
        if (!res) throw new Error(AXIOS_ERROR);
        const {success, error, updatedAbTest} = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!updatedAbTest) throw new Error("No updated ab test returned.");
        yield put(replaceAbTest(updatedAbTest));
        yield put(setAlert({
            msg: "Successfully added design.",
            config: SUCCESS_SNACKBAR
        }))
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}


function* interceptAddDesignToDb() {
    yield takeEvery(
        [DESIGN_ACTIONS.ADD_DESIGN_TO_DB],
        handleAddDesignToDb
    )
}






// ------------------------------------------------------------------ Update Design ----------------------------------------------------------------------------------------//
/*
    action.payload will be a form with all the data needed.
    See DesignFormFinal.tsx handleClick
    and /designs/_index.ts on the backend, patch route
*/
type UpdateDesignInDb = ReturnType<typeof updateDesignInDb>;
function* handleUpdateDesignInDb(action: UpdateDesignInDb) {
    try {
        const url                 = BASE_URL + '/projects/abtests/designgroup/design'; 
        const res: AxiosResponse<DesignRouteResponse> = yield call(
            axios.patch, 
            url,
            action.payload,
            { headers: { 'Content-Type': 'multipart/form-data'}}
        );
    
        if (!res) throw new Error(AXIOS_ERROR);
        const {success, error, updatedAbTest} = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!updatedAbTest) throw new Error("No updated ab test returned.");
        yield put(replaceAbTest(updatedAbTest));
        yield put(setAlert({
            msg: "Successfully updated design.",
            config: SUCCESS_SNACKBAR
        }));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }));
    }
}

function* interceptUpdateDesignInDb() {
    yield takeLatest(
        [DESIGN_ACTIONS.UPDATE_DESIGN_IN_DB],
        handleUpdateDesignInDb
    )
}



export function* designSagas() {
    yield all([
        call(interceptAddDesignToDb),
        call(interceptDeleteDesignInDb),
        call(interceptUpdateDesignInDb)
    ])
}