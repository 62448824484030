import { DataGrid, GridColDef, GridRowData, GridRowId, GridRowParams, GridSlotsComponent, GridFilterModel, GridValidRowModel, GridState } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { BROWSER_HISTORY } from '../..';
import { arraysEqual, makeSlug } from '../../helpers/utils';
import { ABTestGridRow } from '../../redux/abtest/interfaces';
import { setNavProjectName, setFilter, setNavFilteredAbTests, setNavAbTestName } from '../../redux/navigation/navActions';
import { ProjectGridRow } from '../../redux/projects/interfaces';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import s from './DataTable.module.scss'
import { useEffect, useState } from 'react';
import { NavState } from '../../redux/navigation/interfaces';

type Props = {
    rows: ProjectGridRow[] | ABTestGridRow[],
    cols: GridColDef[],
    components?: Partial<GridSlotsComponent>,
    purpose: "abTest" | "project"
    filterValue: NavState["filterValue"]
    filteredAbTests: NavState["filteredAbTests"]
    setNavProjectName: (s: string) => ReduxAction
    setFilter: (filter: string) => ReduxAction
    setNavFilteredAbTests: (tests: string[]) => ReduxAction
}


/*
    takes in rows, cols to display a projects grid or an ab tests data grid
    if purpose === "abTest" we do a lot of logic for navigating ab tests
    else we do basics 

    all state is basically used only for abTest purposes
*/
const DataTable = ({ 
    rows, 
    cols, 
    components, 
    purpose,
    filterValue,
    filteredAbTests,
    setNavProjectName, 
    setFilter,
    setNavFilteredAbTests
} : Props) => {


    const baseUrl                               = '/home/projects/';
    const [filteredIndexes, setFilteredIndexes] = useState<GridRowId[]>([]); // array of indexes as strings, representing the indexes to show after filtering
    const [baseRows, setBaseRows]               = useState<Record<GridRowId, GridValidRowModel>>({}) // an Object with indexes of rows as the key, and row as the value
    const [filterModel, setFilterModel]         = useState<GridFilterModel>({
        items: [
            { columnField: "name", operatorValue: "contains", value: filterValue}
        ]
    })


    useEffect(() => {
        if (purpose === "project") {
            if (filteredAbTests.length > 0) {
                setNavFilteredAbTests([]);
            }
            if (filterValue) {
                setFilter('');
            }
        }
    }, [])


    function setToBaseRows() {
        const filtered = [];
        for (const idx of Object.keys(baseRows)) {
            filtered.push(baseRows[Number(idx)].name)
        }
        setNavFilteredAbTests(filtered.reverse())
    }

    function setToFilteredRows(visibleRows: GridRowId[]) {
        const filtered = [];
        setFilteredIndexes(visibleRows);
        for (const idx of visibleRows) {
            let i = Number(idx.toString());
            filtered.push(baseRows[i].name);
        }
        setNavFilteredAbTests(filtered.reverse());
    }


    function handleRowClick(params: GridRowParams) {

        if (purpose === "abTest") {
            setNavAbTestName(params.row.name);
            BROWSER_HISTORY.push(baseUrl + `${makeSlug(params.row.project)}/abtests/${makeSlug(params.row.name)}`)
        } else {
            setNavProjectName(params.row.name);
            BROWSER_HISTORY.push(baseUrl + `${makeSlug(params.row.name)}`)
        }
    }


    return (
        <div className={s.wrap}>
            <DataGrid 
                rows={rows} 
                columns={cols} 
                disableColumnSelector={true} 
                disableSelectionOnClick={true}
                components={components}
                onRowClick={handleRowClick}
                getRowClassName={(params: GridRowParams) => s.rowClass}
                filterModel={purpose === "abTest" ? filterModel : undefined}
                onStateChange={(state: GridState, event) => {
                    if (purpose === "abTest") {     
                        // init baseRows and return
                        if (Object.keys(baseRows).length === 0) {
                            const baserows = state.rows.idRowsLookup;
                            return setBaseRows(baserows);
                        }
                        if (filteredAbTests.length === 0) {
                            setToBaseRows();
                        } else {
                            const { visibleRowsLookup } = state.filter;
                            const rowIndexes = Object.keys(visibleRowsLookup)
                            const visibleRows = [];
                            for (const idx of rowIndexes) {
                                if (visibleRowsLookup[idx] === true) {
                                    visibleRows.push(idx);
                                }
                            }
                            // arraysEqual protects us from an infinite render loop
                            if (visibleRows && !arraysEqual(visibleRows as string[], filteredIndexes as string[])) {
                                if (visibleRows.length > 0) {
                                    setToFilteredRows(visibleRows);
                                }
                            }
                        }
                        
                    }
                }}
                onFilterModelChange={(model: GridFilterModel) => {
                    if (purpose === "abTest") {
                        if (model.items.length > 0) {
                            for (const item of model.items) {
                                if (item.columnField === "name") {
                                    // logic here for everything
                                    let newFilterValue = item.value;
                                    if (!newFilterValue) {
                                        setFilter('');
                                        setToBaseRows();
                                    } else {
                                        setFilter(newFilterValue);
                                    }
                                }
                            }
                        } else { // this is executed when someone clicks the X button onthe filter popup

                            setFilter('')
                            setToBaseRows();
                        }
                        setFilterModel(model)
                    }
                }}
            />
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { filterValue, filteredAbTests } = rootState.nav;
    return { filterValue, filteredAbTests } 
}
const mapDispatch = {
    setNavProjectName: (s: string) => setNavProjectName(s),
    setNavAbTestName: (s: string) => setNavAbTestName(s),
    setFilter: (filter: string) => setFilter(filter),
    setNavFilteredAbTests: (tests: string[]) => setNavFilteredAbTests(tests)
}
export default connect(mapStateToProps, mapDispatch)(DataTable);