import { all, call } from 'redux-saga/effects'
import { abTestSagas } from './abtest/abTestSagas';
import { adminSagas } from './admin/adminSagas';
import { externalApiSagas } from './external-apis/externalApisSagas';
import { projectsSagas } from './projects/projectsSagas';


export default function* rootSaga() {
    yield all([
        call(projectsSagas),
        call(abTestSagas),
        call(adminSagas),
        call(externalApiSagas)
    ]);
}