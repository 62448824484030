import { DesignGroup } from "../../abtest/design-group/interfaces"
import { ReduxAction } from "../../rootReducer"
import { ACTIONS, OurApiState, OUR_API_INIT_STATE } from "./interfaces"



export const ourApiReducer = (state: OurApiState = OUR_API_INIT_STATE, action: ReduxAction): OurApiState => {
    switch(action.type) {
        
        
        // NORMAL ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_ALL_EVENT_NAMES_OUR_API:
            return {
                ...state,
                allEventNames: action.payload
            }
        case ACTIONS.SET_GROUP_PROPERTY_NAMES_OUR_API:
            return {
                ...state,
                designGroupDynamicData: state.designGroupDynamicData.map(data => {
                    if (data.name === action.payload.groupName) {
                        return {
                            ...data,
                            properties: action.payload.propertyNames
                        }
                    } else {
                        return data
                    }
                })            
            }
        case ACTIONS.SET_MIN_MAX_DATES_OUR_API:
            return {
                ...state,
                designGroupDynamicData: state.designGroupDynamicData.map(data => {
                    if (data.name === action.payload.groupName) {
                        return {
                            ...data,
                            minEventStartDate: action.payload.dates.start,
                            maxEventEndDate: action.payload.dates.end
                        }
                    } else {
                        return data
                    }
                })
            }
        case ACTIONS.CLEAR_PROPERTIES_AND_DATES_OUR_API:
            return {
                ...state,
                designGroupDynamicData: state.designGroupDynamicData.map(data => {
                    if (data.name === action.payload) {
                        return {
                            ...data,
                            minEventStartDate: "",
                            maxEventEndDate: "",
                            properties: []
                        }
                    } else {
                        return data
                    }
                })
            }
        case ACTIONS.SET_CURRENT_DYNAMIC_GROUPS_OUR_API:
            return {
                ...state,
                designGroupDynamicData: (action.payload as DesignGroup[]).map(group => {
                    return {
                        name: group.name,
                        minEventStartDate: "",
                        maxEventEndDate: "",
                        properties: []
                    }
                })
            }
        case ACTIONS.SET_EVENT_DATA_LOADING:
            return {
                ...state,
                eventDataLoading: action.payload
            }
        default:
            return { ...state }
    }
}