import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CircularProgress, Tab } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { BASE_URL, ERR_SNACKBAR, IPHONE_PLUS_WIDTH, IPHONE_PRO_MAX } from '../../../helpers/constants';
import { setAlert } from '../../../redux/admin/adminActions';
import { AdminState, AlertMsg } from '../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../redux/rootReducer';
import s from './DisplayValueCountsArea.module.scss'
import { ValueCounts } from './GetValueCountsArea';
import ValueCountsGraph from './GetValueCountsArea/ValueCountsGraph';

type Props = {
    setAlert: (alert: AlertMsg) => ReduxAction
    theme: AdminState["theme"]
}
export interface ParameterValueCountInterface {
    parameterValues: ValueCounts
    parameter: string
    eventName: string
    projectName: string
    projectId?: string
    createdAt: string
}
type GetAllCountsResponse = {
    allProjectValueCounts?: ParameterValueCountInterface[]
    success: boolean
    error?: string
}
const DisplayValueCountsArea = ({ theme, setAlert }: Props) => {
    const [allValueCounts, setAllValueCounts] = useState<ParameterValueCountInterface[]>([]);
    const [loading, setLoading] = useState(true);
    const NO_GROUPS = "no groups";
    const [currValueCount, setCurrValueCount] = useState(allValueCounts.length > 0 ? getOptionName(allValueCounts[0]) : '');
    const WINDOW_WIDTH = window.innerWidth;


    useEffect(() => {
        async function getAllCounts() {
            setLoading(true);
            try {
                const url = BASE_URL + '/analytics/techlabfze/projectdata/saved';
                const response = await axios.get<any, AxiosResponse<GetAllCountsResponse>>(url);
                if (!response) throw new Error("no response from get counts query")
                const { success, error, allProjectValueCounts } = response.data;
                if (error) throw new Error(error);
                if (!success) throw new Error("unsuccessful query.")
                if (!allProjectValueCounts) throw new Error('No results returned, even though it was a successful query.')
                setAllValueCounts(allProjectValueCounts);
                if (allProjectValueCounts.length > 0) {
                    setCurrValueCount(getOption(allProjectValueCounts[0]))
                }
            } catch (e: any) {
                setAlert({
                    msg: e.message,
                    config: ERR_SNACKBAR
                })
            }
            setLoading(false);
        }
        getAllCounts();
        return () => {
            setLoading(false);
            setCurrValueCount('');
        }
    }, [])


    function getOptionName(valueCount: ParameterValueCountInterface) {
        return `${valueCount.projectName} (${new Date(valueCount.createdAt).toLocaleDateString('en-GB')})`
    }
    function getOption(valueCount: ParameterValueCountInterface) {
        return `${valueCount.projectName}(${valueCount.createdAt})`
    }

    function updateParamValueCounts(updatedRecord: ParameterValueCountInterface) {
        setAllValueCounts(prevCounts => (
            [...prevCounts.filter(record => record.createdAt !== updatedRecord.createdAt), updatedRecord]
        ))
    }

    function deleteParamValueCount(deletedRecord: ParameterValueCountInterface) {
        setAllValueCounts(prevCounts => prevCounts.filter(record => record.createdAt !== deletedRecord.createdAt))
        // select another project after deleting


        if (allValueCounts.length > 1) {
            // first handle deleting the first record
            if (allValueCounts[0].createdAt === deletedRecord.createdAt) {
                setCurrValueCount(getOption(allValueCounts[1]));
            } else {
                setCurrValueCount(getOption(allValueCounts[0]));
            }
        }
    }
    return (
        <div className={s.wrap} style={WINDOW_WIDTH <= IPHONE_PRO_MAX ? { display: 'block' } : {}}>
            {
                loading ? <CircularProgress /> : (
                    allValueCounts.length > 0 ? (
                        <TabContext value={currValueCount}>
                            <TabList
                                orientation={WINDOW_WIDTH > IPHONE_PRO_MAX ? "vertical" : "horizontal"}
                                variant="scrollable"
                                className={s.tabList}
                                onChange={(e: SyntheticEvent, newValue: string) => setCurrValueCount(newValue)}
                                sx={{ background: theme === "dark" ? "#343434" : "#f4f4f4" }}
                            >
                                {
                                    allValueCounts.map((vCount, idx) => <Tab key={idx} value={getOption(vCount)} label={getOptionName(vCount)} />)
                                }
                            </TabList>
                            {
                                allValueCounts.map((pvCount, idx) => (
                                        <TabPanel classes={{ root: s.tabPanel }} key={idx} value={getOption(pvCount)}>
                                            <ValueCountsGraph
                                                key={idx}
                                                valueCounts={pvCount.parameterValues}
                                                displayOnly
                                                updatePvCounts={updateParamValueCounts}
                                                deletePvCount={deleteParamValueCount}
                                                pvCount={pvCount}
                                            />
                                        </TabPanel>
                                ))
                            }

                        </TabContext>
                    ) : (
                        <div>{NO_GROUPS}</div>
                    )
                )
            }


        </div>
    );
};

const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert)
}
const mapStateToProps = (rootState: RootState) => {
    const { theme } = rootState.admin;
    return { theme }
}
export default connect(mapStateToProps, mapDispatch)(DisplayValueCountsArea)