import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useState } from 'react'
import { AbTestInterface } from '../../../../redux/abtest/interfaces';
import s from './DesignGroup.module.scss'
import DesignCardFinal from './DesignGroup/DesignCardFinal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCard from '../../../reusable/AddCard';
import CustomModal from '../../../reusable/CustomModal';
import { SxProps } from '@mui/system';
import DesignForm from '../DesignsArea/AddDesignGroupForm/DesignForm';
import { createStructuredSelector } from 'reselect';
import { RootState } from '../../../../redux/rootReducer';
import { AdminState } from '../../../../redux/admin/interfaces';
import { selectTheme } from '../../../../redux/admin/adminSelectors';
import { connect } from 'react-redux';
import { DesignGroup } from '../../../../redux/abtest/design-group/interfaces';
import DesignGroupButtons from './DesignGroup/DesignGroupButtons';

type Props = {
    group: DesignGroup,
    abTest: AbTestInterface,
    theme: AdminState["theme"]
};

export const DesignGroupComponent = ({ group, abTest, theme } : Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [expanded, setExpanded] = useState(true);
    const iconStyles = {
        fontSize: '0.9rem'
    };
    const summaryStyles: SxProps = {
        '& .MuiAccordionSummary-content': {
            paddingLeft: '15px'
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)'
        },
        flexDirection: 'row-reverse'
    } 
    
    let bestScore = -Infinity;
    let bestTrialScore = -Infinity;
    group.designs.forEach(design => {
        if (design.score > bestScore) {
            bestScore = design.score;
        }
        if(design.trialScore > bestTrialScore){
            bestTrialScore = design.trialScore;
        }
    });

    return (
        <Accordion sx={theme === "dark" ? { backgroundColor: "#2d2d2d"} : {}} expanded={expanded}>
            <AccordionSummary onClick={() => setExpanded(!expanded)} sx={summaryStyles}  expandIcon={<ArrowForwardIosIcon sx={iconStyles}/>}>{group.name}</AccordionSummary>
            <AccordionDetails>
                <div className={s.wrap}>
                {
                    group.designs.map((design, idx) => (
                        <DesignCardFinal
                            key={idx}
                            topTrialPerformer={bestTrialScore === design.trialScore && bestTrialScore !== 0 ? true : false}
                            design={design}
                            group={group}
                            abTestId={abTest._id}
                            topPerformer={bestScore === design.score && bestScore !== 0 ? true : false}
                        />
                    ))
                }
                    <AddCard setOpen={setOpen}/>
                </div>
                <DesignGroupButtons group={group} abTestId={abTest._id} groupNames={abTest.designGroups.map(g => g.name)}/>
                <CustomModal open={open} setOpen={setOpen}>
                    <DesignForm 
                        setOpen={setOpen} 
                        purpose="postToDb"
                        abTest={abTest} 
                        existingDesigns={group.designs}
                        groupName={group.name}
                    />
                </CustomModal>
            </AccordionDetails>
        </Accordion>
       
    );
};

type SProps = Pick<Props, "theme">
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    theme: selectTheme
});

export default connect(mapStateToProps)(DesignGroupComponent);