import { Box, Button, Card, CardActions, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { darkModeCardBg, darkModeCardContent } from '../../../../../helpers/constants';
import { deleteDesignInDb } from '../../../../../redux/abtest/design-group/designs/designActions';
import { Design } from '../../../../../redux/abtest/design-group/designs/interfaces';
import { DesignGroup } from '../../../../../redux/abtest/design-group/interfaces';
import { selectAlertMsgTxt, selectTheme } from '../../../../../redux/admin/adminSelectors';
import { AdminState } from '../../../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../../../redux/rootReducer';
import CustomModal from '../../../../reusable/CustomModal';
import InlineSvg from '../../../../reusable/InlineSvg';
import s from './DesignCardFinal.module.scss';
import DeleteDialog from './DesignCardFinal/DeleteDialog';
import DesignFormFinal from './DesignCardFinal/DesignFormFinal';
import JsonDialog from './DesignCardFinal/JsonDialog';


type Props = {
    design: Design,
    group: DesignGroup,
    abTestId: string,
    topPerformer: boolean,
    topTrialPerformer: boolean,
    theme: AdminState["theme"],
    alertText: string,
    deleteDesignInDb: (design: Design, abTestId: string, groupName: string) => ReduxAction,
}

/* Design Card component used for an existing design in the database */
const DesignCardFinal = ({
    design,
    group,
    abTestId,
    topPerformer,
    topTrialPerformer,
    theme,
    alertText,
    deleteDesignInDb
}: Props) => {

    const [open, setOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [jsonOpen, setJsonOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    function handleConfirm() {
        setLoading(true);
        deleteDesignInDb(design, abTestId, group.name);
    }

    // when it's finished loading (component unmounts), reset vals
    useEffect(() => {
        return () => {
            setConfirmationOpen(false);
        }
    }, [design])
    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [alertText])


    const DesignText = () => (
        design.designText && design.designText.length > 140 ? (
            <Tooltip title={design.designText}>
                <div className={s.designTextWrap} style={theme === "dark" ? { color: "black" } : {}}>
                    <div>
                        {design.designText}
                    </div>
                </div>
            </Tooltip>
        ) : (
            <div className={s.designTextWrap} style={theme === "dark" ? { color: "black" } : {}}>
                <div>
                    {design.designText}
                </div>
            </div>
        )
    );

    const DesignImg = () => {
        if (!design.imageUrl) return null;

        if (design.imageUrl.includes('.svg')) {
            return <InlineSvg url={design.imageUrl} />
        } else {
            return <CardMedia
                component="img"
                alt="design img"
                image={design.imageUrl}
                className={s.image}
            />
        }
    }

    const styles = theme === "dark" ? darkModeCardBg : {};

    return (
        <div>
            <Card className={s.wrap} >
                {
                    design.designText ? (
                        <CardMedia component={DesignText} />
                    ) : (
                        <DesignImg />
                    )
                }


                <div className={s.btmWrap}>
                    <CardContent className={s.content} sx={theme === "dark" ? darkModeCardContent : {}}>
                        <div className={s.nameWrap}>
                            <Typography gutterBottom variant="body2" component="div">
                                {design.name}
                            </Typography>
                        </div>
                        {/* <div className={s.commentsWrap}>
                            <Tooltip title={design.comments}>
                                <Typography variant="body2" color="text.secondary">
                                    Comments: {design.comments}
                                </Typography>
                            </Tooltip>
                        </div> */}
                        <div className={s.flexContainer}>
                            <Box sx={topPerformer ? { background: "#5eba7d" } : styles}>
                                <div className={s.clicksPropsWrap}>
                                    <p>
                                        clicks: {design.clicks}
                                    </p>
                                    <p>
                                        impressions: {design.properties}
                                    </p>
                                </div>
                                <div className={s.btm}>
                                    <Typography variant="body2" color="text.secondary">
                                        Ratio: {design.score}
                                    </Typography>
                                </div>
                            </Box>
                            <Box sx={topTrialPerformer ? { background: "#5eba7d" } : styles}>
                                <div className={s.clicksPropsWrap}>
                                    <p>
                                        trials: {design.trial}
                                    </p>
                                    <p>
                                        impressions: {design.properties}
                                    </p>
                                </div>
                                <div className={s.btm}>
                                    <Typography variant="body2" color="text.secondary">
                                        Ratio: {design.trialScore}
                                    </Typography>
                                </div>
                            </Box>
                        </div>
                    </CardContent>

                    <CardContent  >

                    </CardContent>

                    <CardActions className={theme === "dark" ? s.btnsDark : s.btns}>
                        <Button size="small" onClick={() => setOpen(true)}>
                            Edit
                        </Button>
                        <Button size="small" onClick={() => setConfirmationOpen(true)}>Delete</Button>
                        {design.type === 'image' ? <Button size="small" onClick={() => setJsonOpen(true)}>JSON Info</Button> : null}
                    </CardActions>
                </div>
                <CustomModal open={open} setOpen={setOpen}>
                    <DesignFormFinal setOpen={setOpen} design={design} group={group} />
                </CustomModal>
                <DeleteDialog
                    confirmationOpen={confirmationOpen}
                    loading={loading}
                    setConfirmationOpen={setConfirmationOpen}
                    handleConfirm={handleConfirm}
                />
                <JsonDialog
                    jsonOpen={jsonOpen}
                    design={design}
                    setJsonOpen={setJsonOpen}
                />
            </Card>
            {/* {
                topPerformer && (
                    <CardContent className={s.lowerContent}>
                        <div className={s.topPerformer}>TOP PERFORMER</div>
                    </CardContent>
                )
            } */}

            {/* {
                window.innerWidth > 438 && (
                    <CardContent className={s.lowerContent}>
                        <div>&#123;</div>
                        <div style={lowerData}>"key":"{design.name}",</div>
                        <div style={lowerData}>"link":"{design.imageUrl}"</div>
                        <div>&#125;</div>
                    </CardContent>
                )
            } */}

        </div>
    );
};

const mapDispatch = {
    deleteDesignInDb: (design: Design, abTestId: string, groupName: string) => deleteDesignInDb(design, abTestId, groupName)
}

type SProps = Pick<Props, "theme" | "alertText">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    theme: selectTheme,
    alertText: selectAlertMsgTxt
});
export default connect(mapStateToProps, mapDispatch)(DesignCardFinal);