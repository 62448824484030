import axios, { AxiosResponse } from "axios"
import { all, call, put, select, take, takeLatest } from "redux-saga/effects"
import { AXIOS_ERROR, BASE_URL, ERR_SNACKBAR, SUCCESS_SNACKBAR, UNSUCCESS } from "../../../helpers/constants"
import { setAlert } from "../../admin/adminActions"
import { UnityAbTest } from "../../abtest/interfaces"
import { ProjectInterface, UnityProject } from "../../projects/interfaces"
import { replaceAbTest } from "../../projects/projectsActions"
import { selectProjects } from "../../projects/projectsSelectors"
import { ACTIONS } from './interfaces'
import { launchUnityCampaign, setUnityConfigKeys, setHasUnityCampaign, getUnityConfigKeys, checkUnityCampaignExists } from "./unityActions"



// ------------------------------------------------------------------ {{ Get unity config keys }} ----------------------------------------------------------------------------------------//
/*
    NOTES: we export these because they are used by the loadUnityAbTestData action creator
           ONCE when the AbTestPage.tsx is mounted, and also used by individual components
           on the page too
*/
type RemoteConfigResponse = {
    success: boolean,
    configKeys?: string[],
    error?: string
};
export type GetUnityConfigKeysAction = ReturnType<typeof getUnityConfigKeys>;
export function* handleGetUnityConfigKeys({ payload }: GetUnityConfigKeysAction) {
    try {
        const url = BASE_URL + '/analytics/unity/remoteconfig';
        const res: AxiosResponse<RemoteConfigResponse> = yield call(
            axios.get,
            url,
            { params: { projectId: payload as string}}
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { configKeys, success, error } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!configKeys) throw new Error("config keys were undefined. Please contact Developer.");
    
        yield put(setUnityConfigKeys(configKeys));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({config: ERR_SNACKBAR, msg: e.message}))
    }
}

function* interceptGetConfigKeys() {
    while (true) {
        const action: GetUnityConfigKeysAction  = yield take(ACTIONS.GET_UNITY_CONFIG_KEYS);
        yield call(handleGetUnityConfigKeys, action)
    }
}







// ------------------------------------------------------------------ {{ Launch a Unity Campaign }} ----------------------------------------------------------------------------------------//
type LaunchUnityAbTestAction = ReturnType<typeof launchUnityCampaign>;
type AbTestCreateRes         = {
    updatedAbTest?: UnityAbTest,
    success: boolean,
    error?: string
};
function* handleLaunchUnityCampaign({ payload }: LaunchUnityAbTestAction) {
    try {
        const url = BASE_URL + '/analytics/unity/remoteconfig/abtest/create';
        const res: AxiosResponse<AbTestCreateRes> = yield call(
            axios.post,
            url,
            payload
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { updatedAbTest, success, error } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!updatedAbTest) throw new Error("No updated ab test returned. Please contact developer.");
        yield put(replaceAbTest(updatedAbTest))
        yield put(setHasUnityCampaign(true));
        yield put(setAlert({msg: "Successfully launched new campaign.", config: SUCCESS_SNACKBAR}));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({config: ERR_SNACKBAR, msg: e.message}))
    }
}

function* interceptLaunchUnityCampaign() {
    yield takeLatest(
        [ACTIONS.LAUNCH_UNITY_CAMPAIGN],
        handleLaunchUnityCampaign
    )
}









// ------------------------------------------------------------------ {{ Check if a unity campaign exists }} ----------------------------------------------------------------------------------------//
/*
    NOTES: this saga is only used on ab test page load
    
           we export these because they are used by the loadUnityAbTestData action creator
           ONCE when the AbTestPage.tsx is mounted, and also used by individual components
           on the page too
*/
type CheckIfExistsResponse = {
    exists?: boolean,
    success: boolean,
    error?: string
};
type CheckIfExistsPayload = {
    projectId: string,
    campaignId: string
}
export type CheckUnityCampaignExistsAction = ReturnType<typeof checkUnityCampaignExists>;
export function* handleCheckUnityCampaignExists(action: CheckUnityCampaignExistsAction) {
    try {
        const { projectName, campaignId }  = action.payload;
        const projects: ProjectInterface[] = yield select(selectProjects);
        const project                      = projects.find(proj => proj.name === projectName) as UnityProject | undefined;
        if (!project) throw new Error("No project found while search for the " + projectName + " project.");
        const { unitySettings } = project.apiInfo;
        const data: CheckIfExistsPayload = {
            projectId: unitySettings.projectId,
            campaignId
        }
        const url = BASE_URL + '/analytics/unity/remoteconfig/abtest/check';
        const res: AxiosResponse<CheckIfExistsResponse> = yield call(
            axios.get,
            url,
            { params: data }
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { success, exists, error } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (!exists) {
            yield put(setHasUnityCampaign(false));
        } else {
            yield put(setHasUnityCampaign(true));
        }
    } catch (e: any) {
        console.log(e);
        yield put(setHasUnityCampaign(false));
        // we expect rule not found if there is no campaign in api, no need for notification
        if (!e.message.includes("Rule not found")) {
            yield put(setAlert({config: ERR_SNACKBAR, msg: e.message}))
        }
    }
}


/*   we use "take" here because take will block all other actions until the saga interceptGetEventDataOurApi is complete */
function* interceptCheckUnityCampaignExists() {
    while (true) {
        const action: CheckUnityCampaignExistsAction = yield take(ACTIONS.CHECK_UNITY_CAMPAIGN_EXISTS);
        yield call(handleCheckUnityCampaignExists, action);
    }
}








export function* unityApiSagas() {
    yield all([
        call(interceptGetConfigKeys),
        call(interceptLaunchUnityCampaign),
        call(interceptCheckUnityCampaignExists)
    ])
}