import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router';
import s from './MainDrawer.module.scss'
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import LogoutIcon from '@mui/icons-material/Logout';
import { toggleTheme } from '../../redux/admin/adminActions';
import { connect } from 'react-redux';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import BarChartIcon from '@mui/icons-material/BarChart';
import { AdminState } from '../../redux/admin/interfaces';
import { createStructuredSelector } from 'reselect';
import { selectTheme } from '../../redux/admin/adminSelectors';
import LightModeIcon from '@mui/icons-material/LightMode';
type Props = {
    setOpen: Dispatch<SetStateAction<boolean>>,
    open?: boolean,
    setDialogOpen: Dispatch<SetStateAction<boolean>>,
    variant: "permanent" | "temporary",
    toggleTheme: () => ReduxAction,
    theme: AdminState["theme"]
}

const MainDrawer = ({ setOpen, setDialogOpen, toggleTheme, theme, open, variant } : Props) => {
    const history = useHistory();
    return (
        <Drawer onClick={() => setOpen(false)} anchor="left" open={open} variant={variant}>
            <List className={s.wrap}>
                <ListItem button key={1} onClick={() => history.push('/home')}>
                    <ListItemIcon>
                        <HomeIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Home"}/>
                </ListItem>
                <ListItem button key={2} onClick={() => history.push('/home/projects')}>
                    <ListItemIcon>
                        <BuildIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Projects"} />
                </ListItem>

                <ListItem button key={6} onClick={() => history.push('/home/specialdata')}>
                    <ListItemIcon>
                        <BarChartIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Project Data"} />
                </ListItem>
                    
                <ListItem button key={3}  onClick={() => setDialogOpen(true)}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Sign Out"}/>
                </ListItem>

                <ListItem button key={4}  onClick={() => toggleTheme()}>
                    <ListItemIcon>
                        {
                            theme === "dark" ? (
                                <DarkModeIcon/>
                            ) : (
                                <LightModeIcon/>
                            )
                        }
                    </ListItemIcon>
                    <ListItemText primary={"Toggle Theme"}/>
                </ListItem>
            </List>
        </Drawer>
    );
};

const mapDispatch = {
    toggleTheme: () => toggleTheme()
}
type SProps = Pick<Props, "theme">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    theme: selectTheme
})
export default connect(mapStateToProps, mapDispatch)(MainDrawer);