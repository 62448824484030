import axios, { AxiosResponse } from "axios"
import { all, call, put, take } from "redux-saga/effects"
import { AXIOS_ERROR, BASE_URL, ERR_SNACKBAR, UNSUCCESS } from "../../../helpers/constants"
import { setAlert } from "../../admin/adminActions"
import { ACTIONS } from './interfaces'
import { clearPropertiesAndDatesOurApi, getEventDataOurApi, setMinMaxDateOurApi, setGroupPropertyNames } from "./ourApiActions"





export type GetEventDataOurApiAction = ReturnType<typeof getEventDataOurApi>;
type EventDataRouteResponse = {
    success: boolean,
    properties?: string[],
    minMaxDates?: { start: string, end: string},
    error?: string
};
export function* handleGetEventDataOurApi(action: GetEventDataOurApiAction) {
    try {
        const route                         = BASE_URL + '/analytics/techlabfze/eventproperty/eventdata';
        const { eventName, otherEventName, groupName, trialName } = action.payload;
        const res: AxiosResponse<EventDataRouteResponse> = yield call(
            axios.get,
            route,
            { params: { eventName, otherEventName , trialName }}
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { success, error, properties, minMaxDates } = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        if (properties === undefined) throw new Error("properties were undefined. Contact Developer");
        if (minMaxDates === undefined) throw new Error("minMaxDates were undefined. Contact Developer");
        if (!minMaxDates.end || !minMaxDates.start) {
            // updates the loading state in EventProertyFields.tsx
            yield put(clearPropertiesAndDatesOurApi(groupName));
            throw new Error("min or max date wasn't present.")
        }
        yield put(setMinMaxDateOurApi(minMaxDates, groupName))
        yield put(setGroupPropertyNames(properties, groupName));
        if (properties.length === 0) throw new Error('no properties returned.')
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }))
    }
}


/*   we use "take" here because take will block all other actions until the saga interceptGetEventDataOurApi is complete */
function* interceptGetEventDataOurApi() {
    while (true) {
        const action: GetEventDataOurApiAction = yield take(ACTIONS.GET_EVENT_DATA_OUR_API)
        yield call(handleGetEventDataOurApi, action);
    }
}



export function* ourApiSagas() {
    yield all([
        call(interceptGetEventDataOurApi)
    ])
}