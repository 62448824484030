import { OptionsObject } from "notistack";

export const ACTIONS = {
    SET_AUTHED: "SET_AUTHED",
    SET_ALERT: "SET_ALERT",
    TOGGLE_THEME: "TOGGLE_THEME",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    INIT_APP_STATE: "INIT_APP_STATE",
}

export interface AlertMsg {
    msg: string,
    config: OptionsObject
}

export interface AdminState {
    authed: boolean,
    username: string,
    sessionId: string,
    refreshToken: string,
    alertMsg: AlertMsg,
    theme: "light" | "dark"
}



export const ADMIN_INIT_STATE: AdminState = {
    authed: false,
    username: "",
    sessionId: "",
    refreshToken: "",
    alertMsg: { msg: "", config: {}},
    theme: "light",
}