import { combineReducers } from "redux";
import { initAbTestReducer } from "./abtest/abtest-init/abtestInitReducer";
import { adminReducer } from "./admin/adminReducer";
import { externalApisReducer } from "./external-apis/externalApisReducer";
import { navReducer } from "./navigation/navReducer";
import { projectsReducer } from "./projects/projectsReducer";
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/es/persistReducer";
import hardSet from "redux-persist/es/stateReconciler/hardSet";

const persistConfig = {
    key: 'admin',
    storage,
    stateReconciler: hardSet
}

/*
    admin: handles administrative state such as sesionId, theme, etc
    initAbTest: this reducer handles the create ab test form, and adding design groups to an ab test
    projects: contains all the projects and grid data
    nav: stores the current ab test or project based on user navigation
    externalApis: {
        ourApiReducer: contains dynamic design group data (properties, etc.), from our api, the /techlabfze route
        unityApiReducer: contains dynamic data from unity api (unity campaign data, config keys, etc.)
    }
*/

export const rootReducer = combineReducers({
    admin: persistReducer(persistConfig, adminReducer), // we only want to persist the admin reducer
    initAbTest: initAbTestReducer,
    projects: projectsReducer,
    nav: navReducer,
    externalApis: externalApisReducer
});

export type RootState = ReturnType<typeof rootReducer>
export type ReduxAction = {
    type: string,
    payload?: any
}