import { ReduxAction } from "../../rootReducer"
import { InitAbTestState, ACTIONS, INIT_AB_TEST_INIT_STATE } from "./interfaces"




export const initAbTestReducer = (state: InitAbTestState = INIT_AB_TEST_INIT_STATE, action: ReduxAction): InitAbTestState => {
    switch (action.type) {
        case ACTIONS.ADD_INITIAL_DESIGN:
            return {
                ...state,
                initialDesigns: action.payload.name ? (
                    [...state.initialDesigns, action.payload]
                ) : (
                    state.initialDesigns
                ),
                designError: action.payload.name ? (
                    state.designError
                ) : (
                    true
                )  
            }
        case ACTIONS.UPDATE_INITIAL_DESIGN:
            let { idx, updatedDesign } = action.payload;
            return {
                ...state,
                initialDesigns: state.initialDesigns.map((d, i) => i === idx ? updatedDesign : d)
            }
        case ACTIONS.DELETE_INITIAL_DESIGN:
            return {
                ...state,
                initialDesigns: state.initialDesigns.filter((d, i) => i !== action.payload)
            }
        case ACTIONS.SET_CREATE_AB_TEST_FORM_OPEN:
            return {
                ...state,
                createAbTestFormOpen: action.payload
            }
        case ACTIONS.RESET_INIT_AB_TEST_STATE:
            return {
                ...INIT_AB_TEST_INIT_STATE
            }
        default:
            return { ...state }
    }
}