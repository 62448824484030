import { Dispatch, SetStateAction } from 'react'
import s from './ImageUploader.module.scss'
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";
import Button from "@mui/material/Button";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { BIG_LAPTOP_WIDTH } from '../../helpers/constants';

type Props = {
    image: ImageType[] | null,
    error: boolean,
    warning: string
    imgSize: string
    setImage: (imageList: ImageListType, addUpdateIndex: number[] | undefined) => void,
    setError: Dispatch<SetStateAction<boolean>>
}

/* uploading images using the react-images-uploading component */
const ImageUploader = ({
    image,
    error,
    warning,
    imgSize,
    setImage,
    setError
} : Props) => {

    
    function onChange(imageList: ImageListType, addUpdateIndex: number[] | undefined){
        // data for submit
        setImage(imageList, addUpdateIndex);
        if (!image && error) setError(false);
    };

    return (
        <div className={s.wrap}>
            <ImageUploading
                multiple
                value={image ? image : []}
                onChange={onChange}
                maxNumber={1}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <Button
                            variant="outlined"
                            onClick={onImageUpload}
                            endIcon={<AddAPhotoIcon />}
                            sx={error ? {borderColor: 'red'} : isDragging ? { borderColor: 'green'} : {}}
                            disabled={image ? true : false}
                            fullWidth
                            className={s.btn}
                            {...dragProps}
                        >
                            Upload Image 
                        </Button>
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image.dataURL} alt="uploadedimg"/>
                                <div className="image-item__btn-wrapper">
                                    <Button
                                        size={window.innerWidth > BIG_LAPTOP_WIDTH ? "medium" : "small"}
                                        onClick={() => onImageUpdate(index)}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        size={window.innerWidth > BIG_LAPTOP_WIDTH ? "medium" : "small"}
                                        onClick={() => onImageRemove(index)}
                                    >
                                        Remove
                                    </Button>

                                    <div className={s.warning}>
                                        {imgSize}
                                        <div>{warning}</div>
                                    </div>
                                
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

export default ImageUploader;