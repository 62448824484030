import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { connect } from 'react-redux';
import { BIG_LAPTOP_WIDTH, WARN_SNACKBAR } from '../../../../../helpers/constants';
import { getFileSizeMB, getFileSizeMsg } from '../../../../../helpers/utils';
import { addInitialDesign, updateInitialDesign } from '../../../../../redux/abtest/abtest-init/abtestInitActions';
import { addDesignToDb } from '../../../../../redux/abtest/design-group/designs/designActions';
import { Design, InitialDesign } from '../../../../../redux/abtest/design-group/designs/interfaces';
import { AbTestInterface } from '../../../../../redux/abtest/interfaces';
import { setAlert } from '../../../../../redux/admin/adminActions';
import { AlertMsg } from '../../../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../../../redux/rootReducer';
import ImageUploader from '../../../../reusable/ImageUploader';
import inputStyles from '../../../../sass/inputs.module.scss';
import TextFieldAdornment from '../DesignGroup/DesignCardFinal/DesignFormFinal/TextFieldAdornment';
import s from './DesignForm.module.scss';


type Props = {
    addInitialDesign: (d: InitialDesign) => ReduxAction, // for adding to redux state, while creating an ab test
    addDesignToDb: (design: InitialDesign, abTestId: string, groupName: string) => ReduxAction, // adding a design from ab test page, directly to db
    updateInitialDesign: (idx: number, d: InitialDesign) => ReduxAction, // only in create ab test section
    setOpen: Dispatch<SetStateAction<boolean>>,
    setAlert: (alert: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
    creator: string,
    abTest: AbTestInterface, // from parent's context
    initialDesigns: InitialDesign[], // for init ab test state
    existingDesigns?: Design[],    // for ab test page, and checking if there is an existing final design, before adding to db
    purpose?: "postToDb"
    idx?: number // index of design in rdx state
    groupName?: string // from parent,
    type?: "image" | "text" | "float" | "boolean" | "integer"
}



const BLANK_STATE: InitialDesign = {
    name: "",
    comments: "",
    image: null,
    trial: 0,
    creator: "",
    score: 0,
    trialScore: 0,
    type: "image",
    designText: "",
    clicks: 0,
    properties: 0,
    createdAt: "",
    updatedAt: ""
}



/*
    This form creates a brand new design; in the UI, it is used in creating a design group, and also in adding a design to an existing group
    DesignFormFinal is the form for an existing Design
*/
const DesignForm = ({
    addInitialDesign,
    addDesignToDb,
    updateInitialDesign,
    setOpen,
    setAlert,
    alertMsg,
    idx,
    initialDesigns,
    creator,
    purpose,
    existingDesigns,
    abTest,
    groupName,
    type
}: Props) => {

    let TYPE = type ? type : existingDesigns && existingDesigns[0].type || 'text'

    let isUpdatingDesign = typeof (idx) === 'number' ? true : false;
    let INIT_STATE = isUpdatingDesign ? initialDesigns[(idx as number)] : BLANK_STATE;
    let isTextChecked = isUpdatingDesign && INIT_STATE.designText ? true : false;
    const [design, setDesign] = useState<InitialDesign>({ ...INIT_STATE, type: TYPE });
    const [error, setError] = useState<boolean>(false);
    const [imgErr, setImgErr] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [isText, setIsText] = useState<boolean>(isTextChecked);
    const [loading, setLoading] = useState<boolean>(false); // used only for directly creating a design (purpose is defined)
    const allDesignNames: string[] = [];
    const [warning, setWarning] = useState(""); // when file size is bigger than 1.6MB, we set a warning
    const [imgSizeMsg, setImgSizeMsg] = useState("");


    abTest.designGroups.forEach(group => {
        group.designs.forEach(design => {
            allDesignNames.push(design.name);
        })
    });


    // set creator field on mount
    useEffect(() => {
        setDesign((prev) => ({ ...prev, creator }))
    }, [creator])

    // when final designs are updated, close form and loading
    useEffect(() => {
        return () => {
            setOpen(false);
            setLoading(false);
        }
    }, [existingDesigns]);

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [alertMsg.msg])

    function resetField(field: keyof InitialDesign) {
        setDesign(prev => ({ ...prev, [field]: INIT_STATE[field] }))
    }

    function handleInputChange(e: any) {
        let value = e.target.value
        setError(false);
        if (e.target.name === "name") {
            if (!/^\S*$/.test(e.target.value)) {
                return
            }
            setDesign((oldVal) => {
                return {
                    ...oldVal,
                    [e.target.name]: value
                }
            })
        }

        if (e.target.name === 'designText') {
            if (TYPE === 'float') {
                value = e.target.value.replace(/[^\d.]/g, '')
                setDesign((oldVal) => {
                    return {
                        ...oldVal,
                        designText: value,
                        name: value
                    }
                })
            }
            else if (TYPE === 'integer') {
                value = e.target.value.replace(/[^0-9-]+/g, '')
                setDesign((oldVal) => {
                    return {
                        ...oldVal,
                        designText: value,
                        name: value
                    }
                })
            }
            else if (TYPE === 'boolean') {
                value = e.target.value.replace(/\D/g, '')
                setDesign((oldVal) => {
                    return {
                        ...oldVal,
                        designText: value,
                        name: value``
                    }
                })
            }
            else {
                setDesign((oldVal) => {
                    return {
                        ...oldVal,
                        designText: value,
                        name: value
                    }
                })
            }
        }


        if (e.target.name === "name" && allDesignNames.includes(e.target.value)) {
            setErrMsg("Name already exists in Ab Test");
            setError(true);
        } else {
            if (errMsg) setErrMsg("");
            if (error) setError(false);
        }
    }

    function setImage(imageList: ImageListType, addUpdateIndex: number[] | undefined) {
        // there will only ever be one image, since each design requires one image
        if (!isText) {
            setError(false);
        }

        if (imageList.length > 0 && imageList[0].file) {
            if (getFileSizeMB(imageList[0].file) > 1.6) {
                setAlert({ msg: "The file size is greater than 1.6MB", config: WARN_SNACKBAR });
                setWarning("image larger than 1.6MB")
            } else {
                setWarning("");
                setImgSizeMsg(getFileSizeMsg(imageList[0].file));
            }
        } else {
            setWarning("");
            setImgSizeMsg("");
        }
        setDesign((oldVal) => {
            return {
                ...oldVal,
                image: imageList.length === 1 ? (
                    imageList[0]
                ) : (
                    null
                )
            }
        });
    }

    // handle both adding a new design, and editing an existing one
    function handleClick() {
        if (design.name) {
            if (purpose === "postToDb" && existingDesigns) {

                const hasDuplicate = existingDesigns.some(d => d.name === design.name);
                if (hasDuplicate) {
                    setAlert({
                        msg: "There is a design with that name. Please ensure unique design names.",
                        config: WARN_SNACKBAR
                    })
                }

                if (TYPE === 'image' && !design.image) {
                    return setImgErr(true);
                }

                if ((TYPE === 'text' || TYPE === 'float' || TYPE === 'integer' || TYPE === "boolean") && !design.designText) {
                    return setError(true);
                }

                setLoading(true)

                // abTest and groupName will always be defined when purpose is postToDb; postToDb is set only in DesignGroup.tsx
                addDesignToDb(design, abTest!._id, groupName!);
            } else {
                // make sure design name is unique
                const hasDuplicate = initialDesigns.some(d => d.name === design.name);
                if (!isUpdatingDesign) {
                    if (hasDuplicate) {
                        setAlert({
                            msg: "There is a design with that name. Please ensure unique design names.",
                            config: WARN_SNACKBAR
                        })
                    }
                }


                // validate image and design text
                if (TYPE === 'image' && !design.image) {
                    return setImgErr(true);
                }

                if ((TYPE === 'text' || TYPE === 'float' || TYPE === 'integer' || TYPE === "boolean") && !design.designText) {
                    return setError(true);
                }


                // update design
                if (isUpdatingDesign) {
                    updateInitialDesign((idx as number), design);
                } else {
                    addInitialDesign(design);
                }
                setOpen(false);
            }
        } else {
            setError(true);
        }
    }


    function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
        const { name } = e.target;
        let type: "image" | "text";
        let clearImage = false;
        let resetDesignText = false;
        if (name === "textCheck") {
            setIsText(true);
            type = "text"
            if (design.image) {
                clearImage = true;
            }
        } else {
            setIsText(false);
            type = "image"
            if (design.designText) {
                resetDesignText = true;
            }
        }
        // update type, and remove image if setting to text type
        setDesign((oldVal) => ({
            ...oldVal,
            type,
            image: clearImage ? null : INIT_STATE.image,
            designText: resetDesignText ? "" : INIT_STATE.designText
        }));
    }

    return (
        <div className={s.wrap}>
            <h2>Design Fields</h2>
            {TYPE === 'image' &&
                <section>
                    <TextField
                        name="name"
                        onChange={handleInputChange}
                        label="Design Name"
                        value={design.name}
                        error={error}
                        helperText={error ? errMsg : ""}
                        size={window.innerWidth > BIG_LAPTOP_WIDTH ? "medium" : "small"}
                        className={inputStyles.textFields}
                        InputProps={isUpdatingDesign ? ({
                            endAdornment: <TextFieldAdornment handleClick={() => resetField("name")} />,
                            className: inputStyles.inputWrap
                        }) : ({ className: inputStyles.inputWrap })}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                    />
                </section>
            }

            {TYPE === 'image' ?
                <section>
                    <ImageUploader
                        image={design.image ? [design.image] : null}
                        error={imgErr && !design.image ? true : false}
                        setImage={setImage}
                        setError={setImgErr}
                        warning={warning}
                        imgSize={imgSizeMsg}
                    />
                </section>
                : null
            }

            {TYPE === 'text' || TYPE === 'float' || TYPE === 'integer' ?
                <section>
                    <TextField
                        name="designText"
                        onChange={handleInputChange}
                        label={TYPE === 'text' ? "Design Text" : TYPE === 'float' ? "Design Float" : "Design Integer"}
                        value={design.designText}
                        className={inputStyles.textFields}
                        InputProps={isUpdatingDesign ? ({
                            endAdornment: <TextFieldAdornment handleClick={() => resetField("designText")} />,
                            className: inputStyles.inputWrap
                        }) : ({ className: inputStyles.inputWrap })}
                        InputLabelProps={{ shrink: true }}
                        size={window.innerWidth > BIG_LAPTOP_WIDTH ? "medium" : "small"}
                        error={error}
                        fullWidth
                        multiline
                        required
                    />
                </section>
                : null
            }

            {TYPE === 'boolean' ?
                <section>
                    <Select
                        value={design.designText}
                        label="Design Boolean"
                        name="designText"
                        onChange={handleInputChange}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section>
                : null
            }

            <section>
                <LoadingButton disabled={!design.name} loading={loading} onClick={handleClick} variant="contained" fullWidth>
                    {
                        isUpdatingDesign ? (
                            "UPDATE"
                        ) : (
                            <AddIcon sx={{ fontSize: '50px' }} />
                        )
                    }
                </LoadingButton>
            </section>

        </div>
    );
};


const mapStateToProps = (rootState: RootState) => {
    const { initialDesigns } = rootState.initAbTest;
    const { username, alertMsg } = rootState.admin;
    return { initialDesigns, creator: username, alertMsg: alertMsg }
}
const mapDispatch = {
    addInitialDesign: (d: InitialDesign) => addInitialDesign(d),
    updateInitialDesign: (idx: number, d: InitialDesign) => updateInitialDesign(idx, d),
    setAlert: (alert: AlertMsg) => setAlert(alert),
    addDesignToDb: (design: InitialDesign, abTestId: string, groupName: string) => addDesignToDb(design, abTestId, groupName)
}
export default connect(mapStateToProps, mapDispatch)(DesignForm);