import { Button } from '@mui/material';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import s from './AbTestsCustomFooter.module.scss'
import { ReduxAction, RootState } from '../../../../redux/rootReducer';
import { connect } from 'react-redux';
import { SMALL_LAPTOP_WIDTH } from '../../../../helpers/constants';
import CustomModal from '../../../reusable/CustomModal';
import AbTestForm from '../../AbTestPage/AbTestForm';
import { setCreateAbTestFormOpen } from '../../../../redux/abtest/abtest-init/abtestInitActions';
import { InitAbTestState } from '../../../../redux/abtest/abtest-init/interfaces';


type Props = {
    setCreateAbTestFormOpen: (b: boolean) => ReduxAction,
    createAbTestFormOpen: InitAbTestState["createAbTestFormOpen"]
    projectNameNav: string
}

const AbTestsCustomFooter = ({ setCreateAbTestFormOpen, createAbTestFormOpen, projectNameNav } : Props) => {

    return (
        <GridFooterContainer>
            <div className={s.btnWrapper}>
                <Button 
                    variant="contained" 
                    size="large"
                    onClick={() => setCreateAbTestFormOpen(true)}
                    disableElevation
                    endIcon={window.innerWidth > SMALL_LAPTOP_WIDTH && <AddBoxIcon/>}
                    className={s.addBtn}
                >
                {
                    window.innerWidth > SMALL_LAPTOP_WIDTH ? (
                        "ADD TEST"
                    ) : (
                        <AddBoxIcon/>
                    )
                }
                </Button>
            </div>
            <GridFooter/>
            <CustomModal open={createAbTestFormOpen} setOpen={setCreateAbTestFormOpen}>
                <AbTestForm />
            </CustomModal>
        </GridFooterContainer>
    );
};

const mapDispatch = {
    setCreateAbTestFormOpen: (b: boolean) => setCreateAbTestFormOpen(b)
}

const mapStateToProps = (rootState: RootState) => {
    const { projectName } = rootState.nav;
    const { createAbTestFormOpen } = rootState.initAbTest;
    return { projectNameNav: projectName, createAbTestFormOpen }
}
export default connect(mapStateToProps, mapDispatch)(AbTestsCustomFooter);