import { Backdrop, CircularProgress } from '@mui/material';

type Props = {
    loading: boolean,
    backgroundColor: string,
    circleColor: string
}

/* Used for the project Form and AbTestForm  */
const BackdropLoader = ({
    loading,
    backgroundColor,
    circleColor
} : Props) => {
    return (
        <Backdrop open={loading} sx={{ backgroundColor, position: 'absolute' }}>
            <CircularProgress sx={{color: circleColor }}/>
        </Backdrop>
    );
};

export default BackdropLoader;