import { Dispatch, SetStateAction } from 'react'
import { Dialog, DialogContent } from '@mui/material';
import { Design } from '../../../../../../redux/abtest/design-group/designs/interfaces';

type Props = {
    jsonOpen: boolean,
    setJsonOpen: Dispatch<SetStateAction<boolean>>,
    design: Design
}
const lowerData = {
    paddingLeft: "15px",
    WhiteSpace: "nowrap"
}
const JsonDialog = ({
    jsonOpen,
    design,
    setJsonOpen
} : Props) => {
    return (
        <Dialog
            open={jsonOpen}
            onClose={() => setJsonOpen(false)}
        >
            <DialogContent>
                <div>&#123;</div>
                <div style={lowerData}>"key":"{design.name}",</div>
                <div style={lowerData}>"link":"{design.imageUrl}"</div>
                <div>&#125;</div>
            </DialogContent>
        </Dialog>
    );
};

export default JsonDialog;