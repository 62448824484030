import { InitialDesign } from "../design-group/designs/interfaces"
import { ACTIONS } from "./interfaces"

/*  local ab test creation actions */
export function addInitialDesign(d: InitialDesign) {
    return {
        type: ACTIONS.ADD_INITIAL_DESIGN,
        payload: d
    }
}
export function deleteInitialDesign(idx: number) {
    return {
        type: ACTIONS.DELETE_INITIAL_DESIGN,
        payload: idx
    }
}
export function updateInitialDesign(idx: number, updatedDesign: InitialDesign) {
    return {
        type: ACTIONS.UPDATE_INITIAL_DESIGN,
        payload: { idx, updatedDesign }
    }
}





/* Auxiliary actions */
export function setCreateAbTestFormOpen(b: boolean) {
    return {
        type: ACTIONS.SET_CREATE_AB_TEST_FORM_OPEN,
        payload: b
    }
}
export function resetInitAbTestState() {
    return {
        type: ACTIONS.RESET_INIT_AB_TEST_STATE
    }
}
