import { InitialDesign } from "../design-group/designs/interfaces"

// ------------------------------------------------------{{  REDUX ONLY  }}------------------------------------------------------------
export const ACTIONS = {
    DELETE_INITIAL_DESIGN: "DELETE_INITIAL_DESIGN",
    ADD_INITIAL_DESIGN: "ADD_INITIAL_DESIGN",
    UPDATE_INITIAL_DESIGN: "UPDATE_INITIAL_DESIGN",
    SET_CREATE_AB_TEST_FORM_OPEN:"SET_CREATE_AB_TEST_FORM_OPEN",
    RESET_INIT_AB_TEST_STATE: "RESET_INIT_AB_TEST_STATE",
}



export const INIT_AB_TEST_INIT_STATE: InitAbTestState = {
    createAbTestFormOpen: false,
    designError: false,
    initialDesigns: [],
}

export interface InitAbTestState {
    designError: boolean,
    createAbTestFormOpen: boolean,
    initialDesigns: InitialDesign[]
}
