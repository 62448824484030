import { Paper } from '@mui/material';
import { useState } from 'react'
import { Design } from '../../../../redux/abtest/design-group/designs/interfaces';
import s from './MobileDesignCard.module.scss'
import InlineSvg from '../../../reusable/InlineSvg';
type Props = {
    design: Design,
    best: boolean
}

const MobileDesignCard = ({ design, best } : Props) => {

    const [loading, setLoading] = useState(true);

    const DesignImg = () => {
        if (!design.imageUrl) return null;

        if (design.imageUrl.includes('.svg')) {
            return <InlineSvg url={design.imageUrl}/>
        } else {
            return <img
                src={design.imageUrl}
                alt="design"
            />
        }
    }
    return (
        <Paper className={s.wrap} sx={best ? {background: "#5eba7d"} : {}}>
            <div className={s.innerWrap}>
                <div className={s.imgWrap}>
                {
                    design.designText ? (
                        <div>{design.designText}</div>
                    ) : (
                        <DesignImg/>
                    )
                }
                </div>
                <div className={s.infoWrap}>
                    <h1>{design.name}</h1>
                    <div>Clicks: {design.clicks}</div>
                    <div>Properties: {design.properties}</div>
                    <div style={{fontStyle: "italic"}}>Ratio: {design.score}</div>
                </div>
            </div>
        </Paper>
    );
};

export default MobileDesignCard;