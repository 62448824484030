import s from './FeedbackShowArea.module.scss'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

type Props = {
    feedbackData: { [key: string]: any }
}

const FeedbackShowArea = ({ feedbackData }: Props) => {
    const [showFeedbacks, setShowFeedbacks] = useState<[]>();
    const displayFeedbackData = () => {

        for (const key in feedbackData) {
            if (feedbackData.hasOwnProperty(key)) {
                const value = feedbackData[key];
                console.log(`Key: ${key}, Value: ${value}`);
            }
        }
    }

    return (
        <div className={s.wrap}>
            <Accordion className={s.accordionWrap} classes={{ expanded: s.accordionWrap }}>
                <AccordionSummary classes={{ expanded: s.accordionSummaryContent, content: s.accordionSummaryContent }} expandIcon={<ExpandMoreIcon />} sx={{ minHeight: 'unset !important' }}>
                    <Typography fontSize={20} fontWeight={600} sx={{ margin: '10px 0' }}>
                        Feedback
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={s.detailsWrap}>
                    <ul>
                        {Object.keys(feedbackData).map((key) => (
                            <li key={key}>
                                {/* Render each key-value pair */}
                                <strong>{key}: </strong>
                                {feedbackData[key]}
                            </li>
                        ))}
                    </ul>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default FeedbackShowArea;