import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from 'history';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';
import ScrollToTop from './components/reusable/ScrollToTop';
export const BROWSER_HISTORY = createBrowserHistory();


const notistackRef = React.createRef<SnackbarProvider>();
const handleDismiss = (key: SnackbarKey) => () => { 
    if (notistackRef.current) {
        notistackRef.current.closeSnackbar(key);
    }
}
const dismissStyles = {
    color: 'white',
    backgroundColor: "#9d9d9d",
    "&:hover": {
        backgroundColor: "#545454"
    }
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={BROWSER_HISTORY}>
                <ScrollToTop>
                    <SnackbarProvider 
                        maxSnack={5}
                        ref={notistackRef}
                        action={(key) => (
                            <Button sx={dismissStyles} disableElevation variant="contained" size="small" onClick={handleDismiss(key)}>
                                Dismiss
                            </Button>
                        )}
                    >
                        <App/>
                    </SnackbarProvider>
                </ScrollToTop>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
