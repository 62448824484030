import { Paper, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react'
import s from './EventPropertyFields.module.scss'
import { AdminState } from '../../../../../redux/admin/interfaces';
import { RootState } from '../../../../../redux/rootReducer';
import { connect, useSelector } from 'react-redux';



export type DropdownOpts = {
    value: string,
    label: string
};
type Props = {
    handleChange: (name: string, value: string | null) => void, //parent
    event: string | null, // parent
    otherEvent: string | null, //parent
    property: string | null, //parent
    title: string, // parent
    theme: AdminState["theme"], // rdx
    allEventNames: string[], // rdx
    trialName: string,
}

/*
    NOTE: the labels for each field are different for their name, because Wissam wanted it that way.
          At this point, I still don't fully understand the meaning of the labels he chose, but for simplicity
          I logically think of each field as their "name" property (event, otherEvent, property).
*/
const EventPropertyFields = ({
    handleChange,
    event,
    title,
    otherEvent,
    trialName,
    property,
    theme,
    allEventNames,
}: Props) => {


    const [loading, setLoading] = useState(false);
    const properties = useSelector((rootState: RootState) => {
        const { designGroupDynamicData } = rootState.externalApis.ourApi;
        for (const dataPiece of designGroupDynamicData) {
            if (dataPiece.name === title) {
                return dataPiece.properties;
            }
        }
    });

    const makeDisabled = properties && properties.length === 0 && event && property && otherEvent ? true : false;
    useEffect(() => {
        setLoading(false);
    }, [properties])

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [])

    function handleEventsSelect(e: any, newValue: string | null) {
        handleChange("events", newValue);
    }
    function handleTrialSelect(e: any, newValue: string | null) {
        handleChange("trials", newValue);
    }

    function handleOtherEventSelect(e: any, newValue: string | null) {
        if (newValue) {
            setLoading(true);
        }
        handleChange("otherEvent", newValue);
    }

    function handlePropertySelect(e: any, newValue: string | null) {
        handleChange("property", newValue);
    }


    return (
        <Paper className={s.wrap} sx={theme === "dark" ? { backgroundColor: "#262626" } : {}}>
            <div className={s.innerWrap}>
                <div className={s.title}>{title}</div>
                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            name="events"
                            label="clicks"
                            {...params}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "13px" }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontSize: "13px" }
                            }}
                        />
                    )}
                    options={allEventNames.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    onChange={handleEventsSelect}
                    value={event}
                    disabled={makeDisabled}
                    classes={{ option: s.option }}
                    fullWidth
                />
                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            name="trials"
                            label="trials"
                            {...params}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "13px" }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontSize: "13px" }
                            }}
                        />
                    )}
                    options={allEventNames.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    onChange={handleTrialSelect}
                    value={trialName}
                    disabled={makeDisabled}
                    classes={{ option: s.option }}
                    fullWidth
                />

                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            name="otherEvent"
                            label="impressions"
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "13px" }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontSize: "13px" }
                            }}
                            size="small"
                        />
                    )}
                    options={allEventNames.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    onChange={handleOtherEventSelect}
                    disabled={!(event || trialName) || makeDisabled}
                    value={otherEvent}
                    classes={{ option: s.option }}
                    fullWidth
                />

                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="property"
                            label="parameter"
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "13px" },
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={17}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontSize: "13px" }
                            }}
                        />
                    )}
                    options={properties ? properties.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    ) : []}
                    onChange={handlePropertySelect}
                    disabled={loading || !properties || properties.length === 0 || makeDisabled || !otherEvent}
                    loading={loading}
                    loadingText="loading..."
                    value={property}
                    classes={{ option: s.option }}
                    fullWidth
                />
            </div>
        </Paper>
    );
};



const mapStateToProps = (state: RootState) => {
    const { theme } = state.admin;
    const { allEventNames } = state.externalApis.ourApi;
    return { theme, allEventNames }
}
export default connect(mapStateToProps)(EventPropertyFields);