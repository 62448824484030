import { createSelector } from "reselect";
import { RootState } from "../rootReducer";


// export const ADMIN_INIT_STATE: AdminState = {
//     authed: false,
//     username: "",
//     sessionId: "",
//     refreshToken: "",
//     alertMsg: { msg: "", config: {}},
//     theme: "light",
//     exportApiTable: {events: [], properties: []}
// }



const admin = (rootState: RootState) => rootState.admin;

export const selectAuthed = createSelector(
    [admin],
    admin => admin.authed
)


export const selectUsername = createSelector(
    [admin],
    admin => admin.username
)


export const selectAlertMsg = createSelector(
    [admin],
    admin => admin.alertMsg
)

export const selectAlertMsgTxt = createSelector(
    [admin],
    admin => admin.alertMsg.msg
)


export const selectTheme = createSelector(
    [admin],
    admin => admin.theme
);

export const selectAuthHeaders = (rootState: RootState) => {
    const { sessionId, username, refreshToken } = rootState.admin
    return {
        sessionId,
        username,
        refreshToken
    }
};

