import s from './EventPropertyArea.module.scss'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { DesignGroup } from '../../../redux/abtest/design-group/interfaces';
import GetDesignData from './EventPropertyArea/GetDesignData';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
    designGroups: DesignGroup[]
}

const EventPropertyArea = ({ designGroups } : Props) => {
    return (
        <div className={s.wrap}>
            <Accordion className={s.accordionWrap} classes={{expanded: s.accordionWrap}}>
                <AccordionSummary classes={{expanded: s.accordionSummaryContent, content: s.accordionSummaryContent}} expandIcon={<ExpandMoreIcon />} sx={{minHeight: 'unset !important'}}>
                    <Typography fontSize={20} fontWeight={600} sx={{margin: '10px 0'}}>
                        Get Event/Impressions Data
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={s.detailsWrap}>
                {
                    designGroups.map((group, idx) => <GetDesignData key={idx} designGroup={group}/>)
                }
                </AccordionDetails>                                   
            </Accordion>
        </div>
    );
};

export default EventPropertyArea;