import { TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import s from './DesignFormFinal.module.scss'
import { AlertMsg } from '../../../../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../../../../redux/rootReducer';
import { setAlert } from '../../../../../../redux/admin/adminActions';
import { connect } from 'react-redux';
import { DesignGroup } from '../../../../../../redux/abtest/design-group/interfaces';
import { ImageListType } from 'react-images-uploading';
import { ERR_SNACKBAR, WARN_SNACKBAR } from '../../../../../../helpers/constants';
import { getDesignFormData, getFileSizeMB, getFileSizeMsg } from '../../../../../../helpers/utils';
import { AbTestContext } from '../../../../AbTestPage';
import TextFieldAdornment from './DesignFormFinal/TextFieldAdornment';
import ImageUploader from '../../../../../reusable/ImageUploader';
import InlineSvg from '../../../../../reusable/InlineSvg';
import { AbTestInterface } from '../../../../../../redux/abtest/interfaces';
import { updateDesignInDb } from '../../../../../../redux/abtest/design-group/designs/designActions';
import { Design, InitialDesign } from '../../../../../../redux/abtest/design-group/designs/interfaces';
type Props = {
    setOpen: Dispatch<SetStateAction<boolean>>,
    setAlert: (alert: AlertMsg) => ReduxAction,
    updateDesignInDb: (form: FormData) => ReduxAction,
    alertMsg: AlertMsg,
    design: Design,
    group: DesignGroup
}

export type LocalDesign = Design & {
    newImage: InitialDesign["image"],
    image: InitialDesign["image"]
}


const DesignFormFinal = ({ 
    updateDesignInDb,
    setOpen, 
    setAlert,
    alertMsg,
    design,
    group
} : Props) => {


    const INIT_STATE               = {...design, image: null, newImage: null};
    const isTextDesign             = INIT_STATE.type === "image" ? false : true;
    const [localDesign, setDesign] = useState<LocalDesign>(INIT_STATE); 
    const [error, setError]        = useState<boolean>(false);
    const [errMsg, setErrMsg]      = useState("");
    const [loading, setLoading]    = useState<boolean>(false);
    const [isText, setIsText]      = useState<boolean>(isTextDesign);
    const abTest                   = useContext(AbTestContext) as AbTestInterface;
    const allDesignNames: string[] = [];
    const [warning, setWarning]    = useState(""); // for images larger than 1.6mb
    const [imgSizeMsg, setImgSizeMsg]    = useState("");
    abTest.designGroups.forEach(group => {
        group.designs.forEach(design => {
            allDesignNames.push(design.name);
        })
    });
    useEffect(() => {
        return () => {
            setLoading(false);
            setOpen(false);
        }
    }, [design])

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [alertMsg.msg])

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.name === "name") {
            if (!/^\S*$/.test(e.target.value)) {
                return
            }
        }
        setError(false);
        setDesign((oldVal) => {
            return {
                ...oldVal,
                [e.target.name]: e.target.value
            }
        })
        if (e.target.name === "name" && allDesignNames.includes(e.target.value)) {
            setError(true);
            setErrMsg("Design name already taken in Ab Test.");
        } else {
            if (error) setError(false);
            if (errMsg) setErrMsg("");
        }
    }



    function setImage(imageList: ImageListType, addUpdateIndex: number[] | undefined) {
        // there will only ever be one image, since each design requires one image
        if (!isText) {
            setError(false);
        }
        if (imageList.length > 0 && imageList[0].file) {
            if (getFileSizeMB(imageList[0].file) > 1.6) {
                setAlert({ msg: "The file size is greater than 1.6MB", config: WARN_SNACKBAR});
                setWarning("image larger than 1.6MB")
            } else {
                setWarning("");
                setImgSizeMsg(getFileSizeMsg(imageList[0].file));
            }
        }  else {
            setWarning("");
            setImgSizeMsg("");
        }
        setDesign((oldVal) => {
            return {
                ...oldVal,
                newImage: imageList.length === 1 ? (
                    imageList[0]
                ) : (
                    null
                )
            }
        })
    }


    // update
    function handleClick() {
        
        // form will be defined if empty is false
        const { empty, form } = getDesignFormData(localDesign, INIT_STATE);
        if (empty) {
            setAlert({
                msg: !abTest ? "Error getting A/B Test" : "No changes detected.",
                config: WARN_SNACKBAR
            });
        } else if (INIT_STATE.type === "text" && !isText && !localDesign.newImage) {
            // return if image isn't filled out
            return setError(true);
        } else if (INIT_STATE.type === "image" && isText && !localDesign.designText) {
            // return if designText isn't filled out
            return setError(true);
        } else if (!abTest) {
            return setAlert({
                msg: "No A/B test found.",
                config: ERR_SNACKBAR
            })
        } else {
            const otherDesigns = group.designs.filter(d => d.name !== design.name)
            const hasDuplicate = otherDesigns.some(d => d.name === localDesign.name);
            if (hasDuplicate) {
                return setAlert({
                    msg: "Design name already exists. Please choose another name.",
                    config: ERR_SNACKBAR
                })
            }
            (form as FormData).append("abTestId", abTest._id);
            (form as FormData).append("groupName", group.name);
            (form as FormData).append("designName", INIT_STATE.name);
            setLoading(true);
            updateDesignInDb((form as FormData));
        }
    }
    // function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    //     const { name } = e.target;
    //     let type: "image" | "text";
    //     let clearImage      = false;
    //     let resetDesignText = false;
    //     if (name === "textCheck") {
    //         setIsText(true);
    //         type = "text"
    //         if (localDesign.newImage) {
    //             clearImage = true;
    //         }
    //     } else {
    //         setIsText(false);
    //         type = "image"
    //         if (localDesign.designText) {
    //             resetDesignText = true;
    //         }
    //     }
    //     // update type, and remove image if setting to text type
    //     setDesign((oldVal) => ({
    //         ...oldVal, 
    //         type, 
    //         image: clearImage ? null : INIT_STATE.newImage,
    //         designText: resetDesignText ? "" : INIT_STATE.designText
    //     }));
    //     setError(false);
    // }

    function resetField(field: keyof LocalDesign) {
        setDesign(prev => ({...prev, [field]: INIT_STATE[field]}))
    }

    const DesignImg = () => {
        if (!design.imageUrl) return null;

        if (design.imageUrl.includes('.svg')) {
            return <InlineSvg url={design.imageUrl}/>
        } else {
            return <img src={localDesign.imageUrl} alt="design"/>
        }
    }
    return (
        <div className={s.wrap}>
            <h2>Design Fields</h2>
            <section>
                <TextField
                    name="name"
                    onChange={handleInputChange}
                    label="Design Name"
                    value={localDesign.name}
                    error={error || !localDesign.name}
                    helperText={errMsg ? errMsg : ""}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("name")}/>
                    }}
                    fullWidth
                    required
                />
            </section>


            {/* <section>
                <h3>Choose Design Type</h3>
                <FormControlLabel
                    control={<Checkbox 
                        onChange={handleCheckboxChange}
                        checked={!isText}
                        name="imageCheck"
                    />}
                    label="Image"                    
                />
                <FormControlLabel
                    control={<Checkbox
                        onChange={handleCheckboxChange}
                        checked={isText}
                        name="textCheck"
                    />}
                    label="Text"                   
                />
            </section> */}
            {
                !isText ? (
                    <section>
                        <ImageUploader 
                            image={localDesign.newImage ? [localDesign.newImage] : null} 
                            setImage={setImage}
                            setError={setError}
                            error={error}
                            warning={warning}
                            imgSize={imgSizeMsg}
                        />
                    </section>
                ) : (
                    <section>
                        <TextField
                            name="designText"
                            onChange={handleInputChange}
                            label="Design Text"
                            value={localDesign.designText}
                            InputProps={{
                                endAdornment: <TextFieldAdornment handleClick={() => resetField("designText")}/>
                            }}
                            error={error && !localDesign.image}
                            fullWidth
                            multiline
                        />
                    </section>
                )
            }

            {
                localDesign.imageUrl && (
                    <section>
                        <DesignImg/>
                    </section>
                )
            }
            

            {/* <section>
                <TextField
                    name="comments"
                    onChange={handleInputChange}
                    label="Comments"
                    value={localDesign.comments}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("comments")}/>
                    }}                    
                    fullWidth
                    multiline
                />
            </section>

            <section>
                <TextField
                    onChange={handleInputChange}
                    label="Creator"
                    value={localDesign.creator}
                    aria-readonly
                    fullWidth
                    disabled
                />
            </section>

            <section>
                <TextField
                    onChange={handleInputChange}
                    name="score"
                    label="Score"
                    value={localDesign.score}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("score")}/>
                    }}                     
                    fullWidth
                />
            </section> */}

            <section>
                <LoadingButton disabled={error || !localDesign.name} size="large" loading={loading} onClick={handleClick} variant="contained" fullWidth>
                    UPDATE
                </LoadingButton>
            </section>
        </div>
        
    );
};


const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    updateDesignInDb: (form: FormData) => updateDesignInDb(form)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return { alertMsg };
}

export default connect(mapStateToProps, mapDispatch)(DesignFormFinal);