import { LoadingButton } from '@mui/lab'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BASE_URL, ERR_SNACKBAR, SUCCESS_SNACKBAR } from '../../../../helpers/constants'
import { ValueCounts } from '../GetValueCountsArea'
import s from './SaveValueCountQuery.module.scss'
import { setAlert } from '../../../../redux/admin/adminActions';
import { ReduxAction } from '../../../../redux/rootReducer'
import { AlertMsg } from '../../../../redux/admin/interfaces'
import to from 'await-to-js'
import axios, { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { useProjectIdsAndNamesSelector } from '../../../../redux/projects/projectsSelectors'
import { ParameterValueCountInterface } from '../DisplayValueCountsArea'

type Props = {
    event: string
    parameter: string
    valueCounts: ValueCounts
    setOpen: Dispatch<SetStateAction<boolean>>
    setAlert: (alert: AlertMsg) => ReduxAction
    open: boolean
};
export type ProjectDataDropdownChoice = {
    projectId?: string
    projectName: string
};


/*
    PURPOSE OF COMPONENT: 
            dialog box that allows the user save the results of a get value count query to the db
            ensures a project is selected
            I included the projectId in case future functionality allows use to use the projectId for unforeseen db queries

*/
const SaveValueCountQuery = ({ 
    event, 
    parameter, 
    valueCounts,
    setAlert,
    setOpen,
    open 
} : Props) => {
    const [saveQueryLoading, setSaveQueryLoading] = useState(false);
    const [project, setProject] = useState<ProjectDataDropdownChoice>({ projectName: ''})
    const projectChoices = useProjectIdsAndNamesSelector();

    useEffect(() => {
        return () => {
            setProject({projectName: ''});
            setSaveQueryLoading(false);
        }
    },[])
    async function saveQuery() {
        setSaveQueryLoading(true);
        try {
            const url = BASE_URL + '/analytics/techlabfze/projectdata';
            const dataToSend: Omit<ParameterValueCountInterface, "createdAt"> = {
                parameterValues: valueCounts,
                parameter, 
                projectName: project.projectName,
                eventName: event
            }
            if (project.projectId) dataToSend.projectId = project.projectId;
            const [e1, response] = await to<AxiosResponse<{success: boolean, error?: string}>, any>(axios.post(url, dataToSend));
            if (e1) throw new Error(e1.message)
            if (!response) throw new Error("No response from save query.")
            const { success, error } = response.data;
            if (error) throw new Error(error);
            if (!success) throw new Error("Operation was unsuccessful.");
            setAlert({
                msg: "Successfully saved query.",
                config: SUCCESS_SNACKBAR
            })
            setOpen(false);
        } catch (e: any) {
            setAlert({
                msg: e.message,
                config: ERR_SNACKBAR
            })
        }
        setSaveQueryLoading(false);
    }

    function handleProjectSelect(e: any, newValue: string | null) {
        if (newValue) {
            const foundChoice = projectChoices.find(choice => choice.projectName === newValue);
            const newValueToSet: any = {
                projectName: foundChoice!.projectName
            }
            if (foundChoice!.projectId) newValueToSet.projectId = foundChoice!.projectId;
            setProject(newValueToSet);
        } else {
            setProject({ projectName: ''})
        }
    }
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Save Query to Database</DialogTitle>
            <DialogContent>
                
                <div className={s.wrap}>
                    <div>
                        <DialogContentText>
                            Query Inputs:
                        </DialogContentText>
                        <div><b>event: </b><em>{event}</em></div>
                        <div><b>parameter: </b><em>{parameter}</em></div>
                    </div>
                    <div>
                        <DialogContentText>
                            Query Results:
                        </DialogContentText>
                    {
                        Object.keys(valueCounts).map((valueType, idx) => (
                            <div key={idx}><b>{valueType}: </b><em>{valueCounts[valueType]}</em></div>
                        ))
                    }
                    </div>
                    <div>
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField 
                                    name="project" 
                                    label="Choose project" 
                                    {...params} 
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {fontSize: "13px"}
                                    }}
                                    InputLabelProps={{
                                        ...params.InputLabelProps,
                                        style: {fontSize: "13px"}
                                    }}
                                />
                            )}
                            options={projectChoices.map(choice => choice.projectName).sort((a, b) =>
                                a.toLowerCase().localeCompare(b.toLowerCase())
                            )}
                            onChange={handleProjectSelect}
                            value={project.projectName}
                            classes={{option: s.option}}
                            fullWidth
                        />
                    </div>
                    
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton variant="contained" onClick={() => setOpen(false)}>Cancel</LoadingButton>
                <LoadingButton disabled={!project.projectName} loading={saveQueryLoading} variant="contained" onClick={saveQuery}>Save Query</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert)
}
export default connect(null, mapDispatch)(SaveValueCountQuery);