import { ReduxAction } from "../rootReducer"
import { ACTIONS, NavState, NAV_INIT_STATE } from "./interfaces"



export const navReducer = (state: NavState = NAV_INIT_STATE, action: ReduxAction): NavState => {
    switch(action.type) {
        
        
        // NORMAL ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_NAV_ABTEST_NAME:
            return {
                ...state,
                abTestName: action.payload
            }
        case ACTIONS.SET_NAV_PROJECT_NAME:
            return {
                ...state,
                projectName: action.payload,
                abTestName: ""
            }
        case ACTIONS.SET_AB_TEST_DATA_LOADED:
            return {
                ...state,
                abTestDataLoaded: action.payload
            }
        case ACTIONS.SET_FILTERED_AB_TESTS:
            return {
                ...state,
                filteredAbTests: action.payload
            }
        case ACTIONS.SET_FILTER:
            return {
                ...state,
                filterValue: action.payload
            }
        default:
            return { ...state }
    }
}