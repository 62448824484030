import { ConfigGroup } from "../../../components/pages/AbTestPage/unity-components/UnityLaunchArea/UnityConfigArea";
import { UnityAbTest } from "../../abtest/interfaces";
import { ACTIONS } from "./interfaces";



export function setUnityConfigKeys(keys: string[]) {
    return {
        type: ACTIONS.SET_UNITY_CONFIG_KEYS,
        payload: keys
    }
}



export function setHasUnityCampaign(bool: boolean) {
    return { 
        type: ACTIONS.SET_HAS_UNITY_CAMPAIGN,
        payload: bool
    }
}



// used only on ab test page load
export function setUnityCampaignCheckLoading(bool: boolean) {
    return { 
        type: ACTIONS.SET_CHECK_UNITY_CAMPAIGN_LOADING,
        payload: bool
    }
}



/* ------------------------ caught by sagas ------------------ */

// used only on ab test page load
export function checkUnityCampaignExists(campaignId: string, projectName: string) {
    return { 
        type: ACTIONS.CHECK_UNITY_CAMPAIGN_EXISTS,
        payload: {campaignId, projectName}
    }
}



export function getUnityConfigKeys(projectId: string) {
    return {
        type: ACTIONS.GET_UNITY_CONFIG_KEYS,
        payload: projectId
    }
}



export function launchUnityCampaign(abTest: UnityAbTest, configGroup: ConfigGroup) {
    return {
        type: ACTIONS.LAUNCH_UNITY_CAMPAIGN,
        payload: { abTest, configGroup }
    }
}