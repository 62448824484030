export const ACTIONS = {
    SET_NAV_PROJECT_NAME: "SET_NAV_PROJECT_NAME",
    SET_NAV_ABTEST_NAME: "SET_NAV_ABTEST_NAME",
    SET_AB_TEST_DATA_LOADED: "SET_AB_TEST_DATA_LOADED",
    SET_FILTERED_AB_TESTS: "SET_FILTERED_AB_TESTS",
    SET_FILTER: "SET_FILTER"
}


export interface NavState {
    projectName: string
    abTestName: string
    abTestDataLoaded: boolean
    filteredAbTests: string[]
    filterValue: string
}



export const NAV_INIT_STATE: NavState = {
    projectName: "",
    abTestName: "",
    abTestDataLoaded: false,
    filteredAbTests: [],
    filterValue: ""
}