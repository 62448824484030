import React, { useReducer, useState } from 'react'
import { ReduxAction } from '../../../redux/rootReducer';
import s from './GetValueCountsArea.module.scss';
import EventParameterDropdown from './GetValueCountsArea/EventParameterDropdown';
import SaveValueCountQuery from './GetValueCountsArea/SaveValueCountQuery';
import ValueCountsGraph from './GetValueCountsArea/ValueCountsGraph';

export type ReducerType = {
    event: string
    parameter: string
    parameters: string[]
}
const reducer = (state: ReducerType, action: ReduxAction): ReducerType => {
    switch(action.type) {
        case 'SET_EVENT':
            return {
                ...state,
                event: action.payload
            }
        case 'SET_PARAMETER':
            return {
                ...state,
                parameter: action.payload
            }
        case 'SET_PARAMETERS':
            return {
                ...state,
                parameter: INIT_STATE.parameter,
                parameters: action.payload
            }
        default: 
            return {...state}
    }
}
const INIT_STATE: ReducerType = {
    event: '',
    parameter: '',
    parameters: [],
}
export type ValueCounts = { [key: string]: any};
const GetValueCountsArea = () => {
    const [valueCounts, setValueCounts] = useState<ValueCounts>({});
    const [open, setOpen]               = useState(false);
    const [state, dispatch]             = useReducer(reducer, INIT_STATE);

    
    
    return (
        <div className={s.wrap}>
            <EventParameterDropdown 
                setValueCounts={setValueCounts}
                state={state}
                dispatch={dispatch}
            />
            <ValueCountsGraph 
                valueCounts={valueCounts}
                setOpen={setOpen}
            />
            <SaveValueCountQuery 
                open={open} 
                setOpen={setOpen}
                valueCounts={valueCounts}
                event={state.event}
                parameter={state.parameter}
            />
        </div>
    );
};


export default GetValueCountsArea;