import MobileDesignCard from './MobileDesignGroup/MobileDesignCard';
import s from './MobileDesignGroup.module.scss'
import { DesignGroup } from '../../../redux/abtest/design-group/interfaces';

type Props = {
    group: DesignGroup
}

const MobileDesignGroup = ({ group } : Props) => {
    let bestScore = -Infinity;
    group.designs.forEach(design => {
        if (design.score > bestScore && design.score !== 0) {
            bestScore = design.score;
        }
    });

    return (
        <div className={s.wrap}>
            <h3>{group.name}</h3>
            <hr/>
            {
                group.designs.map(design => (
                    <MobileDesignCard 
                        design={design}
                        best={bestScore === design.score}
                    />
                ))
            }
        </div>
    );
};

export default MobileDesignGroup;