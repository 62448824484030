import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {  Modal } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTheme } from '../../redux/admin/adminSelectors';
import { AdminState } from '../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import s from './CustomModal.module.scss';


type Props = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    children: ReactJSXElement,
    theme: AdminState["theme"]
}

/*
    Insert any component as children and it will be in a nice MUI modal 
*/

const CustomModal = ({ open, setOpen, children, theme } : Props) => {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className={s.modal} style={{background: theme === "dark" ? "#1e1e1e" : "white"}}>
                {children}
            </div>
        </Modal>
    );
};

type SProps = Pick<Props, "theme">;
const mapStateToProps = createStructuredSelector<RootState, SProps>({
    theme: selectTheme
})
export default connect(mapStateToProps)(CustomModal);