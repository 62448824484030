import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ERR_SNACKBAR } from "../../../../helpers/constants";
import { createDesignGroupInDb } from "../../../../redux/abtest/design-group/designGroupActions";
import { InitialDesign } from "../../../../redux/abtest/design-group/designs/interfaces";
import { AbTestInterface } from "../../../../redux/abtest/interfaces";
import { setAlert } from "../../../../redux/admin/adminActions";
import { AlertMsg } from "../../../../redux/admin/interfaces";
import { ReduxAction, RootState } from "../../../../redux/rootReducer";
import AddCard from "../../../reusable/AddCard";
import CustomModal from "../../../reusable/CustomModal";
import { AbTestContext } from '../../AbTestPage';
import s from "./AddDesignGroupForm.module.scss";
import DesignCard from "./AddDesignGroupForm/DesignCard";
import DesignForm from "./AddDesignGroupForm/DesignForm";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


type Props = {
    initialDesigns: InitialDesign[],
    createDesignGroupInDb: (name: string, abTestId: AbTestInterface["_id"]) => ReduxAction,
    setAlert: (alert: AlertMsg) => ReduxAction
    alertMsg: AlertMsg
};

type Itype="image" | "text" | "float" | "boolean" | "integer"

const AddDesignArea = ({ initialDesigns, createDesignGroupInDb, setAlert, alertMsg }: Props) => {
    let isUpdatingDesign = initialDesigns.length > 0 ? true : false;
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [nameErr, setNameErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState<Itype>('image')
    const abTest = useContext(AbTestContext) as AbTestInterface;
    const designGroupNames: string[] = [];

    if (abTest.designGroups) {
        abTest.designGroups.forEach(group => {
            designGroupNames.push(group.name);
        })
    }

    useEffect(() => {
        setLoading(false);
        return () => {
            setLoading(false);
        }
    }, [alertMsg.msg])

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (designGroupNames.includes(e.target.value)) {
            setNameErr(true)
        } else {
            setNameErr(false);
        }
        setName(e.target.value);
    }

    function handleClick() {
        if (abTest.designGroups.length > 0) {
            if (abTest.designGroups[0].designs.length === initialDesigns.length) {
                createDesignGroupInDb(name, abTest._id);
            } else {
                return setAlert({
                    msg: "New design group must be the same length as all other design groups.",
                    config: ERR_SNACKBAR
                })
            }
        } else {
            createDesignGroupInDb(name, abTest._id);
        }
        setLoading(true);
    }


    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <h2>Add Design Group</h2>
                <hr />
            </div>
            <div>
                <div className={s.nameInputWrap}>
                    <TextField
                        fullWidth
                        required
                        label="group name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        error={nameErr}
                        helperText={nameErr ? "That name already exists" : ""}
                    />
                </div>
                {isUpdatingDesign ? null :
                    <div className={s.nameInputWrap}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Type"
                            name='Type'
                            onChange={(e) => {
                                setType(e.target.value as Itype)
                            }}
                            fullWidth
                        >
                            <MenuItem value='image'>Image</MenuItem>
                            <MenuItem value='text'>Text</MenuItem>
                            <MenuItem value='float'>Float</MenuItem>
                            <MenuItem value='integer'>Integer</MenuItem>
                            <MenuItem value='boolean'>Boolean</MenuItem>
                        </Select>
                    </div>
                }
            </div>
            <div className={s.designsWrap}>
                {initialDesigns.map((d, i) => (
                    <DesignCard key={i} idx={i} {...d} />
                ))}
                <AddCard setOpen={setOpen} />
            </div>
            <CustomModal open={open} setOpen={setOpen}>
                <DesignForm abTest={abTest} setOpen={setOpen} type={type} />
            </CustomModal>
            <div className={s.btnWrap}>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={!name || nameErr}
                    loading={loading}
                    onClick={handleClick}
                >
                    Create Group
                </LoadingButton>
            </div>
        </div>
    );
};

const mapDispatch = {
    createDesignGroupInDb: (name: string, abTestId: AbTestInterface["_id"]) => createDesignGroupInDb(name, abTestId),
    setAlert: (alert: AlertMsg) => setAlert(alert)
}
const mapStateToProps = (rootState: RootState) => {
    const { initialDesigns } = rootState.initAbTest;
    const { alertMsg } = rootState.admin;
    return { initialDesigns, alertMsg };
}
export default connect(mapStateToProps, mapDispatch)(AddDesignArea);
