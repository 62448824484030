import { connect } from 'react-redux';
import { match, Redirect } from 'react-router-dom';
import { makeSlug } from '../../helpers/utils';
import { AbTestInterface } from '../../redux/abtest/interfaces';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import DeleteDataButton from '../reusable/DeleteDataButton';
import PageTitle from '../reusable/PageTitle';
import s from './AbTestPage.module.scss'
import AbTestSummary from './AbTestPage/AbTestSummary';
import DesignsArea from './AbTestPage/DesignsArea';
import UnityLaunchArea from './AbTestPage/unity-components/UnityLaunchArea';
import { createContext, useEffect } from 'react';
import { setCurrentDynamicGroupsOurApi } from '../../redux/external-apis/our-api/ourApiActions';
import BarChartArea from './AbTestPage/BarChartArea';
import MobileDesignGroup from './AbTestPage/MobileDesignGroup';
import { DesignGroup } from '../../redux/abtest/design-group/interfaces';
import { loadAbTestPageData } from '../../redux/abtest/abTestActions';
import { useAbTestSelector } from '../../redux/abtest/abTestSelectors';
import { NavState } from '../../redux/navigation/interfaces';
import { setAbTestDataLoaded } from '../../redux/navigation/navActions';
import EventPropertyArea from './AbTestPage/EventPropertyArea';
import AppNavigation from '../reusable/AppNavigation';
import AbTestNavigation from '../reusable/AbTestNavigation';
import { CircularProgress } from '@mui/material';
import FeedbackPropertyArea from './AbTestPage/FeedbackPropertyArea';
import FeedbackShowArea from './AbTestPage/FeedbackShowArea';



export interface LocalMatch extends match {
    params: { name: string, test: string }
}
type Props = {
    match: LocalMatch,
    projectsLength: number
    abTestDataLoaded: NavState["abTestDataLoaded"]
    setCurrentDynamicGroupsOurApi: (groups: DesignGroup[]) => ReduxAction
    loadAbTestPageData: (abTest: AbTestInterface) => ReduxAction
    setAbTestDataLoaded: (bool: boolean) => ReduxAction
}


export const AbTestContext = createContext<AbTestInterface | null>(null);
const AbTestPage = ({
    match,
    projectsLength,
    abTestDataLoaded,
    setCurrentDynamicGroupsOurApi,
    loadAbTestPageData,
    setAbTestDataLoaded
}: Props) => {
    const { abTest, abTestCount, projectCount, projectName } = useAbTestSelector(match);
    const redirectToProjects = projectCount > 1;
    const redirectToProject = abTest && abTestCount > 1;
    const needsRedirect = redirectToProject || redirectToProjects;
    useEffect(() => {
        // if loading a link to an ab test, we need needsRedirect bool
        if (abTest && !abTestDataLoaded && !needsRedirect) {
            loadAbTestPageData(abTest);
        }
        return () => {
            setCurrentDynamicGroupsOurApi([]);
            setAbTestDataLoaded(false);
        }
    }, [])

    useEffect(() => {
        console.log(match)
    }, [projectsLength, match])


    if (redirectToProjects) {
        return <Redirect to="/home/projects" />
    } else if (redirectToProject) {
        return <Redirect to={`/home/projects/${projectName ? makeSlug(projectName) : ""}`} />
    }


    return (
        <div className={s.wrap}>
            {
                projectsLength > 0 && (
                    <>
                        <AppNavigation
                            projectName={abTest ? abTest.basicFields.project : ""}
                            abTestName={abTest ? abTest.basicFields.name : ""}
                        />
                        {abTest && <AbTestNavigation currUrl={match.url} projectName={abTest.basicFields.project} />}
                        <PageTitle text={`${abTest ? abTest.basicFields.name : ""}`} />
                        {
                            window.innerWidth > 438 ? (
                                abTest && (
                                    <AbTestContext.Provider value={abTest}>
                                        <DesignsArea />
                                        {abTest.feedbackCombo?.event &&
                                            abTest.feedbackCombo?.parameter && abTest.feedbackData
                                                ?
                                            <FeedbackShowArea feedbackData={abTest.feedbackData} /> : null}
                                        {
                                            abTest.abTestApiInfo.api === "UNITY" && (
                                                <UnityLaunchArea />
                                            )
                                        }
                                        <EventPropertyArea designGroups={abTest.designGroups} />
                                        <FeedbackPropertyArea />
                                        <div className={s.summary}>
                                            <AbTestSummary />
                                            <BarChartArea abTest={abTest} />
                                        </div>
                                        <DeleteDataButton
                                            projectName={abTest.basicFields.project}
                                            abTestId={abTest._id}
                                            dataType="abTest"
                                        />
                                    </AbTestContext.Provider>
                                )
                            ) : (
                                abTest && abTest.designGroups.length > 0 ? (
                                    abTest.designGroups.map((group, idx) => (
                                        <MobileDesignGroup key={idx} group={group} />
                                    ))
                                ) : (
                                    <div>No designs</div>
                                )
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { projects } = rootState.projects;
    return {
        projectsLength: projects.length,
        abTestDataLoaded: rootState.nav.abTestDataLoaded
    }
}

const mapDispatch = {
    setCurrentDynamicGroupsOurApi: (groups: DesignGroup[]) => setCurrentDynamicGroupsOurApi(groups),
    loadAbTestPageData: (abTest: AbTestInterface) => loadAbTestPageData(abTest),
    setAbTestDataLoaded: (bool: boolean) => setAbTestDataLoaded(bool)
}
export default connect(mapStateToProps, mapDispatch)(AbTestPage);
