import { Paper } from '@mui/material';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AdminState } from '../../redux/admin/interfaces';
import { RootState } from '../../redux/rootReducer';
import PageTitle from '../reusable/PageTitle';
import s from './ProjectDataPage.module.scss'
import DisplayValueCountsArea from './ProjectDataPage/DisplayValueCountsArea';
import GetValueCountsArea from './ProjectDataPage/GetValueCountsArea'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
type Props = {
    theme: AdminState["theme"]
}




const ProjectDataPage = ({ theme } : Props) => {

    let { path, url } = useRouteMatch();
    const history = useHistory();
    const hoverStyles = {
        '&:hover': {
            backgroundColor: theme === "dark" ? "#373737" : "#dcdcdc"
        }
    }

    const Selectors = () => (
        <div className={s.navWrap}>
            <Paper onClick={() => history.push(`${url}/newquery`)} sx={hoverStyles} classes={{root: s.navBtn}}>
                <span>Create New Query <ArrowForwardIcon/></span>
            </Paper>

            <Paper onClick={() => history.push(`${url}/saved`)} sx={hoverStyles} classes={{root: s.navBtn}}>
                <span>Get Saved Queries <ArrowForwardIcon/></span>
            </Paper>
        </div>

    )
    return (
        <div className={s.wrap}>
            <PageTitle  text="Project Data"/>
            
            <Switch>
                <Route exact path={path} component={Selectors} />
                <Route exact path={`${path}/newquery`} component={GetValueCountsArea}/>
                <Route exact path={`${path}/saved`} component={DisplayValueCountsArea}/>
            </Switch>
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { theme } = rootState.admin;
    return { theme }
}
export default connect(mapStateToProps)(ProjectDataPage);