import { Button, Paper } from '@mui/material';
import { useContext, useEffect, useState } from 'react'
import { AbTestInterface } from '../../../redux/abtest/interfaces';
import CustomModal from '../../reusable/CustomModal';
import { AbTestContext } from '../AbTestPage';
import s from './DesignsArea.module.scss'
import DesignGroup from './DesignsArea/DesignGroup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddDesignGroupForm from './DesignsArea/AddDesignGroupForm';

/* Contains all the design groups */

const DesignsArea = () => {
    const abTest                          = useContext(AbTestContext) as AbTestInterface;
    const [newGroupOpen, setNewGroupOpen] = useState(false);
    useEffect(() => {
        setNewGroupOpen(false);
    }, [abTest])
    return (
        <Paper className={s.wrap}>
        {
            abTest && (
                <>
                    <span className={s.title}>
                        <h2>Designs</h2>
                        <Button disableElevation onClick={() => setNewGroupOpen(true)} variant="contained" startIcon={<AddCircleIcon/>}>Add Design Group</Button>
                    </span>
                    <hr/>
                    
                    {
                        abTest.designGroups && abTest.designGroups.length > 0 ? (
                            abTest.designGroups.map((group, idx) => (
                                <DesignGroup key={idx} group={group} abTest={abTest}/>
                            ))
                        ) : (null)
                    }
                    <CustomModal open={newGroupOpen} setOpen={setNewGroupOpen}>
                        <AddDesignGroupForm/>
                    </CustomModal>
                </>
            )
        }
        </Paper>
    );
};

export default DesignsArea;