import axios, { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { AXIOS_ERROR, BASE_URL, ERR_SNACKBAR, SUCCESS_SNACKBAR, UNSUCCESS } from "../../../helpers/constants";
import { fmtDesignGroupData } from "../../../helpers/utils";
import { setAlert } from "../../admin/adminActions";
import { selectUsername } from "../../admin/adminSelectors";
import { setCurrentDynamicGroupsOurApi } from "../../external-apis/our-api/ourApiActions";
import { replaceAbTest } from "../../projects/projectsActions";
import { resetInitAbTestState } from "../abtest-init/abtestInitActions";
import { selectInitialDesigns } from "../abtest-init/abtestInitSelectors";
import { AbTestInterface } from "../interfaces";
import { createDesignGroupInDb, deleteDesignGroupInDb, editDesignGroupInDb, getDesignGroupScores } from "./designGroupActions";
import { designSagas } from "./designs/designSagas";
import { InitialDesign } from "./designs/interfaces";
import { DESIGN_GROUP_ACTIONS } from "./interfaces";





type DesignGroupRouteResponse = {
    error?: string,
    success: boolean,
    updatedAbTest?: AbTestInterface
}






// ------------------------------------------------------------------ Create Design Group ----------------------------------------------------------------------------------------//
type CreateDesignGroupAction   = ReturnType<typeof createDesignGroupInDb>;
export type InitialDesignGroup = {
    name: string
    creator: string
    designs: InitialDesign[]
};
function* handleCreateDesignGroup({ payload }: CreateDesignGroupAction) {
    const { name, abTestId } = payload;
    try {
        const designs: InitialDesign[]        = yield select(selectInitialDesigns);
        const user: string                    = yield select(selectUsername);
        const designGroup: InitialDesignGroup = {
            name: name,
            creator: user,
            designs
        }

        // formData will have files in a seperate part, initialdesigns without an image or imageUrl field at all
        const formData = fmtDesignGroupData(designGroup, abTestId);
        

        // post to database
        const url = BASE_URL + '/abtests/designgroup'
        const res: AxiosResponse<DesignGroupRouteResponse> = yield call(
            axios.post,
            url,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data'}}
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const {success, error, updatedAbTest} = res.data;
        if (error) throw new Error(error);
        if (!success) throw new Error(UNSUCCESS);
        yield put(replaceAbTest(updatedAbTest!));
        yield put(setCurrentDynamicGroupsOurApi(updatedAbTest!.designGroups));
        yield put(setAlert({msg: "Successfully added new design group!", config: SUCCESS_SNACKBAR}));

        // finally clear out the reducer state
        yield put(resetInitAbTestState());
        
    } catch (e: any) {
        yield put(setAlert({msg: e.response ? JSON.stringify(e.response.data) : e.message, config: ERR_SNACKBAR}))
    }
    
}

function* interceptCreateDesignGroup () {
    yield takeLatest(
        [DESIGN_GROUP_ACTIONS.CREATE_DESIGN_GROUP_IN_DB],
        handleCreateDesignGroup
    )
}













// ------------------------------------------------------------------ Delete Design Group ----------------------------------------------------------------------------------------//
type DeleteDesignGroupAction = ReturnType<typeof deleteDesignGroupInDb>;
function* handleDeleteDesignGroupInDb({ payload }: DeleteDesignGroupAction) {
    try {
        const url = BASE_URL + '/abtests/designgroup';
        const res: AxiosResponse<DesignGroupRouteResponse> = yield call(
            axios.delete,
            url,
            { data: { ...payload }}
        )

        if (!res) throw new Error(AXIOS_ERROR);
        const { error, success, updatedAbTest } = res.data;
        if (error) throw new Error(error)
        if (!success) throw new Error(UNSUCCESS);
        if (!updatedAbTest) throw new Error("No updated ab test returned from db.");
        yield put(replaceAbTest(updatedAbTest));
        yield put(setCurrentDynamicGroupsOurApi(updatedAbTest.designGroups));
        yield put(setAlert({msg: "Successfully deleted design group.", config: SUCCESS_SNACKBAR}));
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }))
    }
}
function* interceptDeleteDesignGroupInDb() {
    yield takeLatest(
        [DESIGN_GROUP_ACTIONS.DELETE_DESIGN_GROUP_IN_DB],
        handleDeleteDesignGroupInDb
    )
}






// ------------------------------------------------------------------ UPDATE Design Group ----------------------------------------------------------------------------------------//
type EditDesignGroupAction = ReturnType<typeof editDesignGroupInDb>;
function* handleEditDesignGroupInDb({ payload }: EditDesignGroupAction) {
    try {
        const url = BASE_URL + '/abtests/designgroup';
        const res: AxiosResponse<DesignGroupRouteResponse> = yield call(
            axios.patch,
            url,
            payload
        )
        if (!res) throw new Error(AXIOS_ERROR);
        const { error, success, updatedAbTest } = res.data;
        if (error) throw new Error(error)
        if (!success) throw new Error(UNSUCCESS);
        if (!updatedAbTest) throw new Error("No updated ab test returned from backend.");
        yield put(replaceAbTest(updatedAbTest));
        yield put(setCurrentDynamicGroupsOurApi(updatedAbTest.designGroups));
        yield put(setAlert({msg: "Successfully updated design group.", config: SUCCESS_SNACKBAR}))
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }))
    }
}
function* interceptEditDesignGroupInDb() {
    yield takeLatest(
        [DESIGN_GROUP_ACTIONS.EDIT_DESIGN_GROUP_IN_DB],
        handleEditDesignGroupInDb
    )
}













// ------------------------------------------------------------------ Get Design Group Scores ----------------------------------------------------------------------------------------//
type GetDesignGroupScoresAction = ReturnType<typeof getDesignGroupScores>;
function* handleGetDesignGroupScores(action: GetDesignGroupScoresAction) {
    try {
        const url     = BASE_URL + '/projects/abtests/designgroup/score';
        const res: AxiosResponse<DesignGroupRouteResponse> = yield call(
            axios.post, 
            url, 
            action.payload
        );
        if (!res) throw new Error(AXIOS_ERROR);
        const { error, success, updatedAbTest } = res.data;
        if (error) throw new Error(error)
        if (!success) throw new Error("Unsuccessful operation; please contact developer.");
        if (!updatedAbTest) throw new Error("No updated ab test returned. please contact developer.");
        yield put(replaceAbTest(updatedAbTest))
        yield put(setAlert({
            msg: 'Successfully retrieved design scores!',
            config: SUCCESS_SNACKBAR
        }))
    } catch (e: any) {
        console.log(e);
        yield put(setAlert({
            msg: e.message,
            config: ERR_SNACKBAR
        }))
    }
}

function* interceptGetDesignGroupScores() {
    yield takeLatest(
        [DESIGN_GROUP_ACTIONS.GET_DESIGN_GROUP_SCORES],
        handleGetDesignGroupScores
    )
}








export function* designGroupSagas() {
    yield all([
        call(interceptCreateDesignGroup),
        call(interceptDeleteDesignGroupInDb),
        call(interceptEditDesignGroupInDb),
        call(interceptGetDesignGroupScores),
        call(designSagas)
    ]);
}