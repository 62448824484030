import { Button } from '@mui/material';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import s from './CustomGridFooter.module.scss'
import CustomModal from '../../../reusable/CustomModal';
import ProjectForm from '../ProjectForm';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { SMALL_LAPTOP_WIDTH } from '../../../../helpers/constants';
import { useEffect, useState } from 'react';
import { AlertMsg } from '../../../../redux/admin/interfaces';

type Props = {
    alertMsg: AlertMsg
}

const CustomGridFooter = ({ alertMsg }: Props) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        return () => {
            setOpen(false);
        }
    }, [])

    useEffect(() => {
        if (alertMsg.config.variant === "success") {
            setOpen(false);
        }
    }, [alertMsg.msg])

    return (
        <GridFooterContainer>
            <div className={s.btnWrapper}>
                <Button 
                    variant="contained" 
                    size="large"
                    onClick={() => setOpen(true)}
                    disableElevation
                    endIcon={window.innerWidth > SMALL_LAPTOP_WIDTH && <AddBoxIcon/>}
                    className={s.addBtn}
                >
                {
                    window.innerWidth > SMALL_LAPTOP_WIDTH ? (
                        "ADD PROJECT"
                    ) : (
                        <AddBoxIcon/>
                    )
                }
                </Button>
            </div>
            <GridFooter/>
            <CustomModal open={open} setOpen={setOpen}>
                <ProjectForm setOpen={setOpen}/>
            </CustomModal>
        </GridFooterContainer>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return { alertMsg }
}
export default connect(mapStateToProps)(CustomGridFooter);