export const ACTIONS = {
    LAUNCH_UNITY_CAMPAIGN: "LAUNCH_UNITY_CAMPAIGN",
    CHECK_UNITY_CAMPAIGN_EXISTS: "CHECK_UNITY_CAMPAIGN_EXISTS",
    SET_HAS_UNITY_CAMPAIGN: "SET_HAS_UNITY_CAMPAIGN",
    SET_CHECK_UNITY_CAMPAIGN_LOADING: "SET_CHECK_UNITY_CAMPAIGN_LOADING",
    SET_UNITY_CONFIG_KEYS: "SET_CONFIG_KEYS",
    GET_UNITY_CONFIG_KEYS: "GET_CONFIG_KEYS"
}



export interface UnityApiState {
    currConfigKeys: string[], // project config keys, each project has its own set of config keys
    hasCampaignInApi: boolean,
    checkCampaignLoading: boolean
}



export const UNITY_API_INIT_STATE: UnityApiState = {
    currConfigKeys: [],
    hasCampaignInApi: false,
    checkCampaignLoading: false
}


