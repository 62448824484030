import { all, call } from "redux-saga/effects";
import { unityApiSagas } from './unity/unitySagas';
import { ourApiSagas } from './our-api/ourApiSagas';
//import { firebaseApiSagas } from "./firebase/firebaseSagas";
export function* externalApiSagas() {
    yield all([
        call(unityApiSagas),
        call(ourApiSagas),
        //call(firebaseApiSagas) firebase has no feature yet for creating ab tests programatically
    ])
}