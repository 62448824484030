import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useEffect } from 'react'
import { useHistory } from 'react-router';

type Props = {
    children: ReactJSXElement
}

// component will make sure that the page will scroll to the top of the page on mount
const ScrollToTop = ({ children }: Props) => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        
        return () => {
            unlisten();
        };
    }, []);
  
    return <>{children}</>;
  }
  

export default ScrollToTop;