import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { WARN_SNACKBAR } from '../../../../../../helpers/constants';
import { editDesignGroupInDb } from '../../../../../../redux/abtest/design-group/designGroupActions';
import { DesignGroup } from '../../../../../../redux/abtest/design-group/interfaces';
import { setAlert } from '../../../../../../redux/admin/adminActions';
import { AlertMsg } from '../../../../../../redux/admin/interfaces';
import { ReduxAction } from '../../../../../../redux/rootReducer';
import TextFieldAdornment from '../DesignCardFinal/DesignFormFinal/TextFieldAdornment';
import s from './DesignGroupEditForm.module.scss'

type Props = {
    alertText: string
    group: DesignGroup,
    abTestId: string
    groupNames: string[]
    setOpen: Dispatch<SetStateAction<boolean>>,
    editDesignGroupInDb: (abTestId: string, groupName: string, designGroupEdit: DesignGroupEdit) => ReduxAction
    setAlert: (alert: AlertMsg) => ReduxAction
}
export type DesignGroupEdit = {
    name?: DesignGroup["name"]
    creator?: DesignGroup["creator"]
}


const DesignGroupEditForm = ({ 
    alertText, 
    group, 
    abTestId,
    groupNames,
    setOpen, 
    editDesignGroupInDb, 
    setAlert 
} : Props) => {
    const INIT_STATE: DesignGroupEdit = { name: group.name, creator: group.creator };
    const [groupEdit, setGroupEdit] = useState<DesignGroupEdit>(INIT_STATE); 
    const [error, setError]           = useState<boolean>(false);
    const [errMsg, setErrMsg]         = useState("");
    const [loading, setLoading]       = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [alertText])

    // useEffect(() => {
    //     // if (groupEdit.name.includes("$")) {
    //     //     setError(true);
    //     //     setErrMsg("Cannot include $ symbol for database requirements.");
    //     // }
    // }, [groupEdit.name])


    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        
        setGroupEdit((oldVal) => {
            return {
                ...oldVal,
                [e.target.name]: e.target.value
            }
        });
        if (e.target.name === "name" && groupNames.includes(e.target.value)) {
            setError(true);
            setErrMsg("name already taken")
        } else {
            if (error) setError(false);
            if (errMsg) setErrMsg("");
        }
    }

    // update
    function handleClick() {
        let hasChange = false;
        const editToSend: DesignGroupEdit = {};
        Object.keys(groupEdit).forEach(key => {
            if (groupEdit[key as keyof DesignGroupEdit] !== group[key as keyof DesignGroupEdit]) {
                editToSend[key as keyof DesignGroupEdit] = groupEdit[key as keyof DesignGroupEdit];
                hasChange = true;
            }
        });
        if (!hasChange) {
            return setAlert({msg: "No changes detected", config: WARN_SNACKBAR})
        }
        setLoading(true);
        // send update
        editDesignGroupInDb(abTestId, group.name, editToSend)
    }


    function resetField(field: keyof DesignGroupEdit) {
        setGroupEdit(prev => ({...prev, [field]: INIT_STATE[field]}))
    }

    return (
        <div className={s.wrap}>
            <h2>Design Group Fields</h2>
            <section>
                <TextField
                    name="name"
                    onChange={handleInputChange}
                    label="Group Name"
                    value={groupEdit.name}
                    error={error || !groupEdit.name}
                    helperText={errMsg ? errMsg : ""}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("name")}/>
                    }}
                    fullWidth
                    required
                />
            </section>
            
            <section>
                <TextField
                    name="creator"
                    onChange={handleInputChange}
                    label="group creator"
                    value={groupEdit.creator}
                    error={!groupEdit.creator}
                    InputProps={{
                        endAdornment: <TextFieldAdornment handleClick={() => resetField("creator")}/>
                    }}
                    fullWidth
                    required
                />
            </section>

            <section>
                <LoadingButton disabled={error} size="large" loading={loading} onClick={handleClick} variant="contained" fullWidth>
                    UPDATE
                </LoadingButton>
            </section>
        </div>
    );
};

const mapDispatch = {
    editDesignGroupInDb: (abTestId: string, groupName: string, designGroupEdit: DesignGroupEdit) => editDesignGroupInDb(abTestId, groupName, designGroupEdit),
    setAlert: (alert: AlertMsg) => setAlert(alert)
}
export default connect(null, mapDispatch)(DesignGroupEditForm);