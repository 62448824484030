import { AbTestEditableFields } from "../../components/pages/AbTestPage/AbTestSummary";
import { AbTestInterface, ACTIONS } from "./interfaces";

/* CRUD FOR AB TESTS */
export function createAbTestInDb(
  name: string,
  comments: string,
  project: string
) {
  return {
    type: ACTIONS.CREATE_AB_TEST_IN_DB,
    payload: { name, comments, project },
  };
}
export function updateAbTestInDb(
  abTest: AbTestInterface,
  field: keyof AbTestEditableFields,
  value: string
) {
  return {
    type: ACTIONS.UPDATE_AB_TEST_IN_DB,
    payload: { abTest, field, value },
  };
}
export function deleteAbTestInDb(projectName: string, abTestId: string) {
  return {
    type: ACTIONS.DELETE_AB_TEST_IN_DB,
    payload: { projectName, abTestId },
  };
}

export function loadAbTestPageData(abTest: AbTestInterface) {
  return {
    type: ACTIONS.LOAD_AB_TEST_PAGE_DATA,
    payload: abTest,
  };
}

export function updateAbTestAddFeedback(abTest: AbTestInterface) {
  return {
    type: ACTIONS.UPDATE_FEEDBACK_AB_TEST_IN_DB,
    payload: { abTest },
  };
}
