import { LoadingButton } from '@mui/lab';
import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ERR_SNACKBAR } from '../../../../helpers/constants';
import { setAlert } from '../../../../redux/admin/adminActions';
import { AlertMsg } from '../../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../../redux/rootReducer';
import s from './GetDesignData.module.scss'
import { AbTestContext } from '../../AbTestPage';
import EventPropertyFields from './GetDesignData/EventPropertyFields';
import { clearPropertiesAndDatesOurApi, getEventDataOurApi } from '../../../../redux/external-apis/our-api/ourApiActions';
import { DesignGroup } from '../../../../redux/abtest/design-group/interfaces';
import { getDesignGroupScores } from '../../../../redux/abtest/design-group/designGroupActions';
import { OurApiState } from '../../../../redux/external-apis/our-api/interfaces'
import { useSelectDesignGroupDynamicData } from '../../../../redux/external-apis/our-api/ourApiSelectors';
export type DesignGroupScoreUpdate = {
    property: string,
    eventName: string,
    otherEventName: string,
    startDate: string,
    endDate: string,
    abTestId: string,
    groupName: string
    trialName: string,
};

type Props = {
    setAlert: (alert: AlertMsg) => ReduxAction,
    getDesignGroupScores: (params: DesignGroupScoreUpdate) => ReduxAction,
    getEventDataOurApi: (eventName: string, otherEventName: string, groupName: string, trialName: string) => ReduxAction,
    clearPropertiesAndDatesOurApi: (groupName: string) => ReduxAction,
    alertMsg: AlertMsg,
    designGroup: DesignGroup,
    eventDataLoading: OurApiState["eventDataLoading"] // this is for when the abtest page first mounts only, so all GetDesignData.tsx components have synced loading
};



/*
    PURPOSE: updating the design scores according to the 3 fields presented: event, otherEvent, and property
    ACTIONS: See Props that return ReduxAction
    CHILDREN: EventPropertyFields.tsx interacts with this component heavily
    NOTES: To understand how the ratios are calculated, see the /info route in design.ts on the backend
           Currently the maxEndDate field is obsolete, but I left it in here in case we ever do want to have the ability to 
           Get Design Data by a date range. Currently, we getting design data from the earliest possible date to time a user
           clicks the Get Data button. Previously, we got the min max date possible from the db and offer the choice. Wissam 
           wanted to remove this option, since we want the endDate to be now; events are hitting our api every second
*/
const GetDesignData = ({
    alertMsg,
    setAlert,
    getDesignGroupScores,
    getEventDataOurApi,
    clearPropertiesAndDatesOurApi,
    designGroup,
    eventDataLoading
}: Props) => {


    const abTest = useContext(AbTestContext);
    let hasComboFields = false;
    let INIT_EVT_NAME = '', INIT_OTHER_EVT = '', INIT_PROPERTY = '', INIT_TRIAL_NAME = '';


    // here we handle the case where eventPropertyCombo doesn't exist yet (it should exist, but jic)
    // it also allows us to cache the initial state in case we reset fields
    if (abTest) {
        const { eventPropertyCombo } = designGroup;
        if (eventPropertyCombo) {
            const { eventName, otherEventName, property, trialName } = eventPropertyCombo;
            if ((eventName || trialName) && otherEventName && property) {
                hasComboFields = true;
                INIT_EVT_NAME = eventName;
                INIT_OTHER_EVT = otherEventName;
                INIT_PROPERTY = property;
                INIT_TRIAL_NAME = trialName;
            }
        }
    }
    const [trialName, setTrialName] = useState<string | null>(hasComboFields ? INIT_TRIAL_NAME as string : null);
    const [eventName, setEventName] = useState<string | null>(hasComboFields ? INIT_EVT_NAME as string : null);
    const [otherEventName, setOtherEventName] = useState<string | null>(hasComboFields ? INIT_OTHER_EVT as string : null);
    const [property, setProperty] = useState<string | null>(hasComboFields ? INIT_PROPERTY as string : null);
    const [startDate, setStartDate] = useState<Date | null | "">(null);
    const [endDate, setEndDate] = useState<Date | null | "">(null);
    const [loading, setLoading] = useState(false); // this is for any api hits launched by this component


    // get properties, events, min and max date
    const designGroupDynamicData = useSelectDesignGroupDynamicData(designGroup.name);


    // when component unmounts, remove this design group's dynamic data in ourApiReducer
    useEffect(() => {
        return () => {
            clearPropertiesAndDatesOurApi(designGroup.name);
        }
    }, [])


    /*  whenever the abtest changes, or an alert changes, we reset the local loading */
    useEffect(() => {
        setLoading(false);
        return () => {
            setLoading(false);
        }
    }, [abTest, alertMsg.msg, designGroupDynamicData])


    /*  whenever the event end dates change, reset the dates */
    useEffect(() => {
        if (designGroupDynamicData) {
            const { minEventStartDate, maxEventEndDate } = designGroupDynamicData;
            if (minEventStartDate && maxEventEndDate) {
                setStartDate(new Date(minEventStartDate));
                setEndDate(new Date(maxEventEndDate));
            }
        }
    }, [designGroupDynamicData]);


    /*  this is the final call to "get data". All fields must be present in order to get design scores */
    function handleClick() {
        // if (!eventName || !trialName) {
        //     return setAlert({ msg: "You should add a click or a trial to get data.", config: ERR_SNACKBAR });
        // }
        if (!eventName || !property || !startDate || !endDate || !abTest || !otherEventName) {
            return setAlert({ msg: "All fields must be filled out.", config: ERR_SNACKBAR });
        }
        setLoading(true);
        getDesignGroupScores({
            eventName,
            property,
            otherEventName,
            groupName: designGroup.name,
            abTestId: abTest._id as string,
            endDate: new Date().toISOString(), // must be string
            startDate: (startDate as Date).toISOString(),
            trialName: trialName as string // must be string
        });
    }

    /*  
        There are 3 text fields. if some fields are filled out, and we change "events", other two fields must be reset 
        we also need to clear properties and dates since dates are displayed dynamically, and
        properties are dynamically filled according to the event + otherEvent fields
        User flow: Fill out event -> fill out otherEvent -> handleClick() -> choose "parameter". what happens if I change otherEvent ? clear stuff. ifChangeFirstEvent ? clear stuff etc.
        event and OtherEvent must be filled out before getting dynamic dates
    */
    function handleChange(name: string, value: string | null) {
        if (name === "trials") {
            // if (otherEventName || property) {
            //     setOtherEventName(null);
            //     setProperty(null);
            //     setStartDate(null);
            //     setEndDate(null);
            // }
            setTrialName(value);
        }


        if (name === "events") {
            if (otherEventName || property) {
                setOtherEventName(null);
                setProperty(null);
                setStartDate(null);
                setEndDate(null);
            }
            setEventName(value);
        } else if (name === "otherEvent") {
            // if clearing, remove property choice
            if (!value) {
                setProperty(null);
                setOtherEventName(null);
                setStartDate(null);
                setEndDate(null);
            }
            if (value && value === eventName) {
                setAlert({ msg: "both events must have different names.", config: ERR_SNACKBAR })
                setOtherEventName(null);
                clearPropertiesAndDatesOurApi(designGroup.name); // clear to reset loader
            } else if (value) {
                setOtherEventName(value);
                getEventDataOurApi(eventName as string, value as string, designGroup.name, trialName as string);
            }
        } else if (name === "property") {
            setProperty(value);
        }
    }

    function resetFields() {
        setEventName(hasComboFields ? INIT_EVT_NAME as string : null);
        setOtherEventName(hasComboFields ? INIT_OTHER_EVT as string : null);
        setProperty(hasComboFields ? INIT_PROPERTY as string : null);

        // only clear dates and properties if designs haven't had scores ever updated
        if (!hasComboFields) {
            clearPropertiesAndDatesOurApi(designGroup.name); // in case there are current dates (e.g. a user picked some event and otherEvent, and wanted to reset to initial state)
            setStartDate(null);
            setEndDate(null);
        } else {
            // hasComboFields = true means we have all 3 INIT fields filled out
            // so we must update the possible date range, since dates are based off of events hitting our api every second
            // normally we'd just reset if the data is static, but its dynamic so we want the most up to date date range
            setLoading(true);
            getEventDataOurApi(INIT_EVT_NAME, INIT_OTHER_EVT, designGroup.name, trialName as string);
        }
    }

    return (
        <div className={s.sectionWrap}>
            <section>
                <EventPropertyFields
                    handleChange={handleChange}
                    event={eventName}
                    otherEvent={otherEventName}
                    property={property}
                    trialName={trialName as string}
                    title={designGroup.name}
                />
            </section>



            <section>
                <div className={s.btnsWrap}>
                    <LoadingButton
                        onClick={handleClick}
                        variant="contained"
                        disabled={eventDataLoading}
                        loading={loading || eventDataLoading}
                        size="small"
                    >
                        Get Data
                    </LoadingButton>
                    <LoadingButton
                        onClick={resetFields}
                        variant="outlined"
                        color="warning"
                        sx={{ marginLeft: "0.5rem" }}
                        size="small"
                    >
                        Reset Fields
                    </LoadingButton>
                </div>
            </section>
        </div>
    )
};


const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    getDesignGroupScores: (params: DesignGroupScoreUpdate) => getDesignGroupScores(params),
    getEventDataOurApi: (eventName: string, otherEventName: string, groupName: string, trialName: string) => getEventDataOurApi(eventName, otherEventName, groupName, trialName),
    clearPropertiesAndDatesOurApi: (groupName: string) => clearPropertiesAndDatesOurApi(groupName)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    const { eventDataLoading } = rootState.externalApis.ourApi;
    return { alertMsg, eventDataLoading };
}
export default connect(mapStateToProps, mapDispatch)(GetDesignData);