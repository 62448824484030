import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ReduxAction, RootState } from '../../../../../redux/rootReducer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import s from './DesignGroupButtons.module.scss'
import CustomModal from '../../../../reusable/CustomModal';
import DesignGroupEditForm from './DesignGroupButtons/DesignGroupEditForm';
import { VariantType } from 'notistack';
import { DesignGroup } from '../../../../../redux/abtest/design-group/interfaces';
import { deleteDesignGroupInDb } from '../../../../../redux/abtest/design-group/designGroupActions';
type Props = {
    group: DesignGroup
    abTestId: string
    alertText: string
    alertVariant: VariantType
    groupNames: string[]
    deleteDesignGroupInDb: (abTestId: string, groupName: string) => ReduxAction
}

const DesignGroupButtons = ({ group, alertVariant, abTestId, alertText, groupNames, deleteDesignGroupInDb } : Props) => {
    const [delLoading, setDelLoading]   = useState(false);
    const [delDialogOpen, setDelDialogOpen]               = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);

    useEffect(() => {
        setDelLoading(false);
        return () => {
            setDelLoading(false);
            if (editFormOpen && alertVariant === "success") {
                setEditFormOpen(false);
            }
            setDelDialogOpen(false);
        }
    }, [alertText])


    function handleDelete() {
        setDelLoading(true);
        deleteDesignGroupInDb(abTestId, group.name)
    }

    return (
        <div className={s.wrap}>
            <Dialog open={delDialogOpen} onClose={() => setDelDialogOpen(false)}>
                <DialogTitle>Are you sure you want to delete this design group?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action is a permanent change and cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton variant="contained" onClick={() => setDelDialogOpen(false)}>No</LoadingButton>
                    <LoadingButton loading={delLoading} variant="contained" onClick={handleDelete}>Yes, Delete Group</LoadingButton>
                </DialogActions>
            </Dialog>
            <LoadingButton color="error" variant="outlined" onClick={() => setDelDialogOpen(true)}>Delete Group</LoadingButton>
            <LoadingButton color="warning" variant="outlined" onClick={() => setEditFormOpen(true)}>Edit Group</LoadingButton>
            <CustomModal open={editFormOpen} setOpen={setEditFormOpen}>
                <DesignGroupEditForm 
                    setOpen={setEditFormOpen} 
                    alertText={alertText} 
                    group={group}
                    abTestId={abTestId}
                    groupNames={groupNames}
                />
            </CustomModal>
        </div>
    );
};

const mapDispatch = {
    deleteDesignGroupInDb: (abTestId: string, groupName: string) => deleteDesignGroupInDb(abTestId, groupName)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return { alertText: alertMsg.msg, alertVariant: alertMsg.config.variant as VariantType }
}
export default connect(mapStateToProps, mapDispatch)(DesignGroupButtons);