import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction } from 'react'
type Props = {
    confirmationOpen: boolean,
    setConfirmationOpen: Dispatch<SetStateAction<boolean>>,
    loading: boolean,
    handleConfirm: () => void
}

const DeleteDialog = ({ 
    confirmationOpen,
    loading,
    handleConfirm,
    setConfirmationOpen 
} : Props) => {
    return (
        <Dialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
        >
            <DialogTitle>
                Are you sure you want to delete this design?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmationOpen(false)}>No</Button>
                <LoadingButton loading={loading} onClick={handleConfirm} autoFocus>
                    Yes
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;