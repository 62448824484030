import { ImageType } from "react-images-uploading";

export const DESIGN_ACTIONS = {
  UPDATE_DESIGN_IN_DB: "UPDATE_DESIGN_IN_DB",
  ADD_DESIGN_TO_DB: "ADD_DESIGN_TO_DB",
  DELETE_DESIGN_IN_DB: "DELETE_DESIGN_IN_DB",
};

// same as DB

/* 
    note on design:
    previously wissam wanted to have the option for text based designs, so instead of uploading an image,
    you would simply input text into a text field. Then he wanted this gone, so the "type" field will 
    always currently be "image".
*/
export interface Design {
  name: string;
  comments: string;
  creator: string;
  score: number;
  imageUrl: string | "";
  type: "image" | "text" | "float" | "boolean" | "integer";
  designText: string | "";
  clicks: number;
  trial: number;
  trialScore: number;
  properties: number; // nebulous term
  createdAt: string;
  updatedAt: string;
}

// only for creating a design on the frontend, we change the imageUrl field because
// we'll send the InitialDesigns in formData type request, which requires it be stringified
export interface InitialDesign extends Omit<Design, "imageUrl"> {
  image: ImageType | null;
}
