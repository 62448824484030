import { ACTIONS } from "./interfaces";

export function setNavAbTestName(test: string) {
    return {
        type: ACTIONS.SET_NAV_ABTEST_NAME,
        payload: test
    }
}


export function setNavProjectName(proj: string) {
    return {
        type: ACTIONS.SET_NAV_PROJECT_NAME,
        payload: proj
    }
}


export function setAbTestDataLoaded(bool: boolean) {
    return {
        type: ACTIONS.SET_AB_TEST_DATA_LOADED,
        payload: bool
    }
}


export function setNavFilteredAbTests(tests: string[]) {
    return {
        type: ACTIONS.SET_FILTERED_AB_TESTS,
        payload: tests
    }
}



export function setFilter(filter: string) {
    return {
        type: ACTIONS.SET_FILTER,
        payload: filter
    }
}