import { Paper, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react'
import s from './FeedbackFields.module.scss'
import { AdminState } from '../../../../../redux/admin/interfaces';
import { RootState } from '../../../../../redux/rootReducer';
import { connect, useSelector } from 'react-redux';



export type DropdownOpts = {
    value: string,
    label: string
};
type Props = {
    handleChange: (name: string, value: string | null) => void, //parent
    event: string | null, // parent
    parameter: string | null, //parent 
    title: string, // parent
    theme: AdminState["theme"], // rdx
    allEventNames: string[], // rdx,
    parameters: string []
}

/*
    NOTE: the labels for each field are different for their name, because Wissam wanted it that way.
          At this point, I still don't fully understand the meaning of the labels he chose, but for simplicity
          I logically think of each field as their "name" property (event, otherEvent, property).
*/
const FeedbackFields = ({
    handleChange,
    event,
    title,
    parameter,
    theme,
    allEventNames,
    parameters
}: Props) => {


    const [loading, setLoading] = useState(false);
    
    
    useEffect(() => {
        setLoading(false);
    }, [parameters])

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [])

    function handleEventsSelect(e: any, newValue: string | null) {
        handleChange("event", newValue);
    }
    function handlePropertySelect(e: any, newValue: string | null) {
        handleChange("parameter", newValue);
    }


    return (
        <Paper className={s.wrap} sx={theme === "dark" ? { backgroundColor: "#262626" } : {}}>
            <div className={s.innerWrap}>
                <div className={s.title}>{title}</div>
                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            name="event"
                            label="event"
                            {...params}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "13px" }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontSize: "13px" }
                            }}
                        />
                    )}
                    options={allEventNames.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    onChange={handleEventsSelect}
                    value={event}
                    classes={{ option: s.option }}
                    fullWidth
                />


                <Autocomplete
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="parameter"
                            label="parameter"
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "13px" },
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={17}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontSize: "13px" }
                            }}
                        />
                    )}
                    options={parameters ? parameters.sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    ) : []}
                    onChange={handlePropertySelect}
                    loading={loading}
                    loadingText="loading..."
                    value={parameter}
                    classes={{ option: s.option }}
                    fullWidth
                />
            </div>
        </Paper>
    );
};



const mapStateToProps = (state: RootState) => {
    const { theme } = state.admin;
    const { allEventNames } = state.externalApis.ourApi;
    return { theme, allEventNames }
}
export default connect(mapStateToProps)(FeedbackFields);