import { Button } from "@mui/material";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavState } from "../../redux/navigation/interfaces";
import { RootState } from "../../redux/rootReducer";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ProjectInterface } from "../../redux/projects/interfaces";
import { useAbTestsSelector } from '../../redux/projects/projectsSelectors';
import { LocalMatch } from "../pages/AbTestPage";
import { makeSlug } from "../../helpers/utils";
import { SxProps } from "@mui/system";








/*
    PURPOSE: displaying the next and previous ab test on the ab test page
             if there is a filter for the name of the ab test on the project page, 
             the queue will be based on the filtered results

             if there is no filter, the queue will be based on the non-filtered results that would
             appear on the ab test grid on the project page, which is displayed sorted by createdAt

             filteredAbTests will exist only when there is a filter
             otherwise we will use the sorted list from useAbTestsSelector
*/


type Props = {
    projectName: ProjectInterface["name"]
    currUrl: LocalMatch["url"]
    filteredAbTests: NavState["filteredAbTests"]
}
const AbTestNavigation = ({ currUrl, filteredAbTests, projectName } : Props) => {
    const history           = useHistory();
    const sortedAbTestNames = useAbTestsSelector(projectName);
    if (!sortedAbTestNames) return null
    if (!currUrl) return null
    const abTestsToUse          = filteredAbTests.length > 0 ? filteredAbTests : sortedAbTestNames;
    const routeChunks           = currUrl.split('/');
    const abTestName            = routeChunks[routeChunks.length - 1];
    const currPathWithoutAbTest = currUrl.split(abTestName)[0];
    const currIdx               = abTestsToUse.findIndex(name => makeSlug(name) === abTestName);
    const nextAbTest            = currIdx === abTestsToUse.length - 1 ? null : abTestsToUse[currIdx + 1];
    const prevAbTest            = currIdx === 0 ? null : abTestsToUse[currIdx - 1];
    const btnStyles: SxProps = {
        backgroundColor: "#00b0a8",
        textTransform: "unset",
        "&:hover": {
            backgroundColor: "#b8b8b8"
        }
    }

    function handleNextTest() {
        if (nextAbTest) {
            history.push(`${currPathWithoutAbTest}${makeSlug(nextAbTest)}`)
        }
    }

    function handlePrevTest() {
        if (prevAbTest) {
            history.push(`${currPathWithoutAbTest}${makeSlug(prevAbTest)}`)
        }
    }

    return (
        <div>
            {
                currIdx === -1 ? (
                    <h2>ERROR</h2>
                ) : (
                    <div style={{display: 'flex', gap:'1rem'}}>
                        { 
                            prevAbTest && (
                                <Button 
                                    sx={btnStyles} 
                                    size="small" 
                                    disableElevation 
                                    variant="contained" 
                                    startIcon={<ArrowBackIcon/>}
                                    onClick={handlePrevTest}
                                >({prevAbTest})</Button>
                            )
                        }
                        { 
                            nextAbTest && (
                                <Button 
                                    sx={btnStyles} 
                                    size="small" 
                                    disableElevation 
                                    variant="contained" 
                                    endIcon={<ArrowForwardIcon/>}
                                    onClick={handleNextTest}
                                >({nextAbTest})</Button>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = (rootState: RootState) => {
    const { filteredAbTests } = rootState.nav;
    return { filteredAbTests }
};
export default connect(mapStateToProps)(AbTestNavigation);